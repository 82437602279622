import { Availability } from "./availability";

export const AvailabilityData: Availability[] = [
  {
    availableDay: 'Sunday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Monday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Tuesday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Wednesday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Thursday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Friday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  },
  {
    availableDay: 'Saturday',
    availableTimeFrom: '',
    availableTimeTo: '',
    endTimeMin: '00:00',
    isActive: false
  }
];
