import { Lookup } from '../lookups/Lookup';
import { statusList } from '../sla/enums/statusList';
import { CommunicationMethod } from '../global/CommunicationMethod';
import { SCType } from './enums/SCType';

export class SCDetails {
  id?: number;
  logo: string;
  mainScanningCenter: Lookup;
  scanningCenterName: string;
  scanningCenterType: SCType;
  basicInfo: {
    address: string;
    contactNumberFlag: string;
    contactNumberExtension: string;
    contactNumber: string;
    email: string;
    managerName: string;
    managerFirstName: string;
    managerLastName: string;
    isEmailVerified: boolean;
    isMobileVerified: boolean;
  };
  country: Lookup;
  region: Lookup;
  city: Lookup;
  coverageAreas: Lookup[];
  casesCount: number;
  labUserDetails: Lookup[];
  branches: Lookup[];
  canDeleteScanningCenter: boolean;
  createdOn: string;
  scanningCenterSLA: {
    slaId: number;
    slaStatus: statusList;
    creationDate: Date;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  lastSLA: {
    id: number;
    status: statusList;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  communicationMethod: CommunicationMethod[];
  closedCasesCount: number;
  openCasesCount: number;
}
