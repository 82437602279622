<div mat-dialog-title>
  <h2>
    Receive the {{title}}(s)
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="select-type-section-title">
      Select Method
    </div>
    <p class="mb-3">
      <i class="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
          <path id="info-circle"
            d="M7,2a5,5,0,1,0,5,5A5,5,0,0,0,7,2Zm0,9a4,4,0,1,1,4-4A4,4,0,0,1,7,11ZM7,6.75a.5.5,0,0,0-.5.5v1.5a.5.5,0,0,0,1,0V7.25A.5.5,0,0,0,7,6.75Zm0-2a.625.625,0,1,0,.625.625A.625.625,0,0,0,7,4.75Z"
            transform="translate(-2 -2)" fill="#999" />
        </svg>
      </i>
      <i>Select how you want to confirm that you received the {{title}}s.</i>
    </p>
    <div class="select-type-section">
      <div class="sel-box-container" (click)="select('MANUALLY')">
        <img src="./assets/images/manual-receiving-illustration.png" alt="">
        <h5>Manually</h5>
      </div>
      <div class="sel-box-container" (click)="select('QRCODE')">
        <img src="./assets/images/scanning-qr-code-illustration.png" alt="">
        <h5>Scanning QR Code</h5>
      </div>
    </div>
  </div>
</mat-dialog-content>