<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="mat-dialog-inner">
      <mat-form-field class="position-relative" *ngIf="videoDevices.length && videoDevices.length > 1">
        <mat-label>Select Device</mat-label>
        <mat-select (selectionChange)='selectCamera()' [(ngModel)]="deviceSelected">
          <mat-option class="custom_option" *ngFor="let device of videoDevices" [value]="device.deviceId">
            {{ device.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <video width="100%" #videoElement></video>
      <!-- <canvas #canvasElement style="display:none;"></canvas> -->
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <button mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading' (click)="captureImage()">
        Take Photo
      </button>
    </div>
  </div>
</mat-dialog-content>