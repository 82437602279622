import { statusList } from '../sla/enums/statusList';
import { LabType } from './enums/LabType';

export class ListData {
  labsCount: number;
  pagedData: PagedData[];
}

export class PagedData {
  id: number;
  logo: string;
  labName: string;
  type: LabType;
  manager: string;
  contactNumber: string;
  createdOn: string;
  slaStatus: statusList;
  canDeleteLab: boolean;
}
