<div [formGroup]="formGroup">
  <mat-form-field hideRequiredMarker class="field_with_bg" [hidden]='hidden' [class.loading-field]='dataLoading'>
    <mat-label>{{fieldLabel}}</mat-label>
    <mat-select #select="matSelect" disableOptionCentering [formControlName]="controlName" [multiple]="multiple"
      (openedChange)="openEvent($event)" [required]="required" [disabled]="disabled">
      <div class="select_search_group">
        <div class="select_search_group_inner">
          <input #searchInput (keyup)="applyFilter(searchInput.value)" class="select_search_field form-control"
            placeholder="Search">
          <button mat-icon-button (click)="applyFilter(searchInput.value)">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
      <mat-select-trigger *ngIf="multiple">
        <span *ngFor="let item of formGroup.get(controlName).value">
          {{item.name}}
          <span class="ml-2 badge-status purple-white isSpecial-sm" *ngIf="item.isSpecial">
            Special
          </span>
        </span>
      </mat-select-trigger>
      <ng-container *ngFor="let item of sourcData">
        <mat-option [value]="item" [hidden]="isInArray(hiddenList,(key?item[key]:item))"
          [matTooltip]="item?.subSpeciality ? item?.subSpeciality : item?.name" matTooltipClass="wrap-tooltip">
          <!-- <span>{{key?item[key]:item}}</span> -->
          <div class="d-flex justify-content-between align-items-center mat-option-content">
            <span class="option-title">{{key?item[key]:item}}</span>
            <span class="ml-2 badge-status purple-white" *ngIf="item.isSpecial">
              Special
            </span>
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="hasError(controlName, 'required')">
      {{'forms.Required' | translate}}
    </mat-error>
  </mat-form-field>
</div>
