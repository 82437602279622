export enum ContractType {
  LAB_LAB = 'LAB_LAB',
  VL_LAB = 'VL_LAB',
  LAB_VL = 'LAB_VL',
  LAB_PATH = 'LAB_PATH',
  VL_PATH = 'VL_PATH',
  SC_VL = 'SC_VL',
  SC_LAB = 'SC_LAB',
  SC_HOSPITALS = 'SC_HOSPITALS',
  SC_CLINICS = 'SC_CLINICS',
  VL_CLINICS = 'VL_CLINICS',
  VL_HOSPITALS = 'VL_HOSPITALS',
  LAB_CLINICS = 'LAB_CLINICS',
  LAB_HOSPITALS = 'LAB_HOSPITALS',
}
