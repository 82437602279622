import { Lookup } from '../lookups/Lookup';
import { statusList } from '../sla/enums/statusList';
import { CommunicationMethod } from '../global/CommunicationMethod';
import { FinalReport } from '../global/enums/FinalReport';

export class HospitalDetails {
  hospitalName: string;
  createdOn: string;
  logo: string;
  hospitalUserDetails: string;
  basicInfo: {
    address: string;
    contactNumberFlag: string;
    contactNumberExtension: string;
    contactNumber: string;
    email: string;
    managerName: string;
    managerFirstName: string;
    managerLastName: string;
    isEmailVerified: boolean;
    isMobileVerified: boolean;
  };
  canDeleteHospital: boolean;
  ownScanners: boolean;
  ownLabs: boolean;
  finalReport: FinalReport;
  country: Lookup;
  city: Lookup;
  region: Lookup;
  communicationMethod: CommunicationMethod[];
  hospitalLabs: string;
  hospitalLabsCount: number;
  hospitalSLA: {
    slaId: number;
    slaStatus: statusList;
    creationDate: Date;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  lastSLA: {
    id: number;
    status: statusList;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  id?: number;
  casesCount: number;
  closedCasesCount: number;
  openCasesCount: number;
}
