import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component, Input, OnInit } from '@angular/core';
import { CasecurrentStatus } from 'src/app/models/cases/enums/CasecurrentStatus';
import { DstatusList } from 'src/app/models/requests/enums/DstatusList';
import { DRequestProcedure } from 'src/app/models/requests/enums/DRequestProcedure';
import { CasenextStatus } from 'src/app/models/cases/enums/CasenextStatus';
import { SecondOpinion } from 'src/app/models/cases/SecondOpinion';
import { PathologistSource } from 'src/app/models/cases/enums/PathologistSource';
import { DRequestType } from 'src/app/models/requests/enums/DRequestType';
import { EntityType } from 'src/app/models/requests/enums/EntityType';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';

@Component({
  selector: 'app-case-status',
  templateUrl: './case-status.component.html'
})
export class CaseStatusComponent implements OnInit {

  @Input() currentStatus: CasecurrentStatus;
  @Input() nextStatus: CasenextStatus;
  @Input() dRequestType: DRequestType;
  @Input() dRequestStatus: DstatusList;
  @Input() dRequestProcedure: DRequestProcedure;
  @Input() typeCase: CaseType; // RefCase, Case
  @Input() isAssignedPathologist: boolean;
  @Input() isReviewer: boolean;
  @Input() selectdSecondOpinion: SecondOpinion;
  @Input() selectdEndorsement: SecondOpinion;
  @Input() pathologistSource: PathologistSource;
  @Input() DsendBack = false;
  @Input() pathologiestsCount: number;
  @Input() currentDRequet: DRequestType;
  @Input() requestedType: EntityType;
  @Input() exceedDueDate: boolean;
  @Input() isForQuality: boolean;

  entityTypeEnum = EntityType;

  currentStatusEnum = CasecurrentStatus;

  constructor(
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
  }

  get isLabTechnologist(): boolean {
    return this.hasPermission(`${RolesEnum.LabTissueProcessorTechnologist},${RolesEnum.LabTissueEmbedderTechnologist},${RolesEnum.LabSlideStainnerTechnologist},${RolesEnum.LabSlideSectionerTechnologist}`)
  }

  get isVLabTechnologist(): boolean {
    return this.hasPermission(`${RolesEnum.VLabTissueProcessorTechnologist},${RolesEnum.VLabTissueEmbedderTechnologist},${RolesEnum.VLabSlideStainnerTechnologist},${RolesEnum.VLabSlideSectionerTechnologist}`)
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
