<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
  <div class="row">
    <div *ngFor="let file of files; let i = index" [ngClass]="{ 'col-md-6': cols == 2 }" class="col-12">
      <div class="file-item">
        <div class="file-details">
          <div class="file-icon">
            <img *ngIf="fileType(file.name) == 'doc'" src="./assets/images/file-icons/file-doc-icon.svg" />
            <img *ngIf="fileType(file.name) == 'jpg'" src="./assets/images/file-icons/file-jpg-icon.svg" />
            <img *ngIf="fileType(file.name) == 'pdf'" src="./assets/images/file-icons/file-pdf-icon.svg" />
            <img *ngIf="fileType(file.name) == 'png'" src="./assets/images/file-icons/file-png-icon.svg" />
            <img *ngIf="fileType(file.name) == 'xls'" src="./assets/images/file-icons/file-xls-icon.svg" />
            <img *ngIf="fileType(file.name) == 'zip'" src="./assets/images/file-icons/file-zip-icon.svg" />
            <img *ngIf="fileType(file.name) == 'pptx'" src="./assets/images/file-icons/file-ppt-icon.svg" />
          </div>
          <div>
            <label [title]="file?.name">{{ file?.name }}</label>
            <span>{{ formatBytes(file?.size) }}</span>
            <!-- <span>{{ file.size }}</span> -->
          </div>
        </div>
        <div class="file-btn">
          <a class="download" [href]="file.path" [download]="file.path" target="_blank">
            <svg width="20" height="20" viewBox="0 0 20 20">
              <path id="download-btn"
                d="M2.4,20A2.5,2.5,0,0,1,0,17.508V11.42a.294.294,0,0,1,.093-.2l3.07-5.918a.432.432,0,0,1,.419-.219H5.349a.489.489,0,0,1,0,.977H3.861L1.3,10.953h2.6A3.047,3.047,0,0,1,6.7,12.836a2.154,2.154,0,0,0,1.953,1.3h2.7a2.154,2.154,0,0,0,1.953-1.3,3.047,3.047,0,0,1,2.791-1.883h2.651L16.582,6.064h-1.93a.489.489,0,0,1,0-.977h2.233a.457.457,0,0,1,.442.244l2.6,5.893a.464.464,0,0,1,.07.2v6.088A2.5,2.5,0,0,1,17.6,20Zm7.279-8.68L6.512,7.973a.511.511,0,0,1,0-.588.453.453,0,0,1,.651-.1L9.535,9.781V.49a.466.466,0,1,1,.93,0V9.781l2.372-2.494a.446.446,0,0,1,.558,0,.507.507,0,0,1,.093.686L10.326,11.32a.284.284,0,0,1-.027.029.428.428,0,0,1-.624-.029Z"
                fill="#9D5CB5" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>