import { ClinicsService } from 'src/app/services/clinics.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { LookupsService } from 'src/app/services/lookups.service';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { ClinicForm } from 'src/app/models/clinics/ClinicForm';
import { ClinicDetails } from 'src/app/models/clinics/Details';
import { FinalReport } from 'src/app/models/global/enums/FinalReport';
import { CommunicationMethodType } from 'src/app/models/global/enums/CommunicationMethodType';
import { SwalService } from 'src/app/services/swal.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

export interface DialogData {
  clinicId: number;
  record: ClinicDetails;
  isOurProfile: boolean;
}

@Component({
  selector: 'app-clinic-form',
  templateUrl: './clinic-form.component.html'
})
export class ClinicFormComponent implements OnInit {

  dataLoading = false;
  loading = false;
  hidePassword = true;

  clinicId: number;
  isOurProfile: boolean;
  details: ClinicDetails;

  formGroup: FormGroup;
  isSubmitted = false;

  countryList: Lookup[] = [];
  regionList: Lookup[] = [];
  cityList: Lookup[] = [];

  // image file
  logo: any;
  logoSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;
  disableHasLab = false;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  selectedCountryISO: string = CountryISO.SaudiArabia;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ClinicFormComponent>,
    private formBuilder: FormBuilder,
    private service: ClinicsService,
    private lookupsService: LookupsService,
    private auth: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.buildForm();

    if (this.data.isOurProfile) {
      this.isOurProfile = this.data.isOurProfile;
      this.bindDataToUpdate();
    } else {
      if (this.data && this.data.record && this.data.record.id) {
        this.clinicId = this.data.clinicId;
        this.bindDataToUpdate();
      }
    }

    if (this.data && this.data.record && this.data.record.clinicLabsCount) {
      this.disableHasLab = true;
    }

    this.getCountries();
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.clinicId).subscribe(
      data => {
        this.details = data as ClinicDetails;
        this.dataLoading = false;
        this.bindDataToUpdate();
      },
      error => {
        this.dataLoading = false;
      });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      ClinicName: [null, CustomValidator.noWhiteSpace],
      contactNumber: [null, Validators.required],
      email: [null, [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]],
      country: [null, Validators.required],
      region: [null, Validators.required],
      cityId: [null, Validators.required],
      address: [null, CustomValidator.noWhiteSpace],
      managerFirstName: [null, CustomValidator.noWhiteSpace],
      managerLastName: [null, CustomValidator.noWhiteSpace],
      communicationMethod: this.formBuilder.array([
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.NETWORK],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.FTP],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.LOCAL],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.CEPH],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        })
      ]),
      FinalReport: [FinalReport.CLINIC_REPORT, Validators.required],
      OwnLab: [true],
      OwnScanner: [true]
    });
  }

  hasError(controlName: string, errorName: string, index = null) {
    if (index !== null) {
      return this.communicationMethods.controls[index]['controls'][controlName].hasError(errorName);
    } else {
      return this.formGroup.controls[controlName].hasError(errorName);
    }
  }

  get communicationMethods() {
    return this.formGroup.controls.communicationMethod as FormArray;
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countryList = data as [];
        if (!this.clinicId && !this.isOurProfile) {
          this.regionList = [];
          this.cityList = [];
          this.formGroup.controls.country.setValue(this.countryList.find(item => item.isDefault).id);
          this.getRegions();
        }
      }, err => {
        console.error(err);
      });
  }
  getRegions(changed = false) {
    this.lookupsService.getRegions(this.formGroup.controls.country.value).subscribe(
      data => {
        this.regionList = data as [];
        if (changed) {
          this.cityList = [];
          this.formGroup.controls.region.setValue(null);
          this.formGroup.controls.cityId.setValue(null);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(changed = false) {

    this.lookupsService.getCities(
      this.formGroup.controls.country.value,
      this.formGroup.controls.region.value).subscribe(
        data => {
          this.cityList = data as [];
          if (changed) {
            this.formGroup.controls.cityId.setValue(null);
          }
        }, err => {
          console.error(err);
        });
  }

  bindDataToUpdate(record = this.data.record) {
    this.formGroup.controls.ClinicName.setValue(record.clinicName);
    if (record.logo && record.logo) { this.logoSrc = record.logo; }
    this.formGroup.controls.contactNumber.setValue(record.basicInfo.contactNumber);
    this.selectedCountryISO = record.basicInfo.contactNumberFlag;
    this.formGroup.controls.email.setValue(record.basicInfo.email);
    this.formGroup.controls.country.setValue(record.country.id);
    this.formGroup.controls.region.setValue(record.region.id);
    this.formGroup.controls.cityId.setValue(record.city.id);
    this.formGroup.controls.address.setValue(record.basicInfo.address);
    this.formGroup.controls.managerFirstName.setValue(record.basicInfo.managerFirstName);
    this.formGroup.controls.managerLastName.setValue(record.basicInfo.managerLastName);
    this.communicationMethods.controls.forEach(group => {
      const item = record.communicationMethod.find(method =>
        method.communicationMethodType === group['controls'].CommunicationMethodType.value);
      if (item) {
        group['controls'].selectedMethod.setValue(true);
        group['controls'].CommunicationMethodType.setValue(item.communicationMethodType);
        group['controls'].NetworkPath.setValue(item.networkPath);
        group['controls'].UserName.setValue(item.userName);
        group['controls'].Password.setValue(item.password);
        group['controls'].IsSFtp.setValue(item.isSFtp);
        group['controls'].CephAccessKeyId.setValue(item.cephAccessKeyId);
        group['controls'].CephSecretKey.setValue(item.cephSecretKey);
        group['controls'].CephApiURL.setValue(item.cephApiURL);
        group['controls'].CephDefaultBucket.setValue(item.cephDefaultBucket);
      }
    });
    this.formGroup.controls.FinalReport.setValue(record.finalReport);
    this.formGroup.controls.OwnLab.setValue(record.ownLabs);
    this.formGroup.controls.OwnScanner.setValue(record.ownScanners);
    this.getRegions();
    this.getCities();
  }

  bindMatMultiSelect(data, parent) {
    const selectedList = [];
    if (parent && data) {
      parent.forEach(item => {
        if (data.some(obj => obj.id === item.id) || data.some(obj => obj.id === item.roleId)) {
          selectedList.push(item);
        }
      });
    }
    return selectedList;
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid && this.logoSrc && this.checkCommunicationMethod()) {
      this.loading = true;
      let data: ClinicForm;

      data = {
        Clinic: this.formGroup.controls.ClinicName.value,
        ContactNumberFlag: this.formGroup.controls.contactNumber.value.countryCode,
        ContactNumberExtension: this.formGroup.controls.contactNumber.value.dialCode,
        ContactNumber: this.formGroup.controls.contactNumber.value.number,
        email: this.formGroup.controls.email.value,
        cityId: this.formGroup.controls.cityId.value,
        streetaddress: this.formGroup.controls.address.value,
        managerFirstName: this.formGroup.controls.managerFirstName.value,
        managerLastName: this.formGroup.controls.managerLastName.value,
        communicationMethod: [],
        FinalReport: this.formGroup.controls.FinalReport.value,
        OwnLab: this.formGroup.controls.OwnLab.value,
        OwnScanner: this.formGroup.controls.OwnScanner.value
      } as ClinicForm;

      this.formGroup.controls.communicationMethod.value.forEach(element => {
        if (element.selectedMethod) {
          data.communicationMethod.push(
            {
              CommunicationMethodType: element.CommunicationMethodType,
              NetworkPath: element.NetworkPath,
              UserName: element.UserName,
              Password: element.Password,
              IsSFtp: element.IsSFtp,
              CephAccessKeyId: element.CephAccessKeyId,
              CephSecretKey: element.CephSecretKey,
              CephApiURL: element.CephApiURL,
              CephDefaultBucket: element.CephDefaultBucket,
            }
          );
        }
      });

      if (this.clinicId) { data.ClinicId = this.clinicId; }
      if (this.isOurProfile) {
        this.service.updateClinicProfile(data, this.logo).subscribe(() => {
          this.loading = false;
          this.swalService.swalSuccess('Updated Successfully');
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          this.handleErrors(err.detail);
        });
      } else {
        this.service.saveRecord(data, this.logo).subscribe(() => {
          this.loading = false;
          this.swalService.swalSuccess(this.clinicId ? 'Updated Successfully' : 'Saved Successfully');
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          this.handleErrors(err.detail);
        });
      }
    }
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
      }
    });
  }

  // File preview with validation
  changeFileListener($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.logo = file;
    } else {
      this.logoSrc = undefined;
      this.logo = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.logoSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  autoFillInput(controlName: string) {
    this.formGroup.controls[controlName].reset();
  }

  checkCommunicationMethod(): boolean {
    return !!this.communicationMethods.controls.find(item => item['controls'].selectedMethod.value === true);
  }

}
