import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HospitalForm } from '../models/hospitals/HospitalForm';

@Injectable({
  providedIn: 'root'
})

export class HospitalsService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/Hospital/GetAllHospitals`, { params });
  }

  saveRecord(data: HospitalForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    if (data.HospitalId) {
      return this.http.put(`${environment.apiUrl}/Hospital/EditHospital`, formData);
    } else {
      return this.http.post(`${environment.apiUrl}/Hospital/AddHospital`, formData);
    }
  }

  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/Hospital/GetHospitalDetails?HospitalId=${id}`);
  }

  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/Hospital/DeleteHospital?HospitalId=${id}`);
  }

  getHospitalProfileDetails() {
    return this.http.get(`${environment.apiUrl}/HospitalProfile/GetHospitalProfileDetails`);
  }

  updateHospitalProfile(data: HospitalForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    return this.http.put(`${environment.apiUrl}/HospitalProfile/EditHospitalProfile`, formData);
  }

}
