<div class="otp-container {{setting.wrapperClass}}" id="c_{{componentKey}}" *ngIf="otpForm?.controls"
  [ngStyle]="setting.wrapperStyles">
  <ng-container appCounter [counter]="setting.timer" (value)="onCounterChange($event)">
    <div class="conatiner_count">
      <ng-container *ngIf="!setting.timerType">
        {{ counter }}
      </ng-container>
      <ng-container *ngIf="setting.timerType">
        {{ formatSecsToMins(counter) }}
      </ng-container>
    </div>
  </ng-container>
  <div class="opt_inputs_group">
    <input [type]="setting.numbersOnly ? 'tel' : 'text'" appOnlynumber [disabledNumberOnly]="!setting.numbersOnly"
      [ngStyle]="setting.inputStyles" maxlength="1" class="otp-input {{setting.inputClass}}" autocomplete="off"
      *ngFor="let item of otpForm?.controls | keys; let i = index" [formControl]="otpForm.controls[item]"
      id="otp_{{i}}_{{componentKey}}" (keyup)="keyUp($event, i)">
  </div>

</div>
