<div class="h-100 overflow-auto" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="pageCount" (scrolled)="getNextPage()">
  <div class="sidenav-user-toolbar">
    <h4>
      Wallet Transactions
      <span *ngIf="this.recordsData.totalCount > 0">({{this.recordsData.totalCount}})</span>
    </h4>
  </div>
  <div class="sidenav-user-container">
    <div class="profile_detail">
      <div class="page">
        <ul class="list_transactions" *ngIf="transactionsList.length; else dataEmpty">
          <li class="item_transaction" *ngFor="let record of transactionsList">
            <mat-icon class="material-icons stats bg_success with_bg" *ngIf="record.transactionType === 'ADD'">
              north
            </mat-icon>
            <mat-icon class="material-icons stats bg_danger with_bg" *ngIf="record.transactionType === 'SUBTRACT'">
              south
            </mat-icon>
            <div class="item_transaction_inner">
              <div class="item_transaction_content">
                <h4 [ngSwitch]="record.transactionType">
                  <span *ngSwitchCase="'ADD'">
                    Top Up
                  </span>
                  <span *ngSwitchCase="'SUBTRACT'">
                    Paid in Transaction
                  </span>
                </h4>
                <p>
                  ({{record.paymentReferenceNumber}})
                </p>
                <span class="date_time">
                  {{record.transactionDate | date:'d MMM, y, h:mm a'}}
                </span>
              </div>
              <div class="item_transaction_right"
                [ngClass]="record.transactionType === 'SUBTRACT' ? 'danger':'success'">
                <strong *ngIf="record.amount !== 0">
                  {{record.transactionType === 'SUBTRACT'?'-':'+'}} {{record.amount | number}}
                </strong>
                <strong *ngIf="record.amount === 0">
                  {{record.amount | number}}
                </strong>
                {{defaultCurrency.currency.symbol}}
              </div>
            </div>
          </li>
        </ul>
        <ng-template #dataEmpty>
          <app-empty-state [img]='"./assets/images/viewer-icons/no-remarks-illustration.svg"' [title]='"No Data Found"'>
          </app-empty-state>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf='dataLoading' class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
</div>
