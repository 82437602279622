<div class="card_info_profile">
  <div class="card_info_profile_content">
    <div class="card_info_profile_title">
      <h4>
        Slide Communication Methods
      </h4>
    </div>

    <div class="card_info_profile_body">
      <ul class="list-items containet_item_method p-0 border-0">
        <li>
          <span class="title">
            This {{entityName}} obtains the case slides through:
          </span>
        </li>
        <ng-container *ngFor="let method of communicationMethodList; let i = index">
          <li>
            <div class="desc mt-0">
              <span class="tag-item mb-0" *ngIf="method.communicationMethodType">
                {{method.communicationMethodType}}
              </span>
            </div>
          </li>
          <li *ngIf="method.communicationMethodType">
            <span [ngSwitch]="method.communicationMethodType" class="title">
              <span *ngSwitchCase="'FTP'">
                FTP Server Path
              </span>
              <span *ngSwitchCase="'NETWORK'">
                Network Folder Path
              </span>

            </span>
            <div class="desc">
              {{method.networkPath}}
            </div>
          </li>
          <li *ngIf="method.userName">
            <span class="title">
              Username
            </span>
            <div class="desc">
              {{method.userName}}
            </div>
          </li>
          <li *ngIf="method.password" class="last_item">
            <span class="title">
              Password
            </span>
            <div class="desc d-flex align-items-center">
              <input class="password_input" *ngIf="!isInArray(showPassList,i)" [value]="method.password"
                [type]="'password'" readonly disabled>
              <label class="password_label mb-0" *ngIf="isInArray(showPassList,i)">
                {{method.password}}
              </label>
              <button mat-button color="primary" class="btn_link_with_icon" (click)="showPassword(i)">
                {{!isInArray(showPassList,i)?'Show':'Hide'}}
              </button>
            </div>
          </li>
          <li *ngIf="method.communicationMethodType === 'FTP' && method.isSFtp">
            <span class="title">
              Is Secure File Transfer Protocol (SFTP)
            </span>
          </li>
          <li *ngIf="method.cephApiURL">
            <span class="title">
               Api URL
            </span>
            <div class="desc">
              {{method.cephApiURL}}
            </div>
          </li>
          <li *ngIf="method.cephAccessKeyId">
            <span class="title">
              Access Key Id
            </span>
            <div class="desc">
              {{method.cephAccessKeyId}}
            </div>
          </li>
          <li *ngIf="method.cephSecretKey">
            <span class="title">
               Secret Key
            </span>
            <div class="desc">
              {{method.cephSecretKey}}
            </div>
          </li>
          <li *ngIf="method.cephDefaultBucket">
            <span class="title">
               Default Bucket
            </span>
            <div class="desc">
              {{method.cephDefaultBucket}}
            </div>
          </li>
        </ng-container>

      </ul>
    </div>
  </div>
</div>
