import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { OtpInputComponent } from '../otp-input/otp-input.component';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { VerificationService } from 'src/app/services/verification.service';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { MatchMakingService } from 'src/app/services/match-making.service';
import { SwalService } from 'src/app/services/swal.service';

export interface DialogData {
  entityId: number;
  entityType: EntityEnumSLA;
  title: string;
  message: string;
  verifyEntityUserMobile: boolean;
  caseId: number;
  caseIdType: CaseType;
}

@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html'
})
export class VerifyMobileComponent implements OnInit {
  @ViewChild(OtpInputComponent) otpInput;
  loading = false;
  disabled = true;
  disabledResend = true;
  entityId: number;
  entityType: EntityEnumSLA;

  isSubmitted = false;
  title: string;
  message: string;

  public settings = {
    length: 4,
    numbersOnly: true,
    timer: 120,
    timerType: 1
  };

  otpNumber: number;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<VerifyMobileComponent>,
    private service: VerificationService,
    private matchMakingService: MatchMakingService,
    public translate: TranslateService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.entityId = this.data.entityId;
      this.entityType = this.data.entityType;
      this.title = this.data.title;
      this.message = this.data.message;
      this.ressendOtp();
    }
  }

  onInputChange(e) {
    this.disabled = true;
    if (e.length === this.settings.length) {
      // e will emit values entered as otp and,
      this.disabled = false;
      this.otpNumber = Number(e);
    } else if (e === -1) {
      // if e === -1, timer has stopped
      this.disabledResend = false;
    } else if (e === -2) {
      // e == -2, button click handle
    }
  }

  ressendOtp() {
    this.disabled = true;
    this.disabledResend = true;
    let record: any = {};
    let serviceTarget = null;
    if (this.data.verifyEntityUserMobile) {
      serviceTarget = this.service.sendVerifyEntityOTPToEntityUser();
    } else if (this.data.caseId) {
      record = {
        caseId: this.data.caseId,
        caseIdType: this.data.caseIdType
      } as any;
      serviceTarget = this.matchMakingService.requestReportOTP(record);
    } else {
      record = {
        entityId: this.entityId,
        entityType: this.entityType
      } as any;
      serviceTarget = this.service.sendVerifyEntityOTP(record);
    }

    serviceTarget.subscribe(() => {
      this.disabled = false;
      this.otpInput.ressendOtp();
    }, err => {
      this.disabled = false;
      const errors = JSON.parse(err.detail);
      let message = '';
      if (err.detail) {
        errors.ErrorDTO.forEach((error) => {
          if (error.ErrorCode === 'NotAllowed') {
            message = error.Error;
          }
        });
      } else {
        message = err.title;
      }
      this.swalService.swalFailed(message);
    });
  }

  saveRecord() {
    this.isSubmitted = true;
    this.disabled = true;
    this.loading = true;
    let record: any = {};
    let serviceTarget = null;
    if (this.data.verifyEntityUserMobile) {
      record = {
        otp: this.otpNumber,
        isAuth: false
      };
      serviceTarget = this.service.verifyEntityUserMobile(record);
    } else if (this.data.caseId) {
      record = {
        caseId: this.data.caseId,
        caseIdType: this.data.caseIdType,
        otp: this.otpNumber
      } as any;
      serviceTarget = this.matchMakingService.verfiyOTP(record);
    } else {
      record = {
        otp: this.otpNumber,
        entityId: this.entityId,
        entityType: this.entityType
      } as any;
      serviceTarget = this.service.updateEntityVerificationOTP(record);
    }
    serviceTarget.subscribe(() => {
      this.loading = false;
      this.disabled = false;
      if (!this.data.caseId) {
        this.swalService.swalSuccess('Your mobile number has been verified successfuly');
        this.dialogRef.close(true);
      } else {
        this.dialogRef.close(this.otpNumber);
      }
    }, err => {
      this.loading = false;
      this.disabled = false;
      const errors = JSON.parse(err.detail);
      let message = '';
      if (err.detail) {
        errors.ErrorDTO.forEach((error) => {
          if (error.ErrorCode === 'WRONG_OTP' || error.ErrorCode === 'URL_EXPIRED') {
            message = error.Error;
          }
        });
      } else {
        message = err.title;
      }
      this.swalService.swalFailed(message);
    });

  }

}
