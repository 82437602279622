import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConcernedStaff } from 'src/app/models/cases/ConcernedStaff';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { PathologistSource } from 'src/app/models/cases/enums/PathologistSource';
import { PathologistStatus } from 'src/app/models/cases/enums/PathologistStatus';
import { Pathologiest } from 'src/app/models/cases/Pathologiest';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { ChatService } from 'src/app/services/chat.service';
import { SignalRService } from 'src/app/services/signal-rservice.service';


@Component({
  selector: 'app-concerned-staff',
  templateUrl: './concerned-staff.component.html'
})
export class ConcernedStaffComponent implements OnInit, OnDestroy {
  @Input() concernedStaff: ConcernedStaff[] = [];
  @Input() isAssignedPathologist: Pathologiest;
  @Input() caseIdType: CaseType;
  newChatUser: ConcernedStaff;

  constructor(
    public zone: NgZone,
    private signalRService: SignalRService,
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.signalRService.handleNewChatMessageEvent(this, this.newMessage);
    this.chatService.newChatUser.subscribe(user => {
      this.newChatUser = user;
    });

    this.activatedRoute.queryParamMap.subscribe(query => {
      if (query.get('chatUserId')) {
        const userSelected = this.concernedStaff.find(
          user => user.userId === Number(query.get('chatUserId')) && user.rolesName.includes(RolesEnum.Pathologist)
        );
        if (userSelected) {
          this.openChat(userSelected);
        }
      }
    });
  }

  counter(i: number) { return new Array(i); }

  openChatRoom(user) {
    if (!this.newChatUser || (this.newChatUser && this.newChatUser.userId !== user.userId)) {
      this.chatService.newChatUser.next(user);
    }
  }

  openChat(user) {
    this.chatService.newChatUser.next(user);
  }
  newMessage(context, loggedUserId, messageContent) {
    const userId = loggedUserId.split('_')[0];
    context.zone.run(() => {
      const findUser = context.concernedStaff.find(
        (user) => Number(user.userId) === Number(userId) && user.rolesName.includes(RolesEnum.Pathologist)
      );
      context.openChat(findUser);
    });
  }

  accesChat(record) {
    if (
      this.isAssignedPathologist &&
      this.isAssignedPathologist.source === PathologistSource.PRIMARY &&
      !this.isAssignedPathologist.isForQuality &&
      (
        this.isAssignedPathologist.status === PathologistStatus.ACCEPTED ||
        this.isAssignedPathologist.status === PathologistStatus.DRAFTED ||
        this.isAssignedPathologist.status === PathologistStatus.REQUESTED_SECOND_OPINION ||
        this.isAssignedPathologist.status === PathologistStatus.SENT_BACK ||
        this.isAssignedPathologist.status === PathologistStatus.SENT_BACK_SECOND_OPINION ||
        this.isAssignedPathologist.status === PathologistStatus.REPORTED
      ) &&
      (record.canChat || record.canViewHistory) && !record.isYou
    ) {
      return true;
    } else if (
      this.isAssignedPathologist &&
      this.isAssignedPathologist.source === PathologistSource.SECOND_OPNION &&
      (
        this.isAssignedPathologist.status === PathologistStatus.SUBMIT_COMMENTS ||
        this.isAssignedPathologist.status === PathologistStatus.ACCEPTED ||
        this.isAssignedPathologist.status === PathologistStatus.SENT_BACK_SECOND_OPINION
      ) &&
      (record.canChat || record.canViewHistory) && !record.isYou
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.chatService.newChatUser.next(null);
    this.concernedStaff = [];
  }
}
