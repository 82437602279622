import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { ListOldData, PagedData } from 'src/app/models/sla/ListOldData';
import { ListOldFilter } from 'src/app/models/sla/ListOldFilter';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SlaService } from 'src/app/services/sla.service';

export interface DialogData {
  entityId: number;
  entityType: EntityEnumSLA;
  entityName: string;
  isProfile: string;
}


@Component({
  selector: 'app-old-slas',
  templateUrl: './old-slas.component.html'
})
export class OldSlasComponent implements OnInit {

  dataLoading = false;
  // table
  pageNumber = 1;
  pageSize = 6;
  pageCount: number;
  recordsData: ListOldData = new ListOldData();
  filterModel: ListOldFilter = new ListOldFilter();
  slaList: Array<PagedData> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<OldSlasComponent>,
    private auth: AuthenticationService,
    private service: SlaService
  ) { }

  ngOnInit(): void {
    this.getAll(null);
  }

  getAll(event) {
    this.dataLoading = true;
    this.filterModel.PageSize = this.pageSize;
    this.filterModel.PageNumber = this.pageNumber;
    this.filterModel.EntityId = this.data.entityId;
    this.filterModel.EntityType = EntityEnumSLA[this.data.entityType];
    this.service.getSLAHistory(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListOldData;
          this.pageCount = Math.ceil(this.recordsData.slAsCount / this.filterModel.PageSize);
          if (this.recordsData.pagedData && this.recordsData.pagedData.length) {
            this.slaList = this.slaList.concat(this.recordsData.pagedData);
          }
        }
      },
      error => {
        this.dataLoading = false;
      }
    );
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1) {
      this.pageNumber += 1;
      this.getAll(false);
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
