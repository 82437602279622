import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge';
highchartsMore(Highcharts);
solidGauge(Highcharts);
@Component({
  selector: 'app-high-chart',
  templateUrl: './high-chart.component.html',
  styleUrls: ['./high-chart.component.scss']
})
export class HighChartComponent implements OnInit {
  @Input() data: any;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      title: this.data.title ? {
        text: this.data.title
      } : null,
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      // the value axis
      yAxis: {
        // title: {
        //   text: 'Lab Efficiency',
        // },
        min: this.data.min,
        max: this.data.max,
        reversed: true,
        // tickPixelInterval: 72,
        // tickPosition: 'inside',
        // tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        // tickLength: 20,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          // enabled: false,
          distance: 20,
          style: {
            fontSize: '14px'
          }
        },
        lineWidth: 0,

        plotBands: [
          {
            from: this.data.plot[0].from,
            to: this.data.plot[0].to,
            color: '#ee3650',
            thickness: 30,
            label: {
              x: -10,
              text: 'Poor',
              align: this.data.plot[0].labelPosition || 'right'
            }
          },
          {
            from: this.data.plot[1].from,
            to: this.data.plot[1].to,
            color: '#f4b184',
            thickness: 30,
            label: {
              x: -10,
              text: 'Fair',
              align: this.data.plot[1].labelPosition || 'right'
            }
          },
          {
            from: this.data.plot[2].from,
            to: this.data.plot[2].to,
            color: '#ffd656',
            thickness: 30,
            label: {
              text: 'Good',
              align: this.data.plot[2].labelPosition || 'right'
            }
          },
          {
            from: this.data.plot[3].from,
            to: this.data.plot[3].to,
            color: '#a8d08d',
            thickness: 30,
            label: {
              text: 'Great',
              align: this.data.plot[3].labelPosition || 'left'
            }
          },
          {
            from: this.data.plot[4].from,
            to: this.data.plot[4].to,
            color: '#70ad46',
            thickness: 30,
            label: {
              text: 'Excellent',
              align: this.data.plot[4].labelPosition || 'left'
            }
          },
        ]
      },
      series: [{
        type: 'gauge',
        name: 'Average Time',
        data: [this.data.value],
        tooltip: {
          valueSuffix: ' hour'
        },
        dataLabels: {
          // format: '{y}',
          useHTML: true,
          format: `<span style="text-align: center;display: block;">Lab Efficiency <br> ${this.data.percentage}</span>`,
          align: 'center',
          borderWidth: 0,
          color: (
            Highcharts.defaultOptions.title &&
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || '#333333',
          style: {
            fontSize: '11px'
          }
        },
        dial: {
          radius: '100%',
          backgroundColor: 'gray',
          baseWidth: 15,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 8
        }
      }]
    };
  }

}
