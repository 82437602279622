import { SlideOtherFormComponent } from './../slide-other-form/slide-other-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SlidesService } from 'src/app/services/slides.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListData, PagedData } from 'src/app/models/slides/ListData';
import { ListFilter } from 'src/app/models/slides/ListFilter';
import { Details } from 'src/app/models/cases/Details';
import { CasesService } from 'src/app/services/cases.service';
import { DRequestProcedure } from 'src/app/models/requests/enums/DRequestProcedure';
import { statusList } from 'src/app/models/cases/enums/statusList';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { DstatusList } from 'src/app/models/requests/enums/DstatusList';
import { Pathologiest } from 'src/app/models/cases/Pathologiest';
import { PathologistStatus } from 'src/app/models/cases/enums/PathologistStatus';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { ScanningCentersService } from 'src/app/services/scanningCenters.service';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { TranslateService } from '@ngx-translate/core';
import { CaselogProcedure } from 'src/app/models/cases/enums/CaselogProcedure';
import { ViewerSelectorComponent } from '../viewer-selector/viewer-selector.component';
import { DRequestType } from 'src/app/models/requests/enums/DRequestType';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { SlidesListComponent } from '../slides-list/slides-list.component';
import { SwalService } from 'src/app/services/swal.service';
import { SpecimenType } from 'src/app/models/cases/enums/SpecimenType';
import { ConcurelAccountComponent } from '../concurel-account/concurel-account.component';
import { CassetteSlideReferenceComponent } from '../cassette-slide-reference/cassette-slide-reference.component';
import { ActionEnum } from 'src/app/models/cases/enums/ActionEnum';

@Component({
  selector: 'app-slides-case',
  templateUrl: './slides-case.component.html'
})
export class SlidesCaseComponent implements OnInit {
  @ViewChild(SlidesListComponent) slideList;
  @Input() entityId: number;
  @Input() case: Details;
  @Input() pathologistId: number;
  @Input() requestId: number;
  @Input() pathologistType: string;
  @Input() communicationMethods: string;
  @Input() caseIdType: CaseType;
  @Input() selectedPathologiest: Pathologiest;
  @Input() isAssignedPathologist: Pathologiest;
  @Input() IsEndorsement: string;
  @Input() isPriorCases: boolean;
  @Output() refreshDetails = new EventEmitter<boolean>();
  @Output() pageEvent = new EventEmitter<number>();
  @Output() scrollToSlideSection = new EventEmitter<boolean>();
  currentUser = new AuthUser();
  dataLoading = false;
  // table
  @Input() pageIndex: number;
  pageCount = 0;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  submitUpdatesSlides: boolean;
  updateRequest: boolean;
  uploadSlides: boolean;
  enableEditingUpdates: boolean;
  disabledActions: boolean;
  loadingSlideReference: boolean;
  loadingArchiveAction: boolean;
  scList: Lookup[] = [];
  IsHaveActiveSla = true;
  excludedStatuses = [
    PathologistStatus.WITH_DRAW,
    PathologistStatus.REJECTED,
    PathologistStatus.REPORTED,
    PathologistStatus.CANCELLED,
    PathologistStatus.REVIEWED
  ];

  constructor(
    public dialog: MatDialog,
    private service: SlidesService,
    private caseService: CasesService,
    public translate: TranslateService,
    private auth: AuthenticationService,
    private scService: ScanningCentersService,
    private swalService: SwalService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {

    if (this.hasPermission(RolesEnum.ScanningCenterMainAdmin)) {
      this.getScaningCenter();
    }
    this.caseService.disabledActions.subscribe(data => {
      this.disabledActions = data;
    });
    this.caseService.enableSubmitUpdatesDetails.subscribe(data => {
      this.submitUpdatesSlides = data;
    });
    this.caseService.enableUpdateRequest.subscribe(data => {
      this.updateRequest = data;
    });
    this.caseService.enableUploadSlides.subscribe(data => {
      this.uploadSlides = data;
    });
    this.caseService.enableEditingUpdates.subscribe(data => {
      this.enableEditingUpdates = data;
    });
    this.IsHaveActiveSla = JSON.parse(localStorage.getItem('authUser')).IsHaveActiveSla;
  }


  counter(i: number) { return new Array(i); }

  receiveQrcode() {
    this.slideList.receiveQrcode();
  }

  canUpdateSlides(record: PagedData) {
    if (
      (
        !this.case.dRequest ||
        (
          this.case.dRequest &&
          !this.includeArray([DstatusList.IDLE, DstatusList.UpdatedRequestSource], this.case.dRequest.dRequestStatus) &&
          (
            !this.includeArray([DRequestProcedure.SCANNING_ONLY, DRequestProcedure.SCANNING_PROCESS], this.case.dRequest?.dRequestProcedure) ||
            (
              (
                this.case.dRequest?.dRequestProcedure === DRequestProcedure.SCANNING_ONLY &&
                this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus)
              ) ||
              (
                this.case.dRequest?.dRequestProcedure === DRequestProcedure.SCANNING_PROCESS &&
                this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus)
              )
            )
          )
        )
      ) &&
      (
        this.includeArray([statusList.IDLE, statusList.GROSSING_COMPLETED, statusList.UPLOAD_SLIDES, statusList.Reopened], this.case.caseStatus) ||
        (
          this.case.caseStatus === statusList.CaseHasRejectedRequest &&
          (
            (
              this.case.caseLog[0].procedure !== CaselogProcedure.CASE_HAS_REJECTED_REQUEST &&
              this.case.caseLog[0].userType === ModelsEnum.LabUser
            ) ||
            this.includeArray(
              [CaselogProcedure.CASE_HAS_REJECTED_REQUEST, CaselogProcedure.UPDATE_CASE, CaselogProcedure.UPDATE_SLIDE, CaselogProcedure.UPDATE_SPECIMEN_SECTION, CaselogProcedure.REMOVE_SLIDE_SECTION, CaselogProcedure.ADD_PATIENT_HISTORY, CaselogProcedure.DELETE_SLIDE],
              this.case.caseLog[0].procedure
            )
          )
        ) || (
          this.includeArray([statusList.CaseWithCancelledRequest, statusList.CaseCancelled], this.case.caseStatus) &&
          (
            !this.case.currentDRequet ||
            (
              this.case.currentDRequet &&
              this.case.currentDRequet.dRequestProcedure !== DRequestProcedure.PROCESS_ONLY
            )
          )
        ) ||
        this.case.caseStatus === statusList.SupervisorRejectCaseDetailsCase ||
        (
          this.case.caseStatus === statusList.SupervisorRejectUploadedSlides &&
          this.case.dRequest?.dRequestProcedure === DRequestProcedure.PROCESS_ONLY
        ) ||
        (
          this.case.caseStatus === statusList.CaseSubmittedAfterSentBackCaseDetails &&
          (
            this.getDiffDate(this.case.caseLatestModifiedDate, this.case.caseStatus, 'reverse') ||
            this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
          )
        ) ||
        (
          this.case.caseStatus === statusList.WaitingVlSupervisorProcess &&
          this.getDiffDate(this.case.caseLatestModifiedDate, this.case.caseStatus, 'reverse') &&
          (
            !this.case.dRequest ||
            (
              this.case.dRequest &&
              this.case.dRequest.dRequestProcedure !== DRequestProcedure.PROCESS_ONLY
            )
          )
        ) ||
        (
          this.case.caseStatus === statusList.WaitingVlSupervisorProcess &&
          (
            this.getDiffDate(this.case.caseLatestModifiedDate, this.case.caseStatus, 'reverse') ||
            this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
          ) &&
          this.case.dRequest &&
          this.case.dRequest.dRequestProcedure === DRequestProcedure.PROCESS_ONLY
        ) ||
        this.submitUpdatesSlides
      ) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician}`) ||
        (
          this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
          this.case.hasScanners
        )
      )
    ) {
      return true;
    } else if (
      !this.case.sentBackDRequest &&
      this.case.dRequest &&
      this.includeArray([DRequestProcedure.SCANNING_ONLY, DRequestProcedure.SCANNING_PROCESS], this.case.dRequest?.dRequestProcedure) &&
      this.case.dRequest.dRequestType === DRequestType.VIRTUAL_LAB &&
      (
        !this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse') ||
        !this.case.slidesLatestModifiedDate ||
        (
          this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse') &&
          this.case.slidesLatestModifiedRoles &&
          this.case.slidesLatestModifiedRoles.length &&
          (
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.SuperAdmin) ||
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.VLAdmin) ||
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.VLScanningTechnician)
          )
        )
      ) &&
      (
        this.includeArray([statusList.SupervisorRejectUploadedSlides, statusList.UPLOAD_SLIDES, statusList.CaseWithRequest], this.case.caseStatus) ||
        this.uploadSlides ||
        this.updateRequest ||
        (
          this.includeArray(
            [statusList.CaseHasApprovedRequest, statusList.CaseReuploadedSlides, statusList.WaitingVlSupervisorProcess, statusList.CaseHasRejectedRequest, statusList.TechnicianRefusedSupervisorComments],
            this.case.caseStatus
          ) &&
          this.case.slidesLatestModifiedDate &&
          this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
        )
      ) &&
      (
        !this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus) ||
        (
          this.case.dRequest.dRequestStatus === DstatusList.REJECTED &&
          (
            (
              this.case.caseLog[0].procedure !== CaselogProcedure.CASE_HAS_REJECTED_REQUEST &&
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
            ) ||
            this.uploadSlides ||
            this.updateRequest
          )
        )
      ) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType === CaseType.RefCase
        ) ||
        (
          (
            !record ||
            (
              record && record.creatorType === ModelsEnum.VLUser
            )
          ) &&
          this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`)
        )
      )
    ) {
      return true;
    } else if (
      !this.case.sentBackDRequest &&
      (
        this.includeArray([statusList.IDLE, statusList.GROSSING_COMPLETED, statusList.UPLOAD_SLIDES, statusList.Reopened], this.case.caseStatus) ||
        (
          this.case.caseStatus === statusList.CaseWithCancelledRequest &&
          (
            !this.case.currentDRequet ||
            (
              this.case.currentDRequet &&
              this.case.currentDRequet.dRequestProcedure !== DRequestProcedure.PROCESS_ONLY
            )
          )
        ) ||
        this.includeArray([statusList.CaseHasRejectedRequest, statusList.CaseCancelled, statusList.SupervisorRejectCaseDetailsCase], this.case.caseStatus) ||
        (
          this.case.caseStatus === statusList.CaseSubmittedAfterSentBackCaseDetails &&
          (
            this.getDiffDate(this.case.caseLatestModifiedDate, this.case.caseStatus, 'reverse') ||
            this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
          )
        ) ||
        this.submitUpdatesSlides
      ) &&
      this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`)
    ) {
      return true;
    } else if (
      !this.case.sentBackDRequest &&
      this.case.dRequest &&
      this.includeArray([DRequestProcedure.SCANNING_PROCESS, DRequestProcedure.SCANNING_ONLY], this.case.dRequest?.dRequestProcedure) &&
      this.case.dRequest.dRequestType === DRequestType.SCANNING_CENTER &&
      (
        !this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse') ||
        !this.case.slidesLatestModifiedDate ||
        (
          this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse') &&
          this.case.slidesLatestModifiedRoles &&
          this.case.slidesLatestModifiedRoles.length &&
          (
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.SuperAdmin) ||
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.ScanningCenterAdmin) ||
            this.includeArray(this.case.slidesLatestModifiedRoles, RolesEnum.SCTechnician)
          )
        )
      ) &&
      (
        this.includeArray([statusList.SupervisorRejectUploadedSlides, statusList.CaseWithRequest, statusList.UPLOAD_SLIDES], this.case.caseStatus) ||
        this.uploadSlides || this.updateRequest ||
        (
          this.includeArray(
            [statusList.CaseHasApprovedRequest, statusList.CaseReuploadedSlides, statusList.WaitingVlSupervisorProcess, statusList.CaseHasRejectedRequest, statusList.TechnicianRefusedSupervisorComments],
            this.case.caseStatus
          ) &&
          this.case.slidesLatestModifiedDate &&
          this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
        )
      ) &&
      (
        !this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus) ||
        (
          this.case.dRequest.dRequestStatus === DstatusList.REJECTED &&
          (
            (
              this.case.caseLog[0].procedure !== CaselogProcedure.CASE_HAS_REJECTED_REQUEST &&
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.caseStatus, 'reverse')
            ) ||
            this.uploadSlides ||
            this.updateRequest
          )
        )
      ) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType === CaseType.RefCase
        ) ||
        (
          (
            !record ||
            (
              record && record.creatorType === ModelsEnum.SCUser &&
              (
                record.creatorId === this.currentUser.ObjectValue ||
                this.isInArray(this.scList, record.creatorId)
              )
            )
          ) &&
          this.hasPermission(RolesEnum.ScanningCenterMainAdmin)
        ) ||
        (
          (
            !record ||
            (
              record && record.creatorType === ModelsEnum.SCUser &&
              (
                record.creatorId === this.currentUser.ObjectValue
              )
            )
          ) &&
          this.hasPermission(`${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`)
        )
      )
    ) {
      return true;
    } else if (
      record &&
      this.case.caseStatus === statusList.MatchMaking &&
      record.assignedPersons &&
      record.assignedPersons.length === 0 &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician}`) ||
        (
          this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
          this.case.hasScanners
        )
      )
    ) {
      return true;
    } else if (
      !this.case.sentBackDRequest &&
      record &&
      this.case.caseStatus === statusList.MatchMaking &&
      record.assignedPersons &&
      record.assignedPersons.length === 0 &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`)
      )
    ) {
      return true;
    } else if (
      this.caseIdType === CaseType.RefCase &&
      this.includeArray([statusList.READY_TO_UPLOAD_SLIDES, statusList.READY_TO_GROSSING, statusList.READY_TO_UPLOAD_SLIDES_REF], this.case.caseStatus) &&
      this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician},${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician},${RolesEnum.SuperAdmin}`)
    ) {
      return true;
    } else if (
      this.caseIdType !== CaseType.RefCase &&
      (
        !this.case.dRequest ||
        (
          this.case.dRequest &&
          (
            this.case.dRequest.dRequestProcedure !== DRequestProcedure.SCANNING_PROCESS ||
            (
              this.case.dRequest.dRequestProcedure === DRequestProcedure.SCANNING_PROCESS &&
              this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus)
            )
          )
        )
      ) &&
      (
        this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician},${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`) ||
        (
          this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
          this.case.hasScanners
        )
      ) &&
      (
        this.case.isCaseSentBack &&
        !this.case.isAssignedPathologist &&
        !this.selectedPathologiest &&
        (
          !this.case.sentBackDRequest ||
          (
            this.case.sentBackDRequest &&
            this.includeArray(
              [DstatusList.REJECTED, DstatusList.CANCELLED, DstatusList.WAITING_DELIVERY, DstatusList.SLIDES_DELIVERIED, DstatusList.SLIDES_RECIEVED],
              this.case.sentBackDRequest.dRequestStatus
            )
          )
        )
      )
    ) {
      return true;
    } else if (
      this.caseIdType === CaseType.RefCase &&
      this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician},${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`) &&
      (
        this.case.currentDRequet &&
        this.case.currentDRequet.dRequestProcedure === DRequestProcedure.SCANNING_PROCESS
      ) &&
      (
        this.case.isCaseSentBack &&
        !this.case.isAssignedPathologist &&
        !this.selectedPathologiest &&
        (
          (
            !this.case.sentBackStatus &&
            this.case.grossingPathologist
          ) ||
          (
            this.case.sentBackStatus &&
            (
              (
                (
                  this.case.sentBackStatus === statusList.CASE_SENT_BACK_READY &&
                  this.case.specimenType !== SpecimenType.CONTAINERS
                ) ||
                (
                  this.case.specimenType === SpecimenType.CONTAINERS &&
                  this.case.sentBackReasonType === CaselogProcedure.ADD_SLIDE &&
                  this.case.sentBackStatus === statusList.CASE_SENT_BACK_READY
                )
              )
            )
          )
        ) &&
        (
          !this.case.dRequest ||
          (
            this.case.dRequest &&
            (
              this.case.dRequest.dRequestProcedure !== DRequestProcedure.SCANNING_PROCESS ||
              (
                this.case.dRequest.dRequestProcedure === DRequestProcedure.SCANNING_PROCESS &&
                this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus)
              )
            )
          )
        ) &&
        (
          !this.case.sentBackDRequest ||
          (
            this.case.sentBackDRequest &&
            this.includeArray(
              [DstatusList.REJECTED, DstatusList.CANCELLED, DstatusList.WAITING_DELIVERY, DstatusList.SLIDES_DELIVERIED, DstatusList.SLIDES_RECIEVED],
              this.case.sentBackDRequest.dRequestStatus
            )
          )
        )
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  canCreateSlide() {
    if (this.includeArray([statusList.MatchMaking, statusList.LabSuervisorReviewed], this.case.caseStatus)) {
      if (
        this.selectedPathologiest &&
        (
          (
            this.includeArray([PathologistStatus.SENT_BACK, PathologistStatus.REJECTED_PASS_PATHOLOGISTS], this.selectedPathologiest.status) &&
            (
              !this.case.sentBackDRequest ||
              (
                this.case.sentBackDRequest &&
                this.includeArray([DstatusList.REJECTED, DstatusList.CANCELLED, DstatusList.SUPERVISOR_APPROVED_SLIDES], this.case.sentBackDRequest.dRequestStatus)
              )
            )
          ) ||
          (
            (
              (
                this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
                this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
                (
                  (
                    this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  (
                    this.getDiffDate(null, PathologistStatus.UPDATES_SUBMITTED, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  (
                    (
                      this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', PathologistStatus.UPDATES_SUBMITTED) ||
                      this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', PathologistStatus.UPDATES_SUBMITTED)
                    ) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  this.enableEditingUpdates
                )
              ) ||
              this.includeArray([PathologistStatus.SENT_BACK, PathologistStatus.REJECTED_PASS_PATHOLOGISTS, PathologistStatus.REQUEST_REJECTED], this.selectedPathologiest.status) ||
              (
                this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                  this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                  this.enableEditingUpdates
                )
              )
            ) &&
            (
              !this.case.sentBackDRequest ||
              (
                this.case.sentBackDRequest &&
                (
                  (
                    this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED &&
                    this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, null, this.selectedPathologiest.status)
                  ) ||
                  this.case.sentBackDRequest.dRequestStatus === DstatusList.CANCELLED ||
                  (
                    this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES &&
                    this.case.caseLog[0].userType === ModelsEnum.LabUser &&
                    (
                      this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) ||
                      this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) ||
                      this.enableEditingUpdates
                    )
                  )
                )
              )
            )
          )
        ) &&
        (
          (
            this.hasPermission(RolesEnum.SuperAdmin) &&
            this.caseIdType !== CaseType.RefCase
          ) ||
          this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician}`) ||
          (
            this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
            this.case.hasScanners
          )
        )
      ) {
        return true;
      } else if (
        (
          !this.case.sentBackDRequest &&
          (
            this.selectedPathologiest &&
            (
              (
                this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
                this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
                (
                  (
                    this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  (
                    this.getDiffDate(null, PathologistStatus.UPDATES_SUBMITTED, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  (
                    (
                      this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', PathologistStatus.UPDATES_SUBMITTED) ||
                      this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', PathologistStatus.UPDATES_SUBMITTED)
                    ) &&
                    this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                  ) ||
                  this.enableEditingUpdates
                )
              ) ||
              this.selectedPathologiest.status === PathologistStatus.SENT_BACK ||
              (
                this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                  this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status
                  ) ||
                  this.enableEditingUpdates
                )
              ) ||
              this.selectedPathologiest.status === PathologistStatus.REJECTED_PASS_PATHOLOGISTS
            )
          )
        ) &&
        this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician},${RolesEnum.SuperAdmin}`)
      ) {
        return true;
      } else if (
        !this.case.isCaseClosed &&
        this.case.sentBackDRequest &&
        this.case.sentBackDRequest?.dRequestProcedure === DRequestProcedure.SCAN_SENT_BACK &&
        this.case.sentBackDRequest.dRequestType === DRequestType.VIRTUAL_LAB &&
        (
          this.case.sentBackDRequest.dRequestStatus === DstatusList.IDLE ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES &&
            (
              (
                !this.includeArray([CaselogProcedure.SUPERVISOR_APPROVED_SLIDES, CaselogProcedure.SENT_BACK], this.case.caseLog[0].procedure) &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', 'UPDATES_SUBMITTED') ||
                  this.uploadSlides
                )
              )
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.TECHNICIAN_SUBMIT_UPDATES &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', PathologistStatus.UPDATES_SUBMITTED) ||
              this.uploadSlides
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.TECHNICIAN_REJECT &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', DstatusList.TECHNICIAN_REJECT) ||
              this.uploadSlides
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', 'REQUEST_REJECTED') ||
              this.uploadSlides
            )
          ) ||
          this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED_PASS_PATHOLOGISTS
        ) &&
        (
          (
            this.hasPermission(RolesEnum.SuperAdmin) &&
            this.caseIdType === CaseType.RefCase
          ) ||
          this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`)
        )
      ) {
        return true;
      } else if (
        !this.case.isCaseClosed &&
        this.case.sentBackDRequest &&
        this.case.sentBackDRequest?.dRequestProcedure === DRequestProcedure.SCAN_SENT_BACK &&
        this.case.sentBackDRequest.dRequestType === DRequestType.SCANNING_CENTER &&
        (
          this.case.sentBackDRequest.dRequestStatus === DstatusList.IDLE ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES &&
            (
              (
                !this.includeArray([CaselogProcedure.SUPERVISOR_APPROVED_SLIDES, CaselogProcedure.SENT_BACK], this.case.caseLog[0].procedure) &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', 'UPDATES_SUBMITTED') ||
                  this.uploadSlides
                )

              )
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.TECHNICIAN_SUBMIT_UPDATES &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', PathologistStatus.UPDATES_SUBMITTED
              ) ||
              this.uploadSlides
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.TECHNICIAN_REJECT &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', DstatusList.TECHNICIAN_REJECT) ||
              this.uploadSlides
            )
          ) ||
          (
            this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED &&
            (
              this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', 'REQUEST_REJECTED') ||
              this.uploadSlides
            )
          ) ||
          this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED_PASS_PATHOLOGISTS
        ) &&
        (
          (
            this.hasPermission(RolesEnum.SuperAdmin) &&
            this.caseIdType === CaseType.RefCase
          ) ||
          this.hasPermission(`${RolesEnum.ScanningCenterMainAdmin},${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`)
        )
      ) {
        return true;
      } else if (
        !this.case.isCaseClosed &&
        this.case.sentBackDRequest &&
        this.case.sentBackDRequest?.dRequestProcedure === DRequestProcedure.SCAN_SENT_BACK &&
        this.case.sentBackDRequest.dRequestType === DRequestType.SCANNING_CENTER &&
        this.case.pathologiests && this.case.pathologiests.length &&
        (
          (
            this.hasPermission(RolesEnum.SuperAdmin) &&
            this.caseIdType === CaseType.RefCase
          ) ||
          this.hasPermission(`${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`)
        ) &&
        (
          this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
          (
            this.includeArray([DstatusList.SUPERVISOR_APPROVED_SLIDES, DstatusList.REJECTED], this.case.sentBackDRequest.dRequestStatus) &&
            (
              this.uploadSlides ||
              (
                this.case.caseLog &&
                this.case.caseLog[0].procedure !== CaselogProcedure.SUPERVISOR_APPROVED_SLIDES &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', PathologistStatus.SUPERVISOR_APPROVED_SLIDES) ||
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', this.case.sentBackDRequest.dRequestStatus)
                )
              )

            )
          )
        )
      ) {
        return true;
      } else if (
        !this.case.isCaseClosed &&
        this.case.sentBackDRequest &&
        this.case.sentBackDRequest?.dRequestProcedure === DRequestProcedure.SCAN_SENT_BACK &&
        this.case.sentBackDRequest.dRequestType === DRequestType.VIRTUAL_LAB &&
        this.case.pathologiests && this.case.pathologiests.length &&
        (
          (
            this.hasPermission(RolesEnum.SuperAdmin) &&
            this.caseIdType === CaseType.RefCase
          ) ||
          this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician}`)
        ) &&
        (
          this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
          (
            this.includeArray([DstatusList.SUPERVISOR_APPROVED_SLIDES, DstatusList.REJECTED], this.case.sentBackDRequest.dRequestStatus) &&
            (
              this.uploadSlides ||
              (
                this.case.caseLog &&
                this.case.caseLog[0].procedure !== CaselogProcedure.SUPERVISOR_APPROVED_SLIDES &&
                (
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', PathologistStatus.SUPERVISOR_APPROVED_SLIDES) ||
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.case.sentBackDRequest.dRequestStatus, 'reverse', this.case.sentBackDRequest.dRequestStatus)
                )
              )
            )
          )
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canAddOtherSlide() {
    if (
      this.selectedPathologiest &&
      (
        (
          this.includeArray([PathologistStatus.SENT_BACK, PathologistStatus.REJECTED_PASS_PATHOLOGISTS], this.selectedPathologiest.status) &&
          (
            !this.case.sentBackDRequest ||
            (
              this.case.sentBackDRequest &&
              this.includeArray([DstatusList.REJECTED, DstatusList.CANCELLED, DstatusList.SUPERVISOR_APPROVED_SLIDES], this.case.sentBackDRequest.dRequestStatus)
            )
          )
        ) ||
        (
          (
            (
              this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
              this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
              (
                (
                  this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                (
                  this.getDiffDate(null, PathologistStatus.UPDATES_SUBMITTED, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                this.enableEditingUpdates
              )
            ) ||
            this.includeArray([PathologistStatus.REJECTED_PASS_PATHOLOGISTS, PathologistStatus.REQUEST_REJECTED], this.selectedPathologiest.status) ||
            (
              this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
              (
                this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                this.enableEditingUpdates
              )
            )
          ) &&
          (
            !this.case.sentBackDRequest ||
            (
              this.case.sentBackDRequest &&
              (
                (
                  this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED &&
                  this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, null, this.selectedPathologiest.status)
                ) ||
                this.case.sentBackDRequest.dRequestStatus === DstatusList.CANCELLED ||
                (
                  this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES &&
                  this.case.caseLog[0].userType === ModelsEnum.LabUser &&
                  (
                    this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) ||
                    this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
                    ) ||
                    this.enableEditingUpdates
                  )
                )
              )
            )
          )
        )
      ) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician}`) ||
        (
          this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
          this.case.hasScanners
        )
      )
    ) {
      return true;
    } else if (
      (
        !this.case.dRequest &&
        !this.case.sentBackDRequest &&
        (
          this.selectedPathologiest &&
          (
            (
              this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
              this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
              (
                (
                  this.getDiffDate(null, this.selectedPathologiest.status, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                (
                  this.getDiffDate(null, PathologistStatus.UPDATES_SUBMITTED, 'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                this.enableEditingUpdates
              )
            ) ||
            this.selectedPathologiest.status === PathologistStatus.SENT_BACK ||
            (
              this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
              (
                this.getDiffDate(this.case.slidesLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                this.getDiffDate(this.case.caseLatestModifiedDate, this.selectedPathologiest.status, 'reverse', this.selectedPathologiest.status) ||
                this.enableEditingUpdates
              )
            ) ||
            this.selectedPathologiest.status === PathologistStatus.REJECTED_PASS_PATHOLOGISTS
          )
        )
      )
      &&
      this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician},${RolesEnum.SuperAdmin}`)
    ) {
      return true;
    } else {
      return false;
    }
  }

  canAssignedPathologists() {
    if (
      this.includeArray([statusList.MatchMaking, statusList.CASE_REVIEWED_SUCCESFULLY], this.case.caseStatus) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabSupervisor},${RolesEnum.MatchMaker},${RolesEnum.LabScanningTechnician}`)
      )
    ) {
      return true;
    } else if (
      this.case.dRequest &&
      this.includeArray([DRequestProcedure.SCANNING_PROCESS, DRequestProcedure.PROCESS_ONLY], this.case.dRequest?.dRequestProcedure) &&
      this.includeArray([DRequestType.VIRTUAL_LAB, DRequestType.SCANNING_CENTER], this.case.dRequest.dRequestType) &&
      !this.includeArray([DstatusList.CANCELLED, DstatusList.REJECTED], this.case.dRequest.dRequestStatus) &&
      this.includeArray([statusList.MatchMaking, statusList.CASE_REVIEWED_SUCCESFULLY], this.case.caseStatus) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType === CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLMatchMaker},${RolesEnum.VLSupervisor},${RolesEnum.VLScanningTechnician}`)
      )
    ) {
      return true;
    } else if (
      !this.case.dRequest &&
      !this.case.sentBackDRequest &&
      this.includeArray([statusList.MatchMaking, statusList.CASE_REVIEWED_SUCCESFULLY], this.case.caseStatus) &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.VLAdmin},${RolesEnum.VLMatchMaker},${RolesEnum.VLSupervisor},${RolesEnum.VLScanningTechnician}`)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  canAddSlideReference() {
    if (
      (
        (
          this.case.isCaseSentBack
          && !this.case.isAssignedPathologist
          && this.case.sentBackReasonType === CaselogProcedure.ADD_SLIDE
          && !this.selectedPathologiest
          && (
            (
              this.caseIdType !== CaseType.RefCase &&
              this.case.specimenType !== SpecimenType.CONTAINERS
            ) ||
            (
              this.case.specimenType === SpecimenType.CONTAINERS &&
              this.case.sentBackReasonType === CaselogProcedure.ADD_SLIDE &&
              this.case.grossingPathologist
            )
          ) &&
          (
            !this.case.sentBackDRequest ||
            (
              this.case.sentBackDRequest &&
              this.includeArray(
                [DstatusList.REJECTED, DstatusList.CANCELLED, DstatusList.WAITING_DELIVERY, DstatusList.SLIDES_DELIVERIED, DstatusList.SLIDES_RECIEVED],
                this.case.sentBackDRequest.dRequestStatus
              )
            )
          )
        ) ||
        (
          this.caseIdType !== CaseType.RefCase &&
          (
            this.case.caseStatus === statusList.IDLE || this.case.caseStatus == statusList.UPLOAD_SLIDES ||
            (
              this.case.caseStatus === statusList.CaseWithCancelledRequest &&
              (
                !this.case.currentDRequet ||
                (
                  this.case.currentDRequet &&
                  this.case.currentDRequet.dRequestProcedure !== DRequestProcedure.PROCESS_ONLY
                )
              )
            )
          )
        ) ||
        this.case.caseStatus === statusList.GROSSING_COMPLETED
      ) &&
      this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.LabAdmin},${RolesEnum.LabScanningTechnician},${RolesEnum.VLAdmin},${RolesEnum.VLScanningTechnician},${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`)
    ) {
      return true;
    } else if (this.case && this.case.actions && this.case.actions.length && this.includeActions([ActionEnum.ADD_SLIDE_REF])) {
      return true;
    } else {
      return false;
    }
  }

  addOtherSlide() {
    const dialogRef = this.dialog.open(SlideOtherFormComponent, {
      width: '906px',
      data: {
        caseId: this.case.caseId,
        userId: this.pathologistId,
        userType: this.pathologistType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.caseService.refreshDetails.next(true);
      }
    });
  }
  openViewerSelector(isShare = false) {
    if (this.case.concuralUserCredentials.conCuralUserName || this.case.concuralUserCredentials.conCuralPassword) {
      localStorage.removeItem('concurelUser');
    }
    const dialogRef = this.dialog.open(ViewerSelectorComponent, {
      width: '600px',
      disableClose: isShare,
      data: {
        caseId: this.entityId,
        slides: this.recordsData.pagedData,
        case: this.case,
        entityId: this.entityId,
        pathologistId: this.pathologistId,
        requestId: this.requestId,
        pathologistType: this.pathologistType,
        IsEndorsement: this.IsEndorsement,
        caseIdType: this.caseIdType,
        isShare: isShare,
        conCuralUserName: this.case.concuralUserCredentials ? this.case.concuralUserCredentials.conCuralUserName : null,
        conCuralPassword: this.case.concuralUserCredentials ? this.case.concuralUserCredentials.conCuralPassword : null,
        isPriorCases: this.isPriorCases
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (isShare && result) {
        this.openConcurel(result)
      }
    });
  }

  isConcurelUser(): boolean {
    const concurelUser = localStorage.getItem('concurelUser');
    return concurelUser !== null && concurelUser !== undefined;
  }

  openConcurel(result) {
    // if (this.case.concuralUserCredentials.conCuralUserName || this.case.concuralUserCredentials.conCuralPassword) {
    //   localStorage.removeItem('concurelUser');
    // }
    const dialogRef = this.dialog.open(ConcurelAccountComponent, {
      width: '960px',
      height: 'auto',
      panelClass: 'concurel-dialog',
      data: {
        conCuralUserName: this.case.concuralUserCredentials ? this.case.concuralUserCredentials.conCuralUserName : null,
        conCuralPassword: this.case.concuralUserCredentials ? this.case.concuralUserCredentials.conCuralPassword : null,
        slideList: result.slideList,
        caseType: this.caseIdType,
        caseId: this.case.caseId,
        notAuthorized: result && result.notAuthorized
      }
    })
  }

  getDiffDate(date, status, type = null, procedure = null) {
    return this.caseService.getDiffDate({ case: this.case, date, status, type, procedure });
  }

  getScaningCenter() {
    this.scService.getAllScanningCentersLookup().subscribe(
      data => {
        this.scList = data as [];
      }, err => {
        console.error(err);
      });
  }

  includeActions(item) {
    return this.case.actions.some(record => item.includes(record.button_action));
  }

  isInArray(parentObj, id) {
    return parentObj.some(obj => obj.id === id);
  }

  includeArray(parentObj, item) {
    return parentObj.includes(item);
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  addSlideReference() {
    const data = {
      caseId: this.caseIdType === CaseType.RefCase ? this.case.caseRequestId : this.case.caseId,
      caseIdType: this.caseIdType,
      specimenType: this.case.specimenType,
      tissueType: this.case.tissueType
    }
    this.addCassetteSlideReference(data);
    // if (this.case.containerData) {
    //   this.addCassetteSlideReference(data);
    // } else {
    //   this.loadingSlideReference = true;
    //   this.service.addSlideReference(data).subscribe((res) => {
    //     if (res) {
    //       this.loadingSlideReference = false;
    //       this.caseService.scrollToSection.next('slides');
    //       this.caseService.refreshDetails.next(true);
    //       //this.scrollToSlideSection.emit(true)
    //     }
    //     // this.swalService.swalSuccess('Saved Successfully')
    //     //   .then((result) => {
    //     //     this.caseService.refreshDetails.next(true);
    //     //   });
    //   }, err => {
    //     this.loadingSlideReference = false;
    //   });
    // }
  }

  addCassetteSlideReference(data) {
    const dialogRef = this.dialog.open(CassetteSlideReferenceComponent, {
      width: '550px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.caseService.refreshDetails.next(true);
      }
    });
  }

  archiveAction() {
    this.loadingArchiveAction = true;
    const query = {
      Id: this.entityId,
      Type: this.caseIdType
    };
    this.caseService.archiveAction(query).subscribe((res) => {
      if (res) {
        this.loadingArchiveAction = false;
      }
      this.swalService.swalSuccess('Saved Successfully')
        .then((result) => {
          this.caseService.refreshDetails.next(true);
        });
    }, err => {
      this.loadingArchiveAction = false;
    });
  }

  paginationEvent(e) {
    this.pageEvent.emit(e);
  }
}
