import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { IHttpConnectionOptions } from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  ConnectionStarted = false;

  constructor(private authService: AuthenticationService) { }

  public startConnection = () => {
    const options: IHttpConnectionOptions = {
      transport: signalR.HttpTransportType.WebSockets || signalR.HttpTransportType.LongPolling
    };

    if (this.authService.currentUserValue) {
      options.accessTokenFactory = () => this.authService.currentUserValue.Token
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalR, options)
      .build();

    this.hubConnection
      .start()
      .then(() => { this.ConnectionStarted = true; console.log('Connection started ...') })
      .catch(err => console.log('Error while starting connection ... ' + err));

  }
  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => console.log('Connection stopped ...'))
        .catch(err => console.log('Error while stopping connection ... ' + err));
    }
  }

  // Viewer OutSource
  handleNewOutSourceRemarksUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newOutSourceRemarksUpdate', (token) => {
        handleFunc(context, token);
      });
    }
  }

  // Case
  handleNewCaseUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newCaseUpdate', (caseId) => {
        handleFunc(context, caseId);
      });
    }
  }
  // invoice Updated
  invoiceUpdatedEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('invoiceUpdated', (invoiceId) => {
        handleFunc(context, invoiceId);
      });
    }
  }
  // invoice Updated
  handleRefreshAllInvoicesEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('refreshAllInvoices', () => {
        handleFunc(context);
      });
    }
  }
  // Referred Case
  handleNewRefCaseUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newRefCaseUpdate', (caseId) => {
        handleFunc(context, caseId);
      });
    }
  }

  // Request
  handleNewRequestUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newRequestUpdate', (requestId) => {
        handleFunc(context, requestId);
      });
    }
  }

  // SLA
  handleNewSLAUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newSLAStatusUpdate', (slaId) => {
        handleFunc(context, slaId);
      });
    }
  }
  handleNewSLAContractStatusUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newSLAContractStatusUpdate', (slaId) => {
        handleFunc(context, slaId);
      });
    }
  }

  // Lab
  handleNewLabUpdateEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('newLabUpdate', (labId) => {
        handleFunc(context, labId);
      });
    }
  }


  // report
  handleNewReportUpdateEvent = (context, handleFunc) => {
    this.hubConnection.on('newReportUpdate', (reportId) => {
      handleFunc(context, reportId);
    });
  }

  // chat
  handleNewChatMessageEvent = (context, handleFunc) => {
    this.hubConnection.on('newChatMessage', (loggedUserId, messageContent) => {
      handleFunc(context, loggedUserId, messageContent);
    });
  }


  // Sign Out
  handleSignOutEvent = (context, handleFunc) => {
    if (this.hubConnection) {
      this.hubConnection.on('signOutUser', (userId) => {
        handleFunc(context, userId);
      });
    }
  }

  sendAlert = (context, handleFunc) => {
    this.hubConnection.on('sendAlert', (data) => {
      handleFunc(context, data);
    });
  }
  disposeAlert = (context, handleFunc) => {
    this.hubConnection.on('disposeAlert', (taskGuid) => {
      handleFunc(context, taskGuid);
    });
  }
  changeNotificationCount = (context, handleFunc) => {
    this.hubConnection.on('changeNotificationCount', (count) => {
      handleFunc(context, count);
    });
  }


}
