import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  containerSpecimensNumber: number;
  specimensNumber: number;
}

@Component({
  selector: 'app-receive-specimen',
  templateUrl: './receive-specimen.component.html'
})
export class ReceiveSpecimenComponent implements OnInit {
  dataLoading = false;
  loading = false;
  formGroup: FormGroup;
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ReceiveSpecimenComponent>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      specimensNumber: [this.data.specimensNumber, [Validators.required, Validators.max(this.data.containerSpecimensNumber), Validators.min(1)]]
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close(Number(this.formGroup.controls.specimensNumber.value));
      }, 1000);
    }
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
      }
    });
  }

}
