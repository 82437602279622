import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrData } from 'src/app/models/cases/QrData';
import { QrcodeType } from 'src/app/models/slides/enums/QrcodeType';

export interface DialogData {
  typeQrcode: QrcodeType,
  record: {
    qrToken: string;
    generatedSlideReference: string;
    qrData: QrData;
  };
}

@Component({
  selector: 'app-print-qrcode',
  templateUrl: './print-qrcode.component.html'
})
export class PrintQrcodeComponent implements OnInit {
  loading = false;
  QrCode: string;
  today = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<PrintQrcodeComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    console.log(this.data);

    this.QrCode = decodeURIComponent(this.data.record.qrToken);
  }

  print(id) {

    const lang = localStorage.getItem('language') || 'en';
    const basicUrl = location.origin;
    let style = basicUrl + '/assets/css/en-US/style.css';
    let dir = 'ltr';
    if (lang === 'ar') {
      style = basicUrl + '/assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    const WindowPrt = window.open('', 'PRINT', 'left=0,top=0,width=700,height=600,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write('<html dir="' + dir + '"><head>\
      <base href="/">\
      <link rel="stylesheet" type="text/css" href="' + style + '">\
      <style>@media print{@page{size:1.9in 2.4in;page-break-after: always;}}</style>\
      <title>' + document.title + '</title>');
    WindowPrt.document.write('</head><body class="printable-doc">');
    // mywindow.document.write('<div class="print-logo"><img src="./assets/images/logo/logo.png" /></div>');
    if (document.getElementById(id)) {
      WindowPrt.document.write(document.getElementById(id).innerHTML);
    }
    WindowPrt.document.write('</body></html>');


    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.onload = () => {
      WindowPrt.print();
      //WindowPrt.close();
    };
  }

}
