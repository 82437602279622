<div class="floating-details {{custClass}}" [ngClass]="{'activeDetails': open}">
  <div class="d-flex h-100">
    <div class="overlay" (click)="close()">
      <div class="closeDetails">
        <img src="./assets/images/icons/close-icon.svg" alt="">
      </div>
    </div>
    <div class="details-container">
      <ng-container *ngIf="open">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
