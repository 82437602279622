<mat-dialog-content class="qrcode-content">
  <div class="mat-dialog-body">
    <div class="mat-dialog-inner" id="qrCodeEleme">
      <ng-container *ngIf="data && data.typeQrcode == 'CONTAINER'">
        <div class="container_print_qrcode">
          <div class="inner_media">
            <div>
              <h4 class="title_media">
                MRN: 000001 - {{data.record.qrData.mrn}}
              </h4>

              <h4 class="subtitle_media">
                {{data.record.qrData.location}}-SP-{{data.record.qrData.year}}-{{data.record.qrData.caseId}}
                <span>{{data.record.qrData.containerId}}.</span>
              </h4>
            </div>
            <div class="content_media">
              <!-- <p>
              right cheek mass
            </p> -->
              <p>
                {{today | date:'d/MM/y h:mm:ss'}} {{data.record.qrData.tissue}}
              </p>
            </div>
          </div>
          <qrcode [elementType]='"img"' [margin]="0" [qrdata]="QrCode" [width]="130" [errorCorrectionLevel]="'M'"
            class="bdcase-qrocde"></qrcode>
        </div>
        <div class="container_print_qrcode container_small">
          <div>
            <h4 class="title_media">
              00001 - {{data.record.qrData.mrn}}
            </h4>

            <h4 class="subtitle_media">
              {{data.record.qrData.location}}-SP-{{data.record.qrData.year}}-{{data.record.qrData.caseId}}
            </h4>
          </div>
          <div class="inner_media">
            <div class="content_media">
              <!-- <p>
                right cheek mass
              </p> -->
              <p>
                {{today | date:'d/MM/y h:mm:ss'}}
              </p>
              <p>{{data.record.qrData.tissue}}</p>
              <p>SpecID: {{data.record.qrData.specimenNumber}}</p>
            </div>
            <qrcode [elementType]='"img"' [margin]="0" [qrdata]="QrCode" [width]="130" [errorCorrectionLevel]="'M'"
              class="bdcase-qrocde"></qrcode>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data && data.typeQrcode == 'CASSETTE'">
        <div class="cassette_print_qrcode">
          <qrcode [elementType]='"img"' [margin]="0" [qrdata]="QrCode" [width]="130" [errorCorrectionLevel]="'M'"
            class="bdcase-qrocde"></qrcode>
          <div class="inner_media">
            <h4 class="title_media">
              SP{{data.record.qrData.year}} <span>{{data.record.qrData.caseId}}</span>
            </h4>

            <h4 class="subtitle_media">
              {{data.record.qrData.cassetContainerId}}{{data.record.qrData.cassetteSerial}}
              <span>{{data.record.qrData.mrn}}</span>
            </h4>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data && data.typeQrcode == 'SLIDE'">
        <div class="slide_print_qrcode">
          <div>
            <h4 class="subtitle_media">
              {{data.record.qrData.location}}-SP-{{data.record.qrData.year}}-{{data.record.qrData.caseId}}
            </h4>
            <h4 class="title_media">
              MRN: {{data.record.qrData.mrn}}
            </h4>
          </div>
          <div>
            <div class="inner_media">
              <qrcode [elementType]='"img"' [margin]="0" [qrdata]="QrCode" [width]="130" [errorCorrectionLevel]="'M'"
                class="bdcase-qrocde"></qrcode>
              <div class="content_media">
                <p>
                  {{data.record.qrData.slideContainerId}}-{{data.record.qrData.slideCasseteNumber}}-{{data.record.qrData.slideSerial}}
                </p>
                <p>{{data.record.qrData.tissue}}</p>
              </div>
            </div>
            <div class="footer_media">
              <p>
                {{today | date:'d/MM/y h:mm:ss'}}
              </p>
            </div>
          </div>
        </div>
        <!-- <qrcode [elementType]='"img"' [margin]="2" [qrdata]="QrCode" [width]="230" [errorCorrectionLevel]="'M'"
          class="bdcase-qrocde text-center"></qrcode>
        <p class="text-center label-form">
          Referrence Number: <strong>{{data.record.generatedSlideReference}}</strong>
        </p> -->
      </ng-container>

    </div>
    <div class="d-flex align-items-center justify-content-center">
      <button mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="print('qrCodeEleme')">
        Print QR Code
      </button>
    </div>
  </div>
</mat-dialog-content>