import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigitizationRequestService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  openEditRequestDialog = new BehaviorSubject<any>(null);
  returnUrl = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) {
  }

  saveRecord(data) {
    if (data.digitizationRequestId) {
      return this.http.put(`${environment.apiUrl}/DigitizationRequest/UpdateDigitizationRequest`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/DigitizationRequest/CreateDigitizationRequest`, data);
    }
  }
  deleteRequest(id): any {
    return this.http.delete(`${environment.apiUrl}/DigitizationRequest/DeleteRequest?drequestId=${id}`);
  }

  getAll(params) {
    return this.http.get(`${environment.apiUrl}/DigitizationRequest/ListDigitaizationRequests`, { params });
  }

  getListEntitiesBasedOnSLA(params) {
    return this.http.get(`${environment.apiUrl}/DigitizationRequest/ListEntitiesBasedOnSLA`, { params });
  }

  declineRequest(data): any {
    return this.http.post(`${environment.apiUrl}/Case/CreateCase`, data);
  }

  AssignRepresentativeForGrossing(data): any {
    return this.http.post(`${environment.apiUrl}/DigitizationRequest/AssignRepresentativeForGrossing`, data);
  }
  AssignRepresentative(data): any {
    return this.http.post(`${environment.apiUrl}/DigitizationRequest/AssignRepresentative`, data);
  }
  ListRepresentatives(params): any {
    return this.http.get(`${environment.apiUrl}/DigitizationRequest/ListRepresentatives`, { params });
  }
  getRequestDetails(params): any {
    return this.http.get(`${environment.apiUrl}/DigitizationRequest/GetDRequestDetails`, { params });
  }
  getListRepresentativeRequests(filterModel: any, forGrossing): any {
    if (forGrossing) {
      return this.http.get(`${environment.apiUrl}/DigitizationRequest/ListRepresentativeRequestsForGrossing`, { params: filterModel });
    } else {
      return this.http.get(`${environment.apiUrl}/DigitizationRequest/ListRepresentativeRequests`, { params: filterModel });
    }
  }
}
