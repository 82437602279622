<div class="sidenav-user-toolbar justify-content-start">
  <button class="btn-back" mat-icon-button [matTooltip]="'back'" (click)="closeSidenav()">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.583" height="15.175" viewBox="0 0 15.583 15.175">
      <path id="Path_18837" data-name="Path 18837"
        d="M19,11H7.83l4.88-4.88a1.008,1.008,0,0,0,0-1.42,1,1,0,0,0-1.41,0L4.71,11.29a1,1,0,0,0,0,1.41l6.59,6.59a1,1,0,0,0,1.41-1.41L7.83,13H19a1,1,0,0,0,0-2Z"
        transform="translate(-4.417 -4.407)" fill="#555" />
    </svg>
  </button>
  <h4>
    Surgery Information
  </h4>
</div>
<div class="sidenav-user-container">
  <div class="profile_detail mt-3">
    <div class="page">
      <ng-container *ngIf='details; else dataEmpty'>
        <p [innerText]="details"></p>
      </ng-container>
      <ng-template #dataEmpty>
        <app-empty-state [img]='"./assets/images/viewer-icons/no-remarks-illustration.svg"' [title]='"No Data Found"'>
        </app-empty-state>
      </ng-template>
    </div>
  </div>
</div>