import { UsersService } from 'src/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SwalService } from 'src/app/services/swal.service';

export interface DialogData { }

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  formGroup: FormGroup;
  loading = false;
  msgObj = { msg: '', type: '' };
  hideOldPassword = true;
  hidePassword = true;
  hideConfPassword = true;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private service: UsersService,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading = true;
      const dataPass = {
        oldPassword: this.formGroup.controls.OldPassword.value,
        newPassword: this.formGroup.controls.password.value
      };
      this.service.changePassword(dataPass).subscribe(
        data => {
          this.loading = false;
          this.swalService.swalSuccess(this.translate.instant('auth.Password-updated-successfully'))
            .then((result) => {
              this.dialogRef.close(true);
              this.authenticationService.logout();
            });
        },
        error => {
          this.loading = false;
          this.handleErrors(error.detail);
        });
    }
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      OldPassword: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#&._-]).{8,}$')
      ]],
      cPassword: ['', Validators.required]
    }, { validator: this.validateMatch });
  }

  validateMatch(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('cPassword').value;
    if (pass !== confirmPass) {
      group.get('cPassword').setErrors({ notMatch: true });
    } else {
      group.get('cPassword').setErrors(null);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'NotFound') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ validOldPass: true });
      }
    });
  }
}
