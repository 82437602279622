import { Observable, timer } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'TimeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {

  public transform(futureDate: any, type?: string): Observable<string> {
    if (!futureDate) {
      return null;
    }

    const source = timer(0, 1000);
    return source.pipe(map((_) => this.msToTime(type === 'seconds' ? futureDate = futureDate - 1 : futureDate, type)));
  }

  private msToTime(futureDate, type): string | null {
    let expired = false;
    let msRemaining = 0;
    if (type && type === 'seconds') {
      msRemaining = Math.abs(futureDate) * 1000;
      if (futureDate < 0) {
        expired = true;
      }
    } else {
      const now = new Date().getTime();
      const endDate = new Date(futureDate).getTime();
      msRemaining = endDate - now;
      if (msRemaining < 0) {
        msRemaining = now - endDate;
        expired = true;
      }
    }
    const days = Math.floor(msRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((msRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((msRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((msRemaining % (1000 * 60)) / (1000));

    return `<p class="${expired ? 'text-danger' : ''}">
    ${expired ? '-' : ''}
    ${days !== 0 ? days + ' d, ' : ''}
    ${hours !== 0 ? hours + ' h, ' : ''}
    ${minutes !== 0 ? minutes + ' m, ' : ''}
    left
    </p>`;
  }

}
