import { statusList } from 'src/app/models/users/users/statusList';

export class ListData {
  usersCount: number;
  pagedData: {
    labUserId?: number;
    fullName?: string;
    userName?: string;
    email?: string;
    labName?: string;
    memberSince?: Date;
    userStatus?: statusList;
    userLogo?: string;
    labLogo?: string;
    isPathologist?: boolean;
  }[];
}
