import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  refreshListAndHeader = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getInvoicesList(data) {
    return this.http.get(`${environment.apiUrl}/Payment/ListInvoices`, { params: data });
  }

  GetInvoiceListHeaderDetails(data) {
    return this.http.get(`${environment.apiUrl}/Payment/GetInvoiceListHeaderDetails`, { params: data });
  }

  getInvoiceDetails(id: number) {
    return this.http.get(`${environment.apiUrl}/Payment/GetInvoiceDetails/` + id);
  }

  getEntityWalletTransactions(params) {
    return this.http.get(`${environment.apiUrl}/Payment/GetEntityWalletTransactions`, { params });
  }

  markInvoice(data) {
    return this.http.post(`${environment.apiUrl}/Payment/MarkInvoice`, data);
  }

  getEntityWalletDetails(params) {
    return this.http.get(`${environment.apiUrl}/Payment/GetEntityWalletDetails`, { params });
  }

  getCasePaymentDetails(params) {
    return this.http.get(`${environment.apiUrl}/Payment/GetCasePaymentDetails`, { params });
  }

  checkout(data) {
    return this.http.post(`${environment.apiUrl}/Payment/Checkout`, data);
  }

  getCaseDetails(caseId) {
    return this.http.get(`${environment.apiUrl}/Case/GetInvoiceCaseDetails/` + caseId);
  }

  ListEntitiesBasedOnSLAForPayments(data) {
    return this.http.get(`${environment.apiUrl}/Lookups/ListEntitiesBasedOnSLAForPayments`, { params: data });
  }
}
