export enum EntityEnumSLA {
  hospital = 'HOSPITAL',
  clinic = 'CLINIC',
  lab = 'LAB',
  vl = 'VIRTUAL_LAB',
  labs = 'LABS',
  otherlabs = 'OTHER_LABS',
  hospitalsclinics = 'HOSPITAL_CLINIC',
  scanningCenter = 'SCANNING_CENTER',
  scanningCenters = 'SCANNING_CENTERs',
  pathologists = 'PATHOLOGISTS',
  pathologist = 'PATHOLOGIST',
  otherentity = 'OTHER',
  entitywithentity = 'WITHENTITY'
}
