export enum statusList {
  IDLE = 'IDLE',
  UPLOAD_SLIDES = 'UPLOAD_SLIDES',
  Closed = 'CLOSED',
  Reopened = 'REOPENED',
  MatchMaking = 'MATCH_MAKING',
  MATCHMAKING_RESET = 'MATCHMAKING_RESET',
  SECONDOPINION = 'SECOND_OPINION',
  CaseSubmitted = 'CASE_SUBMITTED',
  CaseSubmittedAfterSentBackCaseDetails = 'CASE_SUBMITTED_AFTER_SENT_BACK_CASE_DETAILS',
  CaseSubmittedAfterSentBackUploadSlides = 'CASE_SUBMITTED_AFTER_SENT_BACK_UPLOADSLIDES',
  LabSupervisorApproved = 'LAB_SUPERVISOR_APPROVED',
  LabSupervisorRejected = 'LabSupervisorRejected',
  CaseWithCancelledRequest = 'CASE_WITH_CANCELLED_REQUEST',
  WAITING_TECHNICIAN_RESPONSE = 'WAITING_TECHNICIAN_RESPONSE',
  CaseHasRejectedRequest = 'CASE_HAS_REJECTED_REQUEST',
  PathologiestAssigned = 'PATHOLOGIEST_ASSIGNED',
  CaseWithRequest = 'CASE_WITH_REQUEST',
  CaseHasApprovedRequest = 'CASE_HAS_APPROVED_REQUEST',
  CaseCancelled = 'CASE_CANCELLED',
  LabSuervisorReviewed = 'LAB_SUERVISOR_REVIEWED',
  SupervisorRejectUploadedSlides = 'SUPERVISOR_REJECT_UPLOADED_SLIDES',
  SupervisorRejectCaseDetails = 'SUPERVISOR_REJECT_CASE_DETAILS',
  SupervisorRejectUploadedSlidesCase = 'SUPERVISOR_REJECT_UPLOADED_SLIDES_CASE',
  SupervisorRejectCaseDetailsCase = 'SUPERVISOR_REJECT_CASE_DETAILS_CASE',
  WaitingVlSupervisorProcess = 'WAITING_VL_SUPERVISOR_PROCESS',
  TechnicianRefusedSupervisorComments = 'TECHNICIAN_REFUSED_SUPERVISOR_COMMENTS',
  CaseReuploadedSlides = 'CASE_REUPLOADED_SLIDES',
  CaseSubmittedDetails = 'CASE_SUBMITTED_DETAILS',
  CASE_REVIEWED_SUCCESFULLY = 'CASE_REVIEWED_SUCCESFULLY',
  CASE_REASSESSMENT = 'CASE_REASSESSMENT',
  CLOSED_LAB_DELETED = 'CLOSED_LAB_DELETED',
  SCANNING_CENTER_UPLOADED_SLIDES = 'SCANNING_CENTER_UPLOADED_SLIDES',
  VIRTUAL_LAB_UPLOADED_SLIDES = 'VIRTUAL_LAB_UPLOADED_SLIDES',
  ACCEPTED = 'ACCEPTED',
  READY_TO_UPLOAD_SLIDES = 'READY_TO_UPLOAD_SLIDES',
  SUPER_SENT_BACK = 'SUPER_SENT_BACK',
  TECH_REF_SUPER_SENT_BACK = 'TECH_REF_SUPER_SENT_BACK',
  TECH_APP_SUPER_SENT_BACK = 'TECH_APP_SUPER_SENT_BACK',
  SUPER_SENT_BACK_FROM_OTHER = 'SUPER_SENT_BACK_FROM_OTHER',
  TECH_REF_SUPER_SENT_BACK_FROM_OTHER = 'TECH_REF_SUPER_SENT_BACK_FROM_OTHER',
  READY_TO_UPLOAD_SLIDES_REF = 'READY_TO_UPLOAD_SLIDES_REF',
  TECH_APP_SUPER_SENT_BACK_FROM_OTHER = 'TECH_APP_SUPER_SENT_BACK_FROM_OTHER',
  ACTION_TAKEN = 'ACTION_TAKEN',
  SUBMIT_SENT_BACK = 'SUBMIT_SENT_BACK',
  CASE_SENT_BACK = 'CASE_SENT_BACK',
  NO_ACTION = 'NO_ACTION',
  CASE_SENT_BACK_FROM_OTHER = 'CASE_SENT_BACK_FROM_OTHER',
  CASE_SENT_BACK_UNDER_PROCESS = 'CASE_SENT_BACK_UNDER_PROCESS',
  CASE_SENT_BACK_DONE = 'CASE_SENT_BACK_DONE',
  CASE_SENT_BACK_READY = 'CASE_SENT_BACK_READY',
  ACCEPTED_PROCESS_ONLY = 'ACCEPTED_PROCESS_ONLY',
  ACTION_TAKEN_TECH = 'ACTION_TAKEN_TECH',
  GROSSING = 'GROSSING',
  GROSSING_RESET = 'GROSSING_RESET',
  ASSINGED_TECHNOLOGIST = 'ASSINGED_TECHNOLOGIST',
  GROSSING_COMPLETED = 'GROSSING_COMPLETED',
  READY_TO_GROSSING = 'READY_TO_GROSSING',
  SUBMIT_SENT_BACK_GROSSING = 'SUBMIT_SENT_BACK_GROSSING',
  CASE_SENT_BACK_GROSSING = 'CASE_SENT_BACK_GROSSING',
  CASE_SENT_BACK_FROM_OTHER_GROSSING = 'CASE_SENT_BACK_FROM_OTHER_GROSSING',
  CASE_SENT_BACK_UNDER_PROCESS_GROSSING = 'CASE_SENT_BACK_UNDER_PROCESS_GROSSING',
  CASE_SENT_BACK_READY_GROSSING = 'CASE_SENT_BACK_READY_GROSSING',
  CASE_SENT_BACK_DONE_GROSSING = 'CASE_SENT_BACK_DONE_GROSSING',
  REQUEST_VERFICATION = 'REQUEST_VERFICATION',
  PENDING = 'PENDING',
  VERIFICATION_ACCEPTED = 'VERIFICATION_ACCEPTED',
  VERIFICATION_DONE = 'VERIFICATION_DONE',
  VERIFICATION_SUBMITED = 'VERIFICATION_SUBMITED',
  VERIFICATION_WITH_DRAW = 'VERIFICATION_WITH_DRAW',
  VERIFICATION_REJECTED = 'VERIFICATION_REJECTED'
}
