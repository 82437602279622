import { SubmitReasonComponent } from 'src/app/partial/submit-reason/submit-reason.component';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { atLeastOneCheckboxCheckedValidator } from 'src/app/helpers/atLeastOneCheckboxCheckedValidator';
import { ActionType } from 'src/app/models/slides/enums/ActionType';
import { ReadSlide } from 'src/app/models/slides/enums/ReadSlide';
import { UpdateSlideReference } from 'src/app/models/slides/UpdateSlideReference';
import { DigitizationRequestService } from 'src/app/services/digitizationRequest.service';
import { SlidesService } from 'src/app/services/slides.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ContainerReferrences } from 'src/app/models/requests/Details';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface Case {
  caseId: number;
  caseNumber: string;
  patientName: string;
  slidesCount: number;
  caseRequestId: number;
  slideReferrences: string[];
  containerReferrences: ContainerReferrences[];
}

export interface DialogData {
  readSlideEnum: ReadSlide;
  caseCode: string;
  requestCode: number;
  multible: boolean;
  extrnalScan: boolean;
  cases: Case[];
  actionType: ActionType;
  forGrossing: boolean;
}

@Component({
  selector: 'app-receive-slide-manually',
  templateUrl: './receive-slide-manually.component.html'
})
export class ReceiveSlideManuallyComponent implements OnInit {
  formGroup = new FormGroup({});
  isSubmitted = false;
  loading = false;
  requestCode: number;
  caseCode: string;
  casesList: Case[] = [];
  activeCaseIndex = 0;
  title = 'slide';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ReceiveSlideManuallyComponent>,
    public dialog: MatDialog,
    private service: SlidesService,
    private dService: DigitizationRequestService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.data.forGrossing && this.data.actionType === ActionType.CONTAINER_PICKEDUP) {
      this.title = 'container';
    } else if (this.data.forGrossing) {
      this.title = 'cassette';
    }
    this.requestCode = this.data.requestCode;
    this.caseCode = this.data.caseCode;
    this.casesList = this.data.cases;
    this.buildForm();
  }

  buildForm() {
    this.casesList.map(item => {
      this.formGroup.addControl(String(item.caseId), this.buildFormArr(this.data.forGrossing ? item.containerReferrences : item.slideReferrences));
    });
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      const refNumber = [];
      this.casesList.map((item, index) => {
        refNumber.push(...this.slidesFormArraySelectedIds(index, String(item.caseId)));
      });
      const data = {
        token: null,
        actionType: this.data.actionType,
        refNumber,
        dRequestId: this.data.requestCode
      } as UpdateSlideReference;
      if (this.checkTotalSlides()) {
        data.comment = null;
        this.loading = true;
        let serviceTarget = this.service.updateSlideReference(data);
        if (this.data.forGrossing) {
          serviceTarget = this.service.updateContainerReference(data);
        }
        serviceTarget.subscribe((res) => {
          this.loading = false;
          this.receiveConfirm();
        }, err => {
          this.loading = false;
        });
      } else {
        this.receiveReason(data);
      }
    }
  }

  receiveReason(recordData: UpdateSlideReference) {
    this.loading = false;
    let helperLabel = 'Please explain why you received fewer slide than the total number you should receive.';
    if (this.data.forGrossing && this.data.actionType === ActionType.CONTAINER_PICKEDUP) {
      helperLabel = 'Please explain why you received fewer containers or specimens than the total number you should receive.';
    } else if (this.data.forGrossing) {
      helperLabel = 'Please explain why you received fewer cassette than the total number you should receive.';
    }
    let serviceTarget = 'updateSlideReference';
    if (this.data.forGrossing) {
      serviceTarget = 'updateContainerReference';
    }
    const dataRecord = {
      title: 'Reasons',
      titleField: 'Reasons / Notes',
      comment: null,
      record: {
        token: recordData.token,
        actionType: recordData.actionType,
        refNumber: recordData.refNumber,
        dRequestId: recordData.dRequestId
      },
      serviceTarget,
      service: this.service,
      showClose: false,
      showBack: true,
      btnSubmitTitle: 'Submit Reasons',
      hideSwalMessageSuccess: true,
      helpersTop: [{
        label: helperLabel,
        class: "label-form"
      }]
    };


    const dialogRef = this.dialog.open(SubmitReasonComponent, {
      width: '550px',
      data: dataRecord
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result.back) {
        this.receiveConfirm();
      }
    });
  }

  receiveConfirm() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '650px',
      data: {
        title: `Receiving ${this.title}s confirmed`,
        // message:
        //   'Waiting you to deliver the slides to your scanning center unless you\'re a mobile center',
        img: 'assets/images/done-illustration.png',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dService.refreshDetails.next(true);
      this.dialogRef.close(true);
    });
  }

  checkTotalSlides(): boolean {
    let Valid = true;
    this.casesList.map((record, index) => {
      if (this.slidesFormArraySelectedIds(index, String(record.caseId))) {
        if (
          this.data.forGrossing &&
          (
            record.containerReferrences.length !== this.slidesFormArraySelectedIds(index, String(record.caseId)).length ||
            (
              record.containerReferrences.length !== this.checkNumberSpecimens(record, String(record.caseId)).length &&
              this.data.actionType === ActionType.CONTAINER_PICKEDUP
            )
          )
        ) {
          Valid = false;
        } else if (!this.data.forGrossing && record.slideReferrences.length !== this.slidesFormArraySelectedIds(index, String(record.caseId)).length) {
          Valid = false;
        }
      } else {
        Valid = false;
      }
    });
    return Valid;
  }

  hasError(controlName: string, errorName: string, index = null, id = null) {
    if (index !== null) {
      return this.formGroup.controls[String(id)]['controls'][index]['controls'][String(controlName)].hasError(errorName);
    } else {
      return this.formGroup.controls[String(controlName)].hasError(errorName);
    }
  }

  getControl(controlName: string, index = null, id = null) {
    return this.formGroup.controls[String(id)]['controls'][index]['controls'][String(controlName)];
  }

  toggleCaseset(index) {
    if (this.activeCaseIndex === index) {
      this.activeCaseIndex = undefined;
    } else {
      this.activeCaseIndex = index;
    }
  }

  slidesFormArraySelectedIds(index: number, key: string): any[] {
    let arr = this.formGroup.value[key].filter(record => record.check).map(item => item = { key: item.refNumber, value: Number(item.specimenNumber) });
    if (this.data.forGrossing && this.data.actionType !== ActionType.CONTAINER_PICKEDUP) {
      console.log(this.data.actionType)
      arr = this.formGroup.value[key].filter(record => record.check).map(item => item = { key: item.refNumber.cassetteRef });
    }
    if (!this.data.forGrossing) {
      arr = this.formGroup.value[key].filter(record => record.check).map(item => item.refNumber);
    }

    // arr = this.casesList[index].slideReferrences
    // .filter((slide, catIdx) => this.formGroup.value[key].some((control, controlIdx) => catIdx === controlIdx && control))
    // .map(slide => slide);

    return arr;
  }

  checkNumberSpecimens(record: Case, key: string) {
    return record.containerReferrences
      .filter((slide, catIdx) => this.formGroup.value[key].some((control, controlIdx) => catIdx === controlIdx && control.check && slide.specimensNumber === Number(control.specimenNumber)))
      .map(slide => slide);
  }

  buildFormArr(records: any[], selectedIds: string[] = []): FormArray {
    const controlArr = records.map(item => {
      const isSelected = selectedIds.some(id => id === item);
      let refNumberValue = item;
      if (this.data.forGrossing && this.data.actionType === ActionType.CONTAINER_PICKEDUP) {
        refNumberValue = item.containerRef;
      } else if (this.data.forGrossing && this.data.actionType === ActionType.CONTAINER_RECIEVED) {
        refNumberValue = item.cassetteRef;
      }
      return new FormGroup({
        specimenNumber: new FormControl(null, this.data.forGrossing ? [Validators.max(item.specimensNumber), Validators.min(1)] : []),
        check: new FormControl(isSelected),
        refNumber: new FormControl(refNumberValue)
      });
    });
    return this.formBuilder.array(controlArr, CustomValidator.multipleCheckboxRequireOne);
  }

  changeEvent(e: MatCheckboxChange, index, id, specimensNumber) {
    if (!e.checked) {
      this.formGroup.controls[String(id)]['controls'][index]['controls']['specimenNumber'].setValue(null);
    } else {
      this.formGroup.controls[String(id)]['controls'][index]['controls']['specimenNumber'].setValue(specimensNumber);
    }
  }
}
