import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-surgery-information',
  templateUrl: './surgery-information.component.html'
})
export class SurgeryInformationComponent implements OnInit {
  @Input() details: string;
  @Output() closeDetails = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }

  closeSidenav() {
    this.closeDetails.emit();
  }
}
