import { AuthenticationService } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    const language = localStorage.getItem('language') || 'en';

    const requestHeader = {
      'accept-language': language,
      lang: language
    } as any;

    if (currentUser && currentUser.Token) {
      requestHeader.Authorization = `Bearer ${currentUser.Token}`;
      requestHeader.refreshToken = currentUser.RefreshToken;
    }

    request = request.clone({
      setHeaders: requestHeader,
      setParams: this.setDateParams(request.params)
    });

    this.dateReplacer(request.body);
    return next.handle(request);
  }

  makeDate(date: Date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return year + '/' + month + '/' + day;
  }

  setDateParams(params: HttpParams) {
    const paramsValue = {};
    params.keys().forEach(p => {
      const value = params.get(p) as any;
      if (value instanceof Date) {
        paramsValue[p] = this.makeDate(value);
      } else if (value !== undefined) {
        paramsValue[p] = value;
      }
    });
    return paramsValue;
  }

  dateReplacer(obj: any) {
    if (obj) {
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (obj[k] instanceof Object) {
            obj[k] = this.dateReplacer(obj[k]);
          }
          if (obj[k] instanceof Date) {
            obj[k] = new Date(`${obj[k]} UTC`);
          }
        }
      }
      return obj;
    }
  }
}
