<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf='!dataLoading && !successMsg'>
  <div class="form-group alert alert-danger" *ngIf="errorMsg">{{errorMsg}}</div>
  <!-- -->
  <div *ngIf="meetingList && meetingList.length else dataEmpty" class="table-responsive table_assign"
   infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount"
   (scrolled)="getNextPage()">

    <mat-radio-group [(ngModel)]="selectedMeeting">
      <table class="table table-primary meeting-list table_assign px-0">
        <thead>
          <tr>
            <th width="50"></th>
            <th>Meeting Title / ID</th>
            <th>Group</th>
            <th>Meeting Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let record of meetingList; let i = index">
            <tr (click)="changeSelected(record.meetingId)" [ngClass]="record.meetingId === selectedMeeting ? 'active':''">
              <td>
                <mat-radio-button [value]="record.meetingId">
                </mat-radio-button>
              </td>
              <td class="meeting">
                <p>{{record.MeetingTitle}}</p>
                <span>{{record.MeetingReferenceNumber}}</span>
              </td>
              <td>
                <span class="badge-status primary">
                  {{record.GroupName}}
                </span>
              </td>
              <td>
                <div>{{record.StartDate | date}}</div>
                <div>{{record.StartDate | date : 'shortTime'}}</div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </mat-radio-group>
  </div>

  <ng-template #dataEmpty>
    <app-empty-state [title]='"No Data Found"'></app-empty-state>
  </ng-template>
</ng-container>
