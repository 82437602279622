<div mat-dialog-title>
  <h2>
    Add from Other Case's Slides
    <span>(slides that shared only with other pathologists)</span>
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="assign-dialog-content">
  <div class="page">

    <div *ngIf='dataLoading' class="dataLoading">
      <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <ng-container *ngIf='!dataLoading'>
      <div *ngIf="recordsData; else dataEmpty">
        <div class="table-responsive" *ngIf="recordsData.length; else dataEmpty">

          <table class="table table-primary table_assign">
            <thead>
              <tr>
                <th width="1%"></th>
                <th>Slide No.</th>
                <th>Stain type</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let record of recordsData; let i = index">
                <tr (click)="assignPath(record.slideId)" [ngClass]="isInArray(record.slideId) ? 'active':''">
                  <td>
                    <mat-checkbox [checked]="isInArray(record.slideId)">
                    </mat-checkbox>
                  </td>
                  <td>
                    #{{record.slideId}}
                  </td>
                  <td>{{record.stainType.name}}</td>
                </tr>
              </ng-container>

            </tbody>

          </table>
        </div>

      </div>
    </ng-container>

    <ng-template #dataEmpty>
      <app-empty-state [title]='"No Data Found"'></app-empty-state>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="assign-dialog-actions justify-content-end">
  <div>
    <button mat-raised-button color="grey" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="loading || this.slidesIds.length === 0"
      [class.loading]='loading' (click)="saveRecord()">
      Add Slides
    </button>
  </div>
</mat-dialog-actions>
