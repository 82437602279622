import { FinalReport } from '../global/enums/FinalReport';
import { Lookup } from '../lookups/Lookup';
import { statusList } from '../sla/enums/statusList';
import { CommunicationMethod } from '../global/CommunicationMethod';

export class ClinicDetails {
  clinicName: string;
  createdOn: string;
  logo: string;
  clinicUserDetails: string;
  basicInfo: {
    address: string;
    contactNumberFlag: string;
    contactNumberExtension: string;
    contactNumber: string;
    email: string;
    managerName: string;
    managerFirstName: string;
    managerLastName: string;
    isEmailVerified: boolean;
    isMobileVerified: boolean;
  };
  canDeleteClinic: boolean;
  ownScanners: boolean;
  ownLabs: boolean;
  finalReport: FinalReport;
  country: Lookup;
  city: Lookup;
  region: Lookup;
  communicationMethod: CommunicationMethod[];
  clinicLabs: string;
  clinicLabsCount: number;
  clinicSLA: {
    slaId: number;
    slaStatus: statusList;
    creationDate: Date;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  lastSLA: {
    id: number;
    status: statusList;
    rejectionReason: string;
    updatedDate: Date;
    rejectionDate: Date;
  };
  id?: number;
  casesCount: number;
  closedCasesCount: number;
  openCasesCount: number;
}
