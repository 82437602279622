import { FormControl, FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, Inject, Input, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { VoiceRecognitionService } from 'src/app/services/speech-recognizer.service';
import { SpeechNotification } from 'src/app/models/speech/speech-notification';
import { SpeechEvent } from 'src/app/models/speech/speech-event';

@Component({
  selector: 'wsa-web-speech',
  templateUrl: './web-speech.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebSpeechComponent implements OnInit {
  private voiceRecognition: VoiceRecognitionService;
  public word: string = '';

  @Input() formGroup: FormGroup;
  @Input() fieldControlName: string;
  @Input() randomNumber: number;
  isUserSpeaking = false;
  private subscriptions: Subscription[] = [];

  constructor(voiceRecognition: VoiceRecognitionService, private elementRef: ElementRef) {
    this.voiceRecognition = voiceRecognition;
  }

  ngOnInit(): void {
    this.initVoiceInput();
  }

  stopRecording() {
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;
  }

  initVoiceInput() {
    // Subscription for initializing and this will call when user stopped speaking.
    this.voiceRecognition.init().subscribe((e) => {
      // User has stopped recording
      // Do whatever when mic finished listening
      if (e) {
        this.isUserSpeaking = false;
      }
    });

    // Subscription to detect user input from voice to text.
    this.voiceRecognition.speechInput().subscribe((input) => {
      // Set voice text output to
      if (this.isUserSpeaking) {
        this.formGroup.controls[this.fieldControlName].setValue(input);
      }
    });
  }

  private processNotification(notification: SpeechNotification<string>): void {
    if (notification.event === SpeechEvent.FinalContent) {
      const message = notification.content?.trim() || '';
      const totalTranscript = this.formGroup.controls[this.fieldControlName].value
        ? `${this.formGroup.controls[this.fieldControlName].value} ${message}`
        : notification.content;
      this.formGroup.controls[this.fieldControlName].setValue(totalTranscript);
    }
  }

  // private processNotification(notification: SpeechNotification<string>): void {
  //   if (notification.event === SpeechEvent.FinalContent) {
  //     const message = notification.content?.trim() || '';
  //     const totalTranscript = this.formGroup.controls[this.fieldControlName].value
  //       ? `${this.formGroup.controls[this.fieldControlName].value} ${message}`
  //       : notification.content;
  //     this.formGroup.controls[this.fieldControlName].setValue(totalTranscript);
  //   }
  // }

  // @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
  //   if (!this.elementRef.nativeElement.contains(event.target) && this.isUserSpeaking) {
  //     console.log(this.elementRef.nativeElement.contains(event.target))
  //     this.subscriptions.forEach(sub => sub.unsubscribe());
  //     this.isUserSpeaking = false;
  //     this.voiceRecognition.stop();
  //   }
  // }

  startRecording() {
    this.isUserSpeaking = true;
    // console.log(this.voiceRecognition.recognition)
    if (this.voiceRecognition.text) {
    }
    this.voiceRecognition.start();
  }
}
