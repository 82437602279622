<div mat-dialog-title>
  <h2>
    {{data.title}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">
      <ng-container *ngIf="data && data.helpersTop && data.helpersTop.length">
        <p [class]="item.class" *ngFor="let item of data.helpersTop" [innerHTML]="item.label">
        </p>
      </ng-container>

      <div class="fields-group" *ngIf="fields.length">
        <ng-container *ngFor="let field of fields; index as i">
          <ng-container *ngIf="field.inputTypeId === 7">
            <h4 class="label-form" *ngIf="field.inputTitle">{{field.inputTitle}}</h4>
            <div class="form-group">
              <div class="radio-selector">
                <mat-radio-group [formControlName]="field.id" class="row">
                  <mat-radio-button *ngFor="let item of field.values" class="col-6"
                    [ngClass]="{'col-12':field.values.length == 1}" [value]="item.id">
                    {{item.title}}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="hasError('required',field.id) && isSubmitted">
                  {{ 'forms.Required' | translate}}
                </mat-error>
                <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data && data.helpersMiddle && data.helpersMiddle.length && i == 0">
            <p [class]="item.class" *ngFor="let item of data.helpersMiddle" [innerHTML]="item.label">
            </p>
          </ng-container>

          <mat-form-field class="form-group" *ngIf="field.inputTypeId == 3">
            <mat-label>{{field.inputTitle}}</mat-label>
            <input matInput [formControlName]="field.id" appOnlynumber [maxlength]="field.fieldLength">
            <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('pattern',field.id)">{{ 'forms.Invalid number' | translate }}</mat-error>
            <mat-error *ngIf="hasError('min',field.id)">
              Minimum is {{field.jsonProps.minimumRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('max',field.id)">
              Maximum is {{field.jsonProps.maxRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('maxLength',field.id)">
              Max Length is {{field.fieldLength}} Number
            </mat-error>
            <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
          </mat-form-field>

          <mat-form-field class="form-group mb-0" *ngIf="field.inputTypeId == 5">
            <mat-label>{{field.inputTitle}}</mat-label>
            <input matInput [formControlName]="field.id" [maxlength]="field.fieldLength">
            <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('pattern',field.id)">{{ 'forms.Invalid number' | translate }}</mat-error>
            <mat-error *ngIf="hasError('min',field.id)">
              Minimum is {{field.jsonProps.minimumRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('max',field.id)">
              Maximum is {{field.jsonProps.maxRange}} Characters
            </mat-error>
            <mat-error *ngIf="hasError('maxLength',field.id)">
              Max Length is {{field.fieldLength}} Number
            </mat-error>
            <mat-error *ngIf="hasError('notUniqe',field.id)">
              {{field.id}} Already Exist
            </mat-error>
            <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
          </mat-form-field>

          <mat-form-field class="form-group" *ngIf="field.inputTypeId == 6">
            <mat-label>{{field.inputTitle}}</mat-label>
            <textarea matInput [formControlName]="field.id" [maxlength]="field.fieldLength"></textarea>
            <mat-error *ngIf="hasError('required',field.id)">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('maxLength',field.id)">
              Max Length is {{field.fieldLength}} Characters
            </mat-error>
            <mat-hint *ngIf="field.validationMessage">{{field.validationMessage}}</mat-hint>
          </mat-form-field>
        </ng-container>
      </div>

      <ng-container *ngIf="data && data.helpersbottom && data.helpersbottom.length">
        <p [class]="item.class" *ngFor="let item of data.helpersbottom" [innerHTML]="item.label"></p>
      </ng-container>

      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        {{data.btnSubmitTitle}}
      </button>
      <button mat-raised-button mat-dialog-close color="grey" [disabled]="loading" class="w-100 mt-3" type="button"
        *ngIf="data.showClose">
        Cancel
      </button>
      <button mat-raised-button color="grey" [disabled]="loading" class="w-100 mt-3" type="button"
        (click)="backDialog()" *ngIf="data.showBack">
        Go Back
      </button>
    </form>
  </div>
</mat-dialog-content>