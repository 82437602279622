import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

export interface CaseLogData {
  name: string;
  message: string;
  status: string;
  procedure: string;
  comment: string;
  image: string;
  creationDate: Date;
}

@Component({
  selector: 'app-case-log-details',
  templateUrl: './case-log-details.component.html'
})
export class CaseLogDetailsComponent implements OnInit {
  @Input() record: CaseLogData;
  @Input() hideScroll: boolean;
  dataLoading = false;
  @Output() closeDetails = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  closeSidenavCaseLog() {
    this.closeDetails.emit();
    if (this.hideScroll) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
      this.renderer.addClass(document.body, 'overflow-hidden');
    }
  }
}
