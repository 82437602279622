import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';

export class CustomValidator {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    let crtValue = control.value;
    if (crtValue) { crtValue = crtValue.trim(); }
    if (!crtValue) { return { required: true }; }
    return null;
  }

  static multipleCheckboxRequireOne(fa: FormArray) {
    let valid = false;

    for (let x = 0; x < fa.length; ++x) {
      if (fa.at(x).value.check) {
        valid = true;
        break;
      }
    }
    if (!valid) { return { required: true }; }
  }
}
