<div class="card_info_profile title-has-btns revert">
  <div class="card_info_profile_content">
    <div class="card_info_profile_title">
      <h4>
        Technologists follow-up
      </h4>
    </div>

    <div class="card_info_profile_body">

      <div class="page">

        <div class="" *ngIf="list?.length; else dataEmpty">

          <table class="table table_list_users">
            <thead>
              <tr>
                <th>Technologist Name</th>
                <th>Step Name</th>
                <th>Receiving Date</th>
                <th>Completion Date</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of list">
                <td>
                  <div class="blog_user_item">
                    <div class="img-circle">
                      <div class="image m-0">
                        <img *ngIf="record.image" [src]="record.image" (error)='record.image = ""'>
                        <span *ngIf="!record.image">{{ record.techonologistName | shortName }}</span>
                      </div>
                    </div>
                    <div class="name">
                      <h5>{{record.techonologistName}}</h5>
                    </div>
                  </div>
                </td>
                <td>{{record.action}}</td>
                <td>{{record.startDate | date:'d MMM, y hh:mm a'}}</td>
                <td>
                  {{record.endDate ? (record.endDate | date:'d MMM, y hh:mm a') : '_'}}
                </td>
                <td>{{record.duration}}</td>
              </tr>
            </tbody>

          </table>
        </div>

        <ng-template #dataEmpty>
          <app-empty-state [img]='"./assets/images/illustration-reading-list.svg"' [desc]='"No Related Technologists yet"'>
          </app-empty-state>
        </ng-template>
      </div>

    </div>
  </div>
</div>
