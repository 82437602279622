import { SubmitReasonComponent } from 'src/app/partial/submit-reason/submit-reason.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { UserDetails } from 'src/app/models/users/users/UserDetails';
import { UsersService } from 'src/app/services/users.service';
import { UserFormComponent } from '../../../features/users/user-management/user-form/user-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SwalService } from 'src/app/services/swal.service';
import { UserPerformanceStatisticsComponent } from '../user-performance-statistics/user-performance-statistics.component';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit {
  actionsLoader = false;
  @Input() title: string;
  @Input() userInfo: any;
  @Input() isModuleAdmin: boolean;
  @Input() pathologistLabType: string;
  user: UserDetails;
  dataLoading = false;
  @Input() module;
  @Input() entityId;
  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() hideActionsFromUsersNotSuerAdmin;
  // image file
  avatar: any;
  avatarSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;
  userType: any;
  entityType: string;
  @Input() moduleView: string

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private service: UsersService,
    private auth: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    if (this.module === ModelsEnum.Lab || this.module === ModelsEnum.LabUser) {
      this.userType = ModelsEnum.LabUser;
      this.entityType = EntityEnumSLA.lab;
    } else if (this.module === ModelsEnum.ScanningCenter || this.module === ModelsEnum.SCUser) {
      this.userType = ModelsEnum.SCUser;
      this.entityType = EntityEnumSLA.scanningCenter;
    } else if (this.module === ModelsEnum.Hospital || this.module === ModelsEnum.hospitalUser) {
      this.userType = ModelsEnum.hospitalUser;
      this.entityType = EntityEnumSLA.hospital;
    } else if (this.module === ModelsEnum.Clinic || this.module === ModelsEnum.clinicUser) {
      this.userType = ModelsEnum.clinicUser;
      this.entityType = EntityEnumSLA.clinic;
    } else if (this.module === ModelsEnum.VL || this.module === ModelsEnum.VLUser) {
      this.userType = ModelsEnum.VLUser;
      this.entityType = EntityEnumSLA.vl;
    } else if (this.module === ModelsEnum.pathologistUser) {
      this.entityType = this.pathologistLabType;
      this.userType = ModelsEnum.pathologistUser;
    } else if (this.module === ModelsEnum.pathologistRequest) {
      this.userType = ModelsEnum.pathologistRequest;
    }

    this.getDetails();
    // if (this.module === 'PATHOLOGIST_USER') {
    //   this.getPathologistDetails();
    // } else {
    // }
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.module, this.userInfo, this.pathologistLabType, this.entityId).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.user = data as UserDetails;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  getPathologistDetails() {
    this.dataLoading = true;

    this.service.getDetails(this.module, this.userInfo).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.user = data as UserDetails;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  resendVerficationMail() {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;

        }
      });
  }

  openEditUser() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '620px',
      data: {
        record: this.user,
        userId: this.userInfo.labUserId,
        model: this.module, // LAB, Sc, VL
        isModuleAdmin: this.isModuleAdmin,
        pathologistLabType: this.pathologistLabType,
        labId: this.entityId,
        // this.user.userStatus !== 'NOT_VERIFIED'
        disableInpusForUpdate: (!this.hasPermission('SuperAdmin') && this.user.userType === 'PATHOLOGIST_USER' && this.user.memberSince) ? true : false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
        this.service.refreshUsersList.next(true);
      }
    });
  }

  deleteRecord(isRemove = false) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.actionsLoader = true;
          const query = { UserId: this.userInfo.labUserId, UserType: this.userType } as any;
          if (isRemove && this.user.lab?.length) {
            query.LabID = this.user.lab[0].labId;
            query.LabType = this.user.lab[0].labType;
          }
          this.service.checkBeforeDeleteUser(query).subscribe(
            (data: any) => {
              if (data.roles.length) {
                this.swalService.swalConfirmation(
                  this.translate.instant('swalMsg.Yesdeleteit'),
                  'This User has reviewer role',
                  'Are you sure to delete?'
                ).then((result) => {
                  if (result.value) {
                    this.deleteUser(query);
                  } else {
                    this.actionsLoader = false;
                  }
                });
              } else {
                this.deleteUser(query);
              }

            },
            err => {
              this.actionsLoader = false;
            }
          );

        }
      });
  }

  deleteUser(query) {
    this.service.deleteUser(query).subscribe(
      data => {
        this.actionsLoader = false;
        this.swalService.swalSuccess('')
          .then(() => {
            this.service.refreshUsersList.next(true);
            this.closeDetails.emit();
          });
      },
      err => {
        this.actionsLoader = false;
        const errors = JSON.parse(err.detail);
        let message = '';
        if (err.detail) {
          errors.ErrorDTO.forEach((error) => {
            if (error.ErrorCode === 'UserHaveCases') {
              message = 'User Can\'t Be Deleted because Have Cases';
            } else if (error.ErrorCode === 'UserHaveSlas') {
              message = 'Can\'t deactivate this pathologist because he have active SLA';
            }
          });
        } else {
          message = err.title;
        }
        this.swalService.swalFailed(message);
      });
  }

  checkRole(item: string) {
    if (this.user.roles) {
      return this.user.roles.includes(item);
    }
    return false;
  }

  resendActivationMail() {
    const query = {
      UserId: this.userInfo.labUserId,
      UserType: this.userType
    };
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.yes'))
      .then((result) => {
        this.actionsLoader = true;
        if (result.value) {
          this.service.ResendActivationMail(query).subscribe(
            data => {
              this.actionsLoader = false;
              this.swalService.swalSuccess()
                .then(() => {
                  // this.getDetails();
                });
            }, error => {
              this.actionsLoader = false;
              // console.log('Try again!, ' + error);
              this.swalService.swalFailed(error.title);
            });
        } else {
          this.actionsLoader = false;
        }
      });
  }

  ResendResetPasswordMail() {
    const query = {
      UserId: this.userInfo.labUserId,
      UserType: this.userType
    };
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.yes'))
      .then((result) => {
        this.actionsLoader = true;
        if (result.value) {
          this.service.ResendResetPasswordMail(query).subscribe(
            data => {
              this.actionsLoader = false;
              this.swalService.swalSuccess()
                .then(() => {
                  // this.getDetails();
                });
            }, error => {
              this.actionsLoader = false;
              // console.log('Try again!, ' + error);
              this.swalService.swalFailed(error.title);
            });
        } else {
          this.actionsLoader = false;
        }
      });
  }

  deactivateRecord() {
    const query = {
      UserId: this.userInfo.labUserId,
      UserType: this.userType
    };
    // if (this.module === ModelsEnum.Lab) {
    //   query.UserType = ModelsEnum.LabUser;
    // } else if (this.module === ModelsEnum.ScanningCenter) {
    //   query.UserType = ModelsEnum.SCUser;
    // } else if (this.module === ModelsEnum.Hospital) {
    //   query.UserType = ModelsEnum.hospitalUser;
    // } else if (this.module === ModelsEnum.Clinic) {
    //   query.UserType = ModelsEnum.clinicUser;
    // } else if (this.module === ModelsEnum.VL) {
    //   query.UserType = ModelsEnum.VLUser;
    // }
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.yes'))
      .then((result) => {
        this.actionsLoader = true;
        if (result.value) {
          this.service.checkBeforeDeleteUser(query).subscribe(
            (data: any) => {
              if (data.roles.length) {
                this.swalService.swalConfirmation(
                  this.translate.instant('swalMsg.yes'),
                  'This User has reviewer role',
                  'Are you sure to Deactivate User?'
                ).then((result) => {
                  if (result.value) {
                    this.deactivateUser(query);
                  } else {
                    this.actionsLoader = false;
                  }
                });
              } else {
                this.deactivateUser(query);
              }

            },
            err => {
              this.actionsLoader = false;
            }
          );

        } else {
          this.actionsLoader = false;
        }
      });
  }

  deactivateUser(query) {
    this.service.DeactivateUser(query).subscribe(
      data => {
        this.actionsLoader = false;
        this.swalService.swalSuccess()
          .then(() => {
            this.getDetails();
            this.service.refreshUsersList.next(true);
          });
      }, err => {
        this.actionsLoader = false;
        const errors = JSON.parse(err.detail);
        let message = '';
        if (err.detail) {
          errors.ErrorDTO.forEach((error) => {
            if (error.ErrorCode === 'UserHaveCases') {
              message = 'User Can\'t Be Deactivate because Have Cases';
            } else if (error.ErrorCode === 'UserHaveSlas') {
              message = 'Can\'t deactivate this pathologist because he have active SLA';
            }
          });
        } else {
          message = err.title;
        }
        // console.log('Try again!, ' + error);
        this.swalService.swalFailed(message);
      });
  }

  activateUser() {
    const query = {
      UserId: this.userInfo.labUserId,
      UserType: this.userType
    };
    // if (this.module === ModelsEnum.Lab) {
    //   query.UserType = ModelsEnum.LabUser;
    // } else if (this.module === ModelsEnum.ScanningCenter) {
    //   query.UserType = ModelsEnum.SCUser;
    // } else if (this.module === ModelsEnum.Hospital) {
    //   query.UserType = ModelsEnum.hospitalUser;
    // } else if (this.module === ModelsEnum.Clinic) {
    //   query.UserType = ModelsEnum.clinicUser;
    // } else if (this.module === ModelsEnum.VL) {
    //   query.UserType = ModelsEnum.VLUser;
    // }
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.yes'))
      .then((result) => {
        this.actionsLoader = true;
        if (result.value) {
          this.service.ActiveUser(query).subscribe(
            data => {
              this.actionsLoader = false;
              this.swalService.swalSuccess()
                .then(() => {
                  this.getDetails();
                  this.service.refreshUsersList.next(true);
                });
            }, error => {
              this.actionsLoader = false;
              // console.log('Try again!, ' + error);
              this.swalService.swalFailed(error.title);
            });
        } else {
          this.actionsLoader = false;
        }
      });
  }

  // File preview with validation
  uploadAvatar($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.avatar = file;
    } else {
      this.avatarSrc = undefined;
      this.avatar = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.user.userImage = myReader.result as string;
      // this.avatarSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  acceptRequest(id) {
    this.actionsLoader = true;
    const data = {
      pathologistId: id,
      action: 'ACCEPTED'
    };
    this.service.takeActionForPathologistRequest(data).subscribe(
      () => {
        this.actionsLoader = false;
        this.getDetails();
        this.service.refreshUsersList.next(true);
      }, error => {
        this.actionsLoader = false;
        // console.log('Try again!, ' + error);
      });
  }

  declineRequest(id) {
    const dataRecord = {
      title: 'Decline Request',
      titleField: 'Reasons',
      comment: null,
      record: {
        id,
        pathologistId: id,
        action: 'REJECTED',
        commentProperty: 'rejectionReason'
      },
      serviceTarget: 'takeActionForPathologistRequest',
      service: this.service,
      showClose: true,
      btnSubmitTitle: 'Submit Reasons',
      swalMessageSuccess: this.translate.instant('swalMsg.declinedsuccessfully')
    };
    const dialogRef = this.dialog.open(SubmitReasonComponent, {
      width: '500px',
      data: {
        id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
        this.service.refreshUsersList.next(true);
      }
    });
  }

  performanceStatistics() {
    const dialogRef = this.dialog.open(UserPerformanceStatisticsComponent, {
      width: '550px',
      data: {
        userId: this.user.id,
        entityType: this.entityType,
        user: this.user,
        title: this.title
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

}
