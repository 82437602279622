<div mat-dialog-title>
  <h2>
    View Reasons
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="profile_detail">
      <div class="item_timeline mt-0">

        <div class="timeline_inner">
          <div class="timeline_head">
            <div class="state_log">
              <span class="danger"></span>
            </div>
            <p>
              {{data && data.status === 'TERMINATED'?'Terminate':'Decline'}}
            </p>
          </div>
          <div class="item_avatar_content">
            <span>
              {{data && data.status === 'TERMINATED'?'Terminated':'Declined'}} on
            </span>
            <h4>
              {{data.creationDate | date:'d MMM, y, h:mm a'}}
            </h4>
          </div>
        </div>
        <div class="timeline_content" *ngIf="data.comment">
          <h4>Reasons</h4>
          <p>
            {{data.comment}}
          </p>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
