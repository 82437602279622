<div class="item_media_sla">
  <div class="icon">
    <img src="assets/images/icons/sla-icon.svg" alt="">
  </div>
  <div class="content flex-grow-1">
    <h4>Service Level Agreement (Histo & {{entityValue}})</h4>
    <ng-container *ngIf="!sla">
      <p>No SLA Added yet</p>
      <a mat-raised-button color="primary" appAccessControl accessRoles="SuperAdmin"
        [routerLink]="['/admin/sla/'+entityType+'/'+ entityName + '/' + entityId + '/create-sla']">
        Fill Out SLA
      </a>
    </ng-container>
    <ng-container *ngIf="sla">
      <p *ngIf="sla">
        Added on: {{sla.creationDate | date:'d MMM, y, h:mm a'}}
      </p>
      <ng-container *ngIf="sla">
        <app-sla-status [status]="sla.slaStatus"></app-sla-status>

        <a mat-button color="primary" class="btn_link_with_icon p-0 ml-2" *ngIf="sla.slaStatus === 'REJECTED'"
          (click)="declineReason()">
          View Reasons
        </a>
        <a mat-button color="primary" class="btn_link_with_icon p-0 ml-2"
          *ngIf="sla.slaStatus === 'PENDING' && sla.rejectionReason && !hasPermission('SuperAdmin')"
          (click)="declineReason()">
          View Earlier Objection Reasons
        </a>
      </ng-container>

      <div class="mt-3 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <button mat-raised-button class=" mat-outline primary mt-0 mr-1"
            [routerLink]="['/admin/sla/'+entityType + '/preview-sla/' + sla.slaId +'/'+ isProfile]">
            View SLA
          </button>
          <ng-container *ngIf="hasPermission('SuperAdmin')">
            <a class="button_with_icon" mat-raised-button color="primary"
              *ngIf="sla && (sla.slaStatus === 'PENDING' || sla.slaStatus === 'REJECTED')"
              [routerLink]="['/admin/sla/'+entityType+'/' + entityName + '/' + entityId + '/edit-sla/' + sla.slaId]">
              <svg width="16" height="16.004" viewBox="0 0 16 16.004">
                <path id="edit-icon"
                  d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
                  transform="translate(-0.188 0.001)" />
              </svg>
              <span class="value">Edit</span>
            </a>
            <a mat-raised-button color="primary"
              *ngIf="lastSLA && (lastSLA.status === 'APPROVED' || lastSLA.status === 'TERMINATED' || lastSLA.status === 'EXPIRED')"
              [routerLink]="['/admin/sla/'+entityType+'/'+ entityName + '/' + entityId + '/create-sla']">
              Add SLA
            </a>
          </ng-container>
        </div>
        <a mat-button color="primary" class="btn_link_with_icon p-0" (click)="oldSlAs()">
          View SLAs
          <i class="material-icons">
            launch
          </i>
        </a>
      </div>
    </ng-container>
  </div>
</div>