import { Component, Input, OnInit } from '@angular/core';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { ListHeaderData } from 'src/app/models/payments/list-header-data';
import { CurrencyService } from 'src/app/services/currency.service';

@Component({
  selector: 'app-entity-outgoing',
  templateUrl: './entity-outgoing.component.html'
})
export class EntityOutgoingComponent implements OnInit {
  @Input() walletDetails: ListHeaderData = new ListHeaderData();
  defaultCurrency: DefaultCurrency;

  constructor(private currencyService: CurrencyService) {
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit() {
  }

}
