import { Component, OnInit, NgZone } from '@angular/core';
import { TranslationService } from './services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslationService
  ) {

  }

  ngOnInit() {
    this.setLnag();
  }

  setLnag() {
    const lang = this.translateService.getSelectedLanguage();
    this.translateService.setLanguage(lang);
  }
}
