<ng-container *ngIf="casesCount > 0; else casesEmpty">
  <div class="top_card">
    <figure>
      <img src="./assets/images/icons/cases-icon-light.svg" alt="">
    </figure>
    <div class="inner_case">
      <div class="content_case">
        <h4>{{casesCount}}</h4>
        <p>Total Cases</p>
      </div>
      <div class="right_card">
        <span class="item_total_count">
          <strong>
            {{openCasesCount}}
          </strong>
          Open
        </span>
        <span class="separator"></span>
        <span class="item_total_count danger">
          <strong>
            {{closedCasesCount}}
          </strong>
          Closed
        </span>
      </div>
    </div>
  </div>
  <div class="footer_case" *ngIf="entityName && !isOurProfile" appAccessControl
    accessRoles="SuperAdmin,LabAccountant,VLAccountant,LabAdmin,MainLabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin,ScanningCenterMainAdmin">
    <a (click)="getCases(entityName)">
      View all Cases
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
        <path id="arrow"
          d="M7.327,13.707a.924.924,0,0,1,0-1.416l4.689-4.167H.993A1.065,1.065,0,0,1,0,7,1.065,1.065,0,0,1,.993,5.875H12.016L7.327,1.707A.945.945,0,0,1,7,1,.944.944,0,0,1,7.327.293a1.225,1.225,0,0,1,1.591,0l6.751,6a.923.923,0,0,1,0,1.416l-6.751,6a1.227,1.227,0,0,1-1.591,0Z"
          fill="#959595" />
      </svg>
    </a>
  </div>
</ng-container>

<ng-template #casesEmpty>
  <app-empty-state custClass="py-0" *ngIf="true" [desc]='"No cases added yet"'
    [img]="'./assets/images/icons/cases-icon-grey.svg'"></app-empty-state>
</ng-template>