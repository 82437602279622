import { Component, OnInit, Renderer2, Input, ElementRef, ViewChild, HostListener, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-light-gallery-video',
  templateUrl: './light-gallery-video.component.html'
})
export class LightGalleryVideoComponent implements OnInit, AfterViewInit {
  @Input() videoUrl = '';
  @ViewChild('videoplayer') videoplayer: ElementRef<HTMLMediaElement>;
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
    console.log(this.videoplayer)
    this.videoplayer.nativeElement.play();
  }


  closePreview() {
    console.log(this.videoplayer)
    this.videoplayer.nativeElement.pause();
    this.videoUrl = '';
    this.renderer.removeClass(document.body, 'overflow-hidden');
    this.closeEvent.emit(true);
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.videoUrl) {
      this.closePreview();
    }
  }

}
