<nav class="navbar head-nav">
  <h2 *ngIf="!dataLoading" [matTooltip]="details.hospitalName" matTooltipClass="wrap-tooltip">
    {{ isOurProfile ? 'Our Hospital Profile' : details.hospitalName }}
  </h2>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]='["/"]'>Home</a></li>
    <li class="breadcrumb-item">{{ isOurProfile ? 'Our Hospital Profile' : 'Hospitals' }}</li>
  </ol>
</nav>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<div class="lab_profile" *ngIf="(!dataLoading && isOurProfile) || (!dataLoading && hospitalId)">
  <div class="profile_wrapper_header">
    <div class="profile_inner_left">
      <div class="profile_image">
        <img [src]="details.logo" (error)='details.logo = "assets/images/lab/default_avatar.svg"' />
        <!-- <span>MS</span> -->
      </div>
      <div class="profile_content_left">
        <h4>
          {{details.hospitalName}}
        </h4>
        <div class="create_date">
          Created on: <span>{{details.createdOn | date:'d MMM, y, h:mm a'}}</span>
        </div>
      </div>
    </div>
    <div class="profile_inner_right">
      <button appAccessControl accessRoles="SuperAdmin" mat-icon-button [matTooltip]="'Delete'"
        (click)="deleteRecord(hospitalId)" *ngIf="details.canDeleteHospital">
        <svg width="16" height="20.002" viewBox="0 0 16 20.002">
          <path id="delete-icon"
            d="M4422.4,17833.639a1.853,1.853,0,0,1-1.844-1.785l-.51-10.822a.2.2,0,0,1,.192-.207H4433.9a.194.194,0,0,1,.192.207l-.505,10.822a1.859,1.859,0,0,1-1.844,1.785Zm7.134-11.252v8.129a.616.616,0,1,0,1.231,0v-8.129a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.075,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.692-2.811a.621.621,0,0,1-.618-.627v-1.248a1.551,1.551,0,0,1,1.54-1.562h3.383v-.627a1.864,1.864,0,0,1,1.849-1.875h2.461a1.86,1.86,0,0,1,1.844,1.875v.627h3.383a1.551,1.551,0,0,1,1.54,1.563v1.248a.617.617,0,0,1-.613.627Zm5.536-4.064v.627h3.692v-.627a.616.616,0,0,0-.613-.621h-2.461A.619.619,0,0,0,4425.223,17815.512Z"
            transform="translate(-4419.069 -17813.637)" fill="#616e7c" />
        </svg>
      </button>
      <a class="button_with_icon" appAccessControl accessRoles="SuperAdmin,HospitalAdmin" mat-raised-button
        color="primary" (click)="openHospitalForm()">
        <svg width="16" height="16.004" viewBox="0 0 16 16.004">
          <path id="edit-icon"
            d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
            transform="translate(-0.188 0.001)" />
        </svg>
        <span class="value">Edit</span>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile card_info_transactions">
      <div class="card_info_profile_content" appAccessControl accessRoles="SuperAdmin,HospitalAdmin,HospitalAccountant">
        <app-entity-balance [isOurProfile]="isOurProfile" [walletDetails]="walletDetails"
          [entityId]="!isOurProfile?hospitalId:0" [entityType]="!isOurProfile?'HOSPITAL':null"></app-entity-balance>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile card_info_case">
      <div class="card_info_profile_content" appAccessControl accessRoles="SuperAdmin,HospitalAdmin,HospitalAccountant"
        [ngClass]="{'d-flex justify-content-center align-items-center':details.openCasesCount === 0}">
        <app-entity-total-cases class="w-100" [casesCount]="details.casesCount"
          [openCasesCount]="details.openCasesCount" [closedCasesCount]="details.closedCasesCount"
          [entityName]="details.hospitalName" [isOurProfile]="isOurProfile"></app-entity-total-cases>
      </div>
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12">
      <div class="card_info_profile card_info_transactions" appAccessControl
        accessRoles="SuperAdmin,HospitalAdmin,HospitalAccountant">
        <div class="card_info_profile_content">
          <ng-container *ngIf="walletDetails && walletDetails.incoming; else walletEmpty">
            <app-entity-incoming [walletDetails]="walletDetails"></app-entity-incoming>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12">
      <div class="card_info_profile card_info_transactions" appAccessControl
        accessRoles="SuperAdmin,HospitalAdmin,HospitalAccountant">
        <div class="card_info_profile_content">
          <ng-container *ngIf="walletDetails && walletDetails.outgoing; else walletEmpty">
            <app-entity-outgoing [walletDetails]="walletDetails"></app-entity-outgoing>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile">
      <app-entity-basic-info (verifyMobileEvent)="verifyMobile($event)" (verfiyEmailEvent)="verfiyEmail($event)"
        [record]="details" [entityId]="hospitalId" [entityType]="entityEnumSLA.hospital"></app-entity-basic-info>
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile title-has-btns revert" *ngIf="!isOurProfile">
      <app-users-list-shared [sla]="details.hospitalSLA" [entityType]='"Hospital"' [entityId]='hospitalId'>
      </app-users-list-shared>
    </div>
    <ng-container *ngIf="isOurProfile">
      <ng-container *ngTemplateOutlet="communicationMethods"></ng-container>
    </ng-container>
    <div class="col-xl-6 col-lg-12 col-md-12">

      <div class="card_info_profile" appAccessControl accessRoles="SuperAdmin" *ngIf="details.ownLabs">
        <div class="card_info_profile_content">
          <div class="item_media_sla align-items-center">
            <div class=" icon">
              <img src="assets/images/icons/labs-radiology-centers-icon.svg" alt="">
            </div>
            <div class="content flex-grow-1">
              <h4>Hospital Lab(s)</h4>
              <ng-container *ngIf="details.hospitalLabsCount === 0">
                <p class="mb-0">
                  The hospital stated that it has a lab but not added yet
                </p>
              </ng-container>
              <ng-container *ngIf="details.hospitalLabsCount > 0">
                <p class="mb-0">
                  The hospital has {{details.hospitalLabsCount}} lab{{details.hospitalLabsCount === 1 ?'':'s'}}
                </p>
              </ng-container>
            </div>
            <div class="actions" *ngIf="details.hospitalLabsCount > 0">
              <a mat-raised-button class="mat-outline primary" [routerLink]='["/admin/labs"]'
                [queryParams]="{entityId:details.id,entityType:'HOSPITAL'}">
                <span class="mr-2">View Labs</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.995" height="10.01" viewBox="0 0 15.995 10.01"
                  fill="#7D4393">
                  <path id="arrow-to-right"
                    d="M17.71,11.29l-4-4a1,1,0,0,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,1,0,1.42,1.42l4-4a1.034,1.034,0,0,0,0-1.42Z"
                    transform="translate(-2 -6.996)" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card_info_profile" appAccessControl accessRoles="SuperAdmin,HospitalAdmin,HospitalAccountant">
        <div class="card_info_profile_content">
          <app-sla-actions [entityId]="hospitalId?hospitalId:currentUser.ObjectValue" [isProfile]="isOurProfile"
            [entityValue]="'Hospital'" [entityType]="'hospital'" [sla]="details.hospitalSLA" [lastSLA]="details.lastSLA"
            [entityName]="details.hospitalName">
          </app-sla-actions>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-xl-6 col-lg-12 col-md-12 pl-0 card_info_profile item_more_info">
          <div class="card_info_profile_content">
            <h4>
              Final Report for Patient
            </h4>
            <p>
              The report that will be printed for the patient
            </p>
            <span class="tag-item mb-0">
              {{details.finalReport === 'HOSPITAL_REPORT'?'Hospital Report':'Lab Report'}}
            </span>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 pr-0 card_info_profile item_more_info">
          <div class="card_info_profile_content">
            <h4>
              Scanners
            </h4>
            <p>
              The hospital stated that it {{details.ownScanners?'':'doesn\'t'}} have scanners.
            </p>
            <p class="mb-0" *ngIf="!details.ownScanners">
              <img src="assets/images/icons/scanners-icon.svg" alt="">
              Has Scanners?
              <a (click)="openHospitalForm()">Update</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isOurProfile">
      <ng-container *ngTemplateOutlet="communicationMethods"></ng-container>
    </ng-container>

  </div>
</div>

<ng-template #communicationMethods>
  <div class="col-xl-6 col-lg-12 col-md-12">
    <app-entity-communication-methods [communicationMethodList]="details.communicationMethod"
      [entityName]="'hospital'"></app-entity-communication-methods>
  </div>
</ng-template>

<ng-template #walletEmpty>
  <app-empty-state custClass="py-0" [desc]='"The wallet is empty, and no transactions have occurred."'
    [img]="'./assets/images/icons/wallet-icon.svg'"></app-empty-state>
</ng-template>