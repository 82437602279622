import { ModelsEnum } from './../../../models/users/users/enums/ModelsEnum';
import { ChangeUsernameComponent } from './../change-username/change-username.component';
import { ChangePasswordComponent } from './../change-password/change-password.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDetails } from 'src/app/models/users/users/UserDetails';
import { UsersService } from 'src/app/services/users.service';
import { UserFormComponent } from '../user-management/user-form/user-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VerifyMobileComponent } from 'src/app/partial/verify-mobile/verify-mobile.component';
import { SwalService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
  user: UserDetails;
  dataLoading = false;
  loading = false;
  userId = 9;

  // image file
  avatar: any;
  avatarSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  constructor(
    private service: UsersService,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.getDetails();
    this.auth.currentUserSubject.subscribe(user => {
      if (user) {
        this.getDetails();
      }
    });
  }

  getDetails(reload = true) {
    this.dataLoading = reload;
    this.service.getUserProfile().subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.user = data as UserDetails;
          this.clear();
        }
      },
      error => {
        this.dataLoading = false;
      }
    );
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '540px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
      }
    });
  }

  changeUsername() {
    const dialogRef = this.dialog.open(ChangeUsernameComponent, {
      width: '540px',
      data: {
        userName: this.user.userName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
      }
    });
  }

  editProfile() {
    console.log(ModelsEnum.profile);

    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '620px',
      data: {
        record: this.user,
        userId: this.userId,
        model: ModelsEnum.profile, // LAB, Sc, VL
        isModuleAdmin: null,
        // isPathologist: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }

  checkRole(item: string) {
    if (this.user.roles) {
      return this.user.roles.includes(item);
    }
    return false;
  }

  // File preview with validation
  uploadAvatar($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.avatar = file;
    } else {
      this.clear();
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      // this.user.userImage = myReader.result as string;
      this.avatarSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  updateAvatar() {
    if (this.avatarSrc) {
      this.loading = true;
      const formData = new FormData();
      formData.append('userImage', this.avatar, this.avatar.name);
      this.service.changeProfilePicture(formData).subscribe(() => {
        this.loading = false;
        this.getDetails(false);
        this.swalService.swalSuccess('Updated Successfully')
          .then(() => {
          });
      }, err => {
        this.loading = false;
      });
    }
  }

  clear() {
    this.avatarSrc = this.user.userImage;
    this.avatar = undefined;
  }

  verifyMobile() {
    const dataRecord = {
      verifyEntityUserMobile: true,
      title: 'Verify your Mobile',
      message: 'We have sent you a one-time password to your mobile number'
    };
    const dialogRef = this.dialog.open(VerifyMobileComponent, {
      width: '750px',
      data: dataRecord
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getDetails();
      }
    });

  }

}
