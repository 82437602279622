<div [formGroup]='formGroup'>
  <div class="app-speech-input" [class.app-speech-input-listening]="isUserSpeaking">
    <mat-form-field>
      <textarea matInput class="app-speech-input-form-control" (keyup)="stopRecording()"
        [formControlName]="fieldControlName">
      </textarea>
      <button matSuffix mat-icon-button class="app-speech-input-btn" *ngIf="isUserSpeaking" (click)="stopRecording()">
        <mat-icon class="soundwave">stop_circle</mat-icon>
      </button>
      <button matSuffix mat-icon-button class="app-speech-input-btn" (click)="startRecording()" *ngIf="!isUserSpeaking">
        <mat-icon>mic</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>