import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ContractType } from 'src/app/models/sla/enums/ContractType';

@Injectable({
  providedIn: 'root',
})
export class SlaService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getAll(params): any {
    if (params.isListSLAsBetweenHistoAndEntities) {
      delete params.isListSLAsBetweenHistoAndEntities;
      return this.http.get(
        `${environment.apiUrl}/EntitySLA/ListSLAsBetweenHistoAndEntities`,
        { params }
      );
    } else if (params.isListListSLAsBetweenEntities) {
      delete params.isListListSLAsBetweenEntities;
      return this.http.get(
        `${environment.apiUrl}/SLAContract/ListSLAsBetweenEntities`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.apiUrl}/SLAContract/ListSLAContracts`,
        { params }
      );
    }
  }

  saveRecordSLA(data, slaID = null, type: ContractType = null) {
    if (type) {
      if (slaID) {
        return this.http.put(
          `${environment.apiUrl}/SLAContract/UpdateSLAContract`,
          data
        );
      } else {
        return this.http.post(
          `${environment.apiUrl}/SLAContract/AddSLAContract`,
          data
        );
      }
    } else {
      if (slaID) {
        return this.http.put(
          `${environment.apiUrl}/EntitySLA/EditEntitySLA`,
          data
        );
      } else {
        return this.http.post(
          `${environment.apiUrl}/EntitySLA/AddEntitySLA`,
          data
        );
      }
    }
  }

  getSLADetails(id, type = false) {
    if (type) {
      return this.http.get(
        `${environment.apiUrl}/SLAContract/GetSlaContractDetails?SlaId=${id}`
      );
    } else {
      return this.http.get(
        `${environment.apiUrl}/EntitySLA/GetEntitySLADetails?SlaId=${id}`
      );
    }
  }

  deleteSLARecord(id, type = false) {
    if (type) {
      return this.http.delete(
        `${environment.apiUrl}/SLAContract/DeleteSLAContract?SlaId=${id}`
      );
    } else {
      return this.http.delete(
        `${environment.apiUrl}/EntitySLA/DeleteEntitySLA?SlaId=${id}`
      );
    }
  }

  changeEntitySLAStatus(data, type = false) {
    if (type) {
      return this.http.put(
        `${environment.apiUrl}/SLAContract/ChangeSLAContractStatus`,
        data
      );
    } else {
      return this.http.put(
        `${environment.apiUrl}/EntitySLA/ChangeEntitySLAStatus`,
        data
      );
    }
  }

  ChangeSLAContractStatus(data) {
    return this.http.put(`${environment.apiUrl}/SLAContract/ChangeSLAContractStatus`, data);
  }

  ChangeEntitySLAStatus(data) {
    return this.http.put(`${environment.apiUrl}/EntitySLA/ChangeEntitySLAStatus`, data);
  }

  getEntityServices(entityType) {
    return this.http.get(
      `${environment.apiUrl}/EntitySLA/GetEntityServices?EntityType=${entityType}`
    );
  }

  getSLAHistory(params) {
    return this.http.get(`${environment.apiUrl}/EntitySLA/GetSLAHistory`, {
      params,
    });
  }

  getLabList() {
    return this.http.get(`${environment.apiUrl}/SLAContract/GetLabList`);
  }

  getLablookupDetailsById(params) {
    return this.http.get(
      `${environment.apiUrl}/SLAContract/GetLablookupDetailsById`,
      { params }
    );
  }

  getListPathologistsLookup(params) {
    return this.http.get(
      `${environment.apiUrl}/SLAContract/ListPathologistsLookup`,
      { params }
    );
  }
}
