export enum statusList {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED',
  NOT_ADDED = 'NOT_ADDED',
  DECLINED = 'DECLINED',
  PAUSED = 'PAUSED'
}
