import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { BranchesService } from 'src/app/services/branches.service';
import { LabsService } from 'src/app/services/labs.service';
import { ScanningCentersService } from 'src/app/services/scanningCenters.service';
import { LabFormComponent } from '../../labs/lab-form/lab-form.component';
import { ScanningCenterFormComponent } from '../../scanning-center/scanning-center-form/scanning-center-form.component';

@Component({
  selector: 'app-branches-side',
  templateUrl: './branches-side.component.html'
})
export class BranchesSideComponent implements OnInit {
  @Input() type: ModelsEnum;
  recordId: number;
  recordsData: Lookup[];
  dataLoading = false;
  @Output() deleteBranch = new EventEmitter<any>();
  @Output() closeDetails = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: BranchesService,
    private labService: LabsService,
    private scService: ScanningCentersService,
    public translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.recordId = Number(params.get('id'));
      }
    });
    this.service.refreshProfileBraches.subscribe(data => {
      if (data) { this.getAll(); }
    });
    this.getAll();
  }

  getAll() {
    this.dataLoading = true;
    this.service.getBranches(this.recordId, this.type).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as Lookup[];
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  openForm(addBranch) {
    let targetComponent = null;
    if (this.type === ModelsEnum.Lab) {
      targetComponent = LabFormComponent;
    } else if (this.type === ModelsEnum.ScanningCenter) {
      targetComponent = ScanningCenterFormComponent;
    }
    const dialogRef = this.dialog.open(targetComponent, {
      width: '600px',
      data: {
        recordId: this.recordId,
        record: null,
        addBranch
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll();
        if (this.type === ModelsEnum.Lab) {
          this.labService.refreshDetails.next(true);
        } else if (this.type === ModelsEnum.ScanningCenter) {
          this.scService.refreshDetails.next(true);
        }
      }
    });
  }

  deleteRecord(recordID) {
    this.deleteBranch.emit(recordID);
    return;
  }

  recordDetails(recordID) {
    if (this.type === ModelsEnum.Lab) {
      this.router.navigate(['/admin/labs', recordID]);
    } else if (this.type === ModelsEnum.ScanningCenter) {
      this.router.navigate(['/admin/scanning-centers', recordID]);
    }
    this.closeDetails.emit();
  }

}
