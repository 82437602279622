import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { InputDetails } from 'src/app/models/configuration/reports/inputs/Details';

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html'
})
export class DynamicFieldComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Input() allowVoiceRecognition: boolean;
  @Input() formGroup: FormGroup;
  @Input() field: InputDetails;

  constructor() {
  }

  ngOnInit(): void {
    // this.formGroup.addControl('searchText', new FormControl(''))
    // this.formGroup.addControl('searchText2', new FormControl(''))
  }

  hasError(errorName: string, controlName = null, index = null) {
    if (controlName && index !== null) {
      return (this.formGroup.controls[controlName] as FormArray).controls[index].hasError(errorName);
    } else {
      return this.formGroup.controls[controlName ? controlName : this.field.id].hasError(errorName);
    }
  }

  otherHasError(fieldId, errorName: string) {
    return this.formGroup.controls[fieldId + '_other'].hasError(errorName);
  }

  dropdownChanged(fieldId) {
    if (this.field.isOtherOption) {
      this.formGroup.controls[fieldId + '_other'].setValue(null);
    }
  }

  checkHaveConditionalogic(arr: InputDetails[], id: number): any[] {
    return arr.filter(item => item.valueId === id);
  }

  checkIndex(controlName: string, index: number) {
    let sameId = false;
    if (this.formGroup.controls[controlName].value[index].check) {
      sameId = true;
    }
    return sameId;
  }

  getArray(count: number): number[] {
    return Array.from(Array(count).keys());
  }

  resetDate(id) {
    this.formGroup.controls[id].setValue(null);
  }

}
