import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CounterDirective } from 'src/app/helpers/counter.directive';



export interface Setting {
  inputStyles?: { [key: string]: any };
  wrapperStyles?: { [key: string]: any };
  allowKeyCodes?: string[];
  length: number;
  numbersOnly?: boolean;
  inputClass?: string;
  wrapperClass?: string;
  timer?: number;
  timerType?: number; //  0: secs, 1: mins
}

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html'
})
export class OtpInputComponent implements OnInit {

  @Input() setting: Setting = {
    length: 4,
    timer: 0,
    timerType: 0
  };
  @Output() valueChanged = new EventEmitter<any>();
  @ViewChildren(CounterDirective) CounterDirective;
  otpForm: FormGroup;
  inputControls: FormControl[] = new Array(this.setting.length);
  componentKey = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
  public counter: number;

  constructor() {

  }

  public ngOnInit(): void {
    this.otpForm = new FormGroup({});
    for (let index = 0; index < this.setting.length; index++) {
      this.otpForm.addControl(this.getControlName(index), new FormControl(null, Validators.required));
    }
  }

  private getControlName(idx) {
    return `ctrl_${idx}`;
  }

  isLeftArrow(e) {
    return this.isKeyCode(e, 37);
  }

  isRightArrow(e) {
    return this.isKeyCode(e, 39);
  }

  isBackspaceOrDelete(e) {
    return e.key === 'Backspace' || e.key === 'Delete' || this.isKeyCode(e, 8) || this.isKeyCode(e, 46);
  }

  isKeyCode(e, targetCode) {
    const key = e.keyCode || e.charCode;
    if (key === targetCode) { return true; }
    return false;
  }

  keyUp(e, inputIdx: number) {
    const nextInputId = this.appendKey(`otp_${inputIdx + 1}`);
    const prevInputId = this.appendKey(`otp_${inputIdx - 1}`);
    if (this.isRightArrow(e)) {
      this.setSelected(nextInputId);
      return;
    }
    if (this.isLeftArrow(e)) {
      this.setSelected(prevInputId);
      return;
    }
    const isBackspace = this.isBackspaceOrDelete(e);
    if (isBackspace && !e.target.value) {
      this.setSelected(prevInputId);
      this.rebuildValue();
      return;
    }
    if (!e.target.value) {
      return;
    }
    if (this.isValidEntry(e)) {
      this.focusTo(nextInputId);
    }
    this.rebuildValue();
  }

  appendKey(id) {
    return `${id}_${this.componentKey}`;
  }

  setSelected(eleId) {
    this.focusTo(eleId);
    const ele: any = document.getElementById(eleId);
    if (ele && ele.setSelectionRange) {
      setTimeout(() => {
        ele.setSelectionRange(0, 1);
      }, 0);
    }
  }

  isValidEntry(e) {
    const inp = String.fromCharCode(e.keyCode);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile || /[a-zA-Z0-9-_]/.test(inp) ||
      (this.setting.allowKeyCodes && this.setting.allowKeyCodes.includes(e.keyCode)) || (e.keyCode >= 96 && e.keyCode <= 105);
  }

  focusTo(eleId) {
    const ele: any = document.getElementById(eleId);
    if (ele) {
      ele.focus();
      ele.selectionStart = ele.selectionEnd = 100;
    }
  }

  rebuildValue() {
    let val = '';
    this.transform(this.otpForm.controls).forEach(k => {
      if (this.otpForm.controls[k].value) {
        val += this.otpForm.controls[k].value;
      }
    });
    this.valueChanged.emit(val);
  }

  transform(value: any): string[] {
    return Object.keys(value);
  }

  public onCounterChange(e): void {
    this.counter = e;
    if (this.counter === 0) {
      this.valueChanged.emit(-1);
    }
  }

  ressendOtp(): void {
    Object.keys(this.otpForm.controls).forEach(key => {
      this.otpForm.controls[key].setValue(null);
    });
    this.CounterDirective.first.startTimer();
    this.valueChanged.emit(-2);
  }

  public formatSecsToMins(time) {
    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }
}
