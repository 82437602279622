import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(private translate: TranslateService) { }

  public swalConfirmation(confirmButtonText: string, title = this.translate.instant('swalMsg.Areyousure'), text = '') {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    });
  }

  public swalFailed(text: string) {
    return Swal.fire({
      title: this.translate.instant('swalMsg.Failed'),
      text,
      icon: 'error',
      confirmButtonText: this.translate.instant('swalMsg.ok'),
    });
  }

  public swalSuccess(text: string = '', confirmButtonText = this.translate.instant('swalMsg.ok')) {
    return Swal.fire({
      title: this.translate.instant('swalMsg.success'),
      text,
      icon: 'success',
      confirmButtonText
    });
  }

  public swalWarning(text: string = '', title: string = '') {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: this.translate.instant('swalMsg.ok')
    });
  }

}
