import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipList } from '@angular/material/chips';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tree-field',
  templateUrl: './tree-field.component.html'
})
export class TreeFieldComponent implements OnInit {
  @Input() required = false;
  @Input() isSubmitted = false;
  @Input() recordsLoading = false;
  @Input() isDisabled = false;
  @Input() recordsTree: Lookup[] = [];
  @Input() detailsSelectedRecords: any;
  @Output() selected = new EventEmitter();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = false;
  selectable = true;
  removable = true;
  searchCtrl = new FormControl();
  selectedRecords: Lookup[] = [];
  showRecordsTree = false;
  // recordsLoading = false;
  @ViewChild('chipRecordsList', { static: false }) chipRecordsList: MatChipList;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {

  }

  updateSelectedRecords(data) {
    this.detailsSelectedRecords = [];
    this.selectedRecords = [];
    if (data) {
      data.forEach((element: Lookup) => {
        if (!element.childs && !this.isInArray(this.selectedRecords, element)) {
          this.selectedRecords.push(element);
        }
      });
      this.selected.emit(this.selectedRecords);
      /* this.searchInput.nativeElement.value = '';
      this.searchCtrl.setValue(null);
      this.filterTree(''); */
    }
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  // dropdowns overlay events
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showRecordsTree = false;
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!event) {
      this.showRecordsTree = !this.showRecordsTree;
    } else if (!this.eRef.nativeElement.contains(event.target)) {
      this.showRecordsTree = false;
    }
    if (!this.showRecordsTree) {
      this.searchCtrl.setValue(null);
      this.filterTree('');
    } else {
      this.searchInput.nativeElement.focus();
    }
  }

  filterTree(value, event = false) {
    if (event) { this.showRecordsTree = true; }
    const filterValue = value.toLowerCase();
    this.recordsTree.forEach(region => {
      if (region.name.toLowerCase().indexOf(filterValue) === 0) {
        region.hidden = false;
      } else {
        region.hidden = true;
        if (region.childs.length) {
          region.childs.forEach(city => {
            if (city.name.toLowerCase().indexOf(filterValue) === 0 && region.hidden) {
              region.hidden = false;
            }
          });
        }
      }
    });
  }

}
