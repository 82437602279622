export enum CaselogProcedure {
  CREATE_CASE = 'CREATE_CASE',
  UPDATE_CASE = 'UPDATE_CASE',
  UPDATE_CASE_STATUS = 'UPDATE_CASE_STATUS',
  DELETE_CASE = 'DELETE_CASE',
  ADD_SLIDE = 'ADD_SLIDE',
  DELETE_SLIDE = 'DELETE_SLIDE',
  ADD_PATIENT_HISTORY = 'ADD_PATIENT_HISTORY',
  ADD_REQUEST = 'ADD_REQUEST',
  UPDATE_SLIDE = 'UPDATE_SLIDE',
  UPDATE_REQUEST = 'UPDATE_REQUEST',
  CHOOSE_PATHOLOGIEST = 'CHOOSE_PATHOLOGIEST',
  DELETE_PATHOLOGIEST = 'DELETE_PATHOLOGIEST',
  CHANGE_PATHOLOGIST_STATUS = 'CHANGE_PATHOLOGIST_STATUS',
  SECOND_OPINION_REQUEST = 'SECOND_OPINION_REQUEST',
  SHOW_HIDE_SLIDE = 'SHOW_HIDE_SLIDE',
  SUPERVISOR_APPROVED_SENT_BACK = 'SUPERVISOR_APPROVED_SENT_BACK',
  SUPERVISOR_APPROVED_SLIDES = 'SUPERVISOR_APPROVED_SLIDES',
  TECHNICIAN_SUBMITTED_SLIDES_SINGLE = 'TECHNICIAN_SUBMITTED_SLIDES_SINGLE',
  UPDATES_SUBMITTED = 'UPDATES_SUBMITTED',
  ADD_OTHER_SLIDE = 'ADD_OTHER_SLIDE',
  SENT_BACK = 'SENT_BACK',
  CASE_HAS_REJECTED_REQUEST = 'CASE_HAS_REJECTED_REQUEST',
  CASE_SUBMITTED = 'CASE_SUBMITTED',
  SupervisorRejectCaseDetailsCase = 'SUPERVISOR_REJECT_CASE_DETAILS_CASE',
  CASE_REASSESSMENT = 'CASE_REASSESSMENT',
  REJECTED_PASS_PATHOLOGISTS = 'REJECTED_PASS_PATHOLOGISTS',
  UPDATE_ENDORSEMENT_STATUS = 'UPDATE_ENDORSEMENT_STATUS',
  ENDORSEMENT_AGREED = 'ENDORSEMENT_AGREED',
  ENDORSEMENT_NOT_AGREED = 'ENDORSEMENT_NOT_AGREED',
  ENDORSEMENT_SUBMITTED = 'ENDORSEMENT_SUBMITTED',
  ENDORSEMENT_REJECTED = 'ENDORSEMENT_REJECTED',
  ENDORSEMENT_WITHDRAW = 'ENDORSEMENT_WITHDRAW',
  REOPENED = 'REOPENED',
  CLOSED = 'CLOSED',
  UPDATE_SPECIMEN_SECTION = 'UPDATE_SPECIMEN_SECTION',
  REMOVE_SLIDE_SECTION = 'REMOVE_SLIDE_SECTION',
  EDIT_SECOND_OPINION_REQUEST = 'EDIT_SECOND_OPINION_REQUEST',
  UPDATE_GROSS = 'UPDATE_GROSS',
  UPDATE_CASE_DETAILS = 'UPDATE_CASE_DETAILS',
  TECHNOLOGIST_SENT_BACK_GROSSING = 'TECHNOLOGIST_SENT_BACK_GROSSING',
  REJECT_GROSSING = 'REJECT_GROSSING',
}
