import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'timezonepipe' })

export class Timezonepipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'yyyy-MM-dd'): string {
    date = new Date(date);  // if orginal type was a string
    console.log(new Date(date).toLocaleTimeString())
    const offset = new Date(new Date().toLocaleString()).getTimezoneOffset();
    if (offset > 0) {
      date = new Date(date.getTime() + (offset * (1000 * 60)));
    } else if (offset < 0) {
      date = new Date(date.getTime() - (Math.abs(offset) * (1000 * 60)));
    }

    return new DatePipe(localStorage.getItem('language') || 'en').transform(date, format);
  }
}
