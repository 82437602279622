import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TissuesService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getAll(filter, isEntity): any {
    if (isEntity) {
      return this.http.get(
        `${environment.apiUrl}/TissueTypeConfiguration/GetAllTissueTypeForEntity`,
        {
          params: filter,
        }
      );
    } else {
      return this.http.get(
        `${environment.apiUrl}/TissueTypeConfiguration/GetAllTissueType`,
        {
          params: filter,
        }
      );
    }
  }

  saveRecord(data , isEntity) {
    if (data.tissueID) {
      if (isEntity) {
        return this.http.put(
          `${environment.apiUrl}/TissueTypeConfiguration/AddEntitTissuesTypesConfigurationForEntity`,
          data
        );
      } else {
        return this.http.put(
          `${environment.apiUrl}/TissueTypeConfiguration/UpdateTissueType`,
          data
        );
      }
    } else {
      return this.http.post(
        `${environment.apiUrl}/TissueTypeConfiguration/AddTissueType`,
        data
      );
    }
  }

  deleteRecord(id) {
    return this.http.delete(
      `${environment.apiUrl}/TissueTypeConfiguration/DeleteTissueType?TissueTypeID=${id}`
    );
  }

  getSpecialties() {
    return this.http.get(`${environment.apiUrl}/Lookups/ListSpecialities`);
  }

  getSubspecialties() {
    return this.http.get(
      `${environment.apiUrl}/TissueTypeConfiguration/GetSubSpecilities`
    );
  }

  getListAntiBodies(id = null , IsFromCase = false) {
    if (id) {
      return this.http.get(
        `${environment.apiUrl}/Lookups/ListAntiBodies?TissueTypeId=${id}&IsFromCase=${IsFromCase}`
      );
    } else {
      return this.http.get(`${environment.apiUrl}/Lookups/ListAntiBodies`);
    }
  }
  subSpecialityHasOther(params) {
    return this.http.get(
      `${environment.apiUrl}/TissueTypeConfiguration/IsSubSpecialityHasOther`,
      { params }
    );
  }
}
