import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DChart } from 'src/app/models/dashboard/DChart';

@Component({
  selector: 'app-chart-statistic',
  templateUrl: './chart-statistic.component.html'
})
export class ChartStatisticComponent implements OnInit {
  @Input() values: any;
  @Input() title: string;
  @Input() legend: boolean;
  @Input() showYAxis: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() type = 'bar';
  @Input() isDoughnut: boolean;
  @Input() colorScheme: any;
  @Input() key: string;
  @Input() angleSpan: number;
  @Input() startAngle: number;
  @Input() min: number;
  @Input() max: number;
  @Input() bigSegments: number;
  @Output() filterChart = new EventEmitter<any>();
  @Input() units: string = '';
  @Input() customClass: string = '';

  multi: any[];

  view: any[] = [];

  // options
  showXAxis = true;
  gradient = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  legendPosition = 'below';
  legendTitle = '';
  showLabels = true;

  constructor(private router: Router) {
    Object.assign(this, { ...this.values });
  }

  ngOnInit() {
  }

  onSelect(event) {
    switch (this.key) {
      case 'date':
        const now = new Date(Date.parse(event.extra.filter + ' 1,' + new Date().getFullYear()));

        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString();

        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString();

        this.filterChart.emit({ dateFrom: firstDay, dateTo: lastDay });
        return;
      case 'TissueId':
        this.filterChart.emit({ TissuesId: event.extra.filter.id, MalignancyId: event.extra.filter.milignantId });
        return;
      case 'Status':
        this.filterChart.emit({ Status: event.extra.filter });
        return;
      case 'Diagnoses':
        this.filterChart.emit({
          Diagnoses: event.extra.filter.id,
          DiagnosesNames: event.extra.filter.name,
          isOther: event.extra.filter.isOther,
          MalignancyId: event.extra.filter.milignantId
        });
        return;
      case 'MalignancyId':
        this.filterChart.emit({ MalignancyId: event.extra.filter });
        return;
      case 'Pathologists':
        this.filterChart.emit({ Pathologists: event.extra.filter });
        return;
      case 'SubSpeciality':
        this.filterChart.emit({ SubSpeciality: event.extra.filter });
        return;
      default:
        return false;
    }
  }

  formatDataLabel(value) {
    const self: any = this; // this "this" will refer to the chart component (pun intented :))
    const item = self.series.filter(data => data.name === value);
    if (item.length > 0) {
      return item[0].extra?.label;
    }
    return value;
  }

  onResize(event) {
    this.view = [event.target.innerWidth, 400];
  }

}
