import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { GlobalFilter } from '../global/GlobalFilter';
import { statusList } from '../sla/enums/statusList';
import { sortDataList } from './sortDataList';
export class ListDataFilter extends GlobalFilter {
  orderBy: sortDataList;
  LabName: string;
  caseIdType: CaseType;
  CaseIds: number[];
  LabType: string;
  CountryId: string;
  RegionId: string;
  CityId: string;
  SlaStatus: statusList;
  EntityId: string;
  EntityType: string;
  IsDiagnoseOnly: boolean;
  forGrossing: boolean;
}
