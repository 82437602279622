<div class="card_info_profile title-has-btns revert">
  <div class="card_info_profile_content">
    <div class="card_info_profile_title">
      <h4>
        Concerned Staff
      </h4>
    </div>

    <div class="card_info_profile_body">

      <div class="page">

        <div class="" *ngIf="concernedStaff?.length; else dataEmpty">

          <table class="table table_list_users">
            <thead>
              <tr>
                <th width="50%">Name / Role</th>
                <th class="text-right">Involved on</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of concernedStaff">
                <td>
                  <div class="blog_user_item">
                    <div class="img-circle">
                      <div class="image m-0">
                        <img *ngIf="record.image" [src]="record.image" (error)='record.image = ""'>
                        <span *ngIf="!record.image">{{ record.name | shortName }}</span>
                      </div>
                    </div>
                    <div class="name">
                      <h5>{{record.name}} <span *ngIf="record.isYou" class="flag">(You)</span></h5>
                      <div *ngIf="record.roles.length">
                        <ng-container *ngFor="let item of record.roles; index as i">
                          <span>{{(i !== 0 ? ' ,' : '') + item}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  <button (click)="openChatRoom(record)" mat-button color="primary" *ngIf="accesChat(record)"
                    class="mr-4">
                    <svg width="20" height="20" viewBox="0 0 20 20" class="mr-2">
                      <path id="chat-icon"
                        d="M16410.586,8074.005a.587.587,0,0,1-.441-.972l1.535-1.754a6.454,6.454,0,0,1-1.68-4.286c0-3.823,3.719-6.934,8.242-6.934s8.242,3.11,8.242,6.934c0,4.136-4.316,7.012-7.656,7.012Zm10.039-7.109a1.172,1.172,0,1,0,1.172-1.172A1.173,1.173,0,0,0,16420.625,8066.9Zm-3.555,0a1.172,1.172,0,1,0,1.172-1.172A1.173,1.173,0,0,0,16417.07,8066.9Zm-3.555,0a1.172,1.172,0,1,0,1.172-1.172A1.173,1.173,0,0,0,16413.516,8066.9Zm14.135,0c-.062-4.424-4.277-8.008-9.408-8.008a10.841,10.841,0,0,0-3.453.564c.568-3.08,3.795-5.447,7.594-5.447,4.2,0,7.617,2.891,7.617,6.445a5.823,5.823,0,0,1-1.521,3.868l1.379,1.609a.586.586,0,0,1-.443.968Z"
                        transform="translate(-16410 -8054.006)" fill="#fff" opacity="0.85" />
                    </svg>Chat
                  </button>
                  <span>{{record.involvedOn | date:'d MMM, y, h:mm a'}}</span>
                </td>
              </tr>
            </tbody>

          </table>
        </div>

        <ng-template #dataEmpty>
          <app-empty-state [img]='"./assets/images/illustration-reading-list.svg"' [desc]='"No Concerned Staff yet"'>
          </app-empty-state>
        </ng-template>
      </div>

    </div>
  </div>
</div>

<app-chat [caseIdType]="caseIdType"></app-chat>