<div class="data-empty {{custClass}}">

  <ng-lottie *ngIf="!img" [options]="options"></ng-lottie>

  <img [src]="img" *ngIf="img" alt="">

  <h3 *ngIf="title">{{title}}</h3>
  <p *ngIf="desc">{{desc}}</p>
  <div class="d-flex justify-content-center">
    <ng-content select="[actions]"></ng-content>
    <a *ngIf="linkBoolean" mat-raised-button color="primary" (click)="evEmit()">
      {{link}}
    </a>
  </div>
</div>
