import { Component, OnInit } from '@angular/core';
import { ListData, PagedData } from 'src/app/models/slides/ListData';
import { ListFilter } from 'src/app/models/slides/ListFilter';
import { SlidesService } from 'src/app/services/slides.service';
import { environment } from 'src/environments/environment';
import { ViewerSelectorComponent } from '../viewer-selector/viewer-selector.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-slides-list-shared',
  templateUrl: './slides-list-shared.component.html'
})
export class SlidesListSharedComponent implements OnInit {
  dataLoading = false;
  // table
  pageCount = 0;
  pageIndex = 0;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  caseNumber: string;
  patientName: string;

  constructor(public dialog: MatDialog, private service: SlidesService) { }

  ngOnInit() {
    this.getAll(null);
  }

  getAll(event) {
    this.filterModel.PageSize = 10;
    this.dataLoading = true;
    if (event) {
      this.pageIndex = event.pageIndex;
      this.filterModel.PageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
    this.filterModel.PageNumber = this.pageIndex + 1;
    this.filterModel.CaseId = environment.sharedCaseId;
    this.filterModel.caseIdType = environment.sharedCaseIdType;
    this.service.getAll(this.filterModel, true).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.caseNumber = this.recordsData.caseNumber;
          this.patientName = this.recordsData.patientName;
          this.pageCount = Math.ceil(this.recordsData.casesCount / this.filterModel.PageSize);
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  openViewerSelector() {
    this.dialog.open(ViewerSelectorComponent, {
      width: '600px',
      data: {
        caseId: this.filterModel.CaseId,
        entityId: this.filterModel.CaseId,
        caseIdType: this.filterModel.caseIdType,
        notAuthenticated: true
      }
    });
  }

}
