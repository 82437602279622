 import { SecondOpinionForm } from 'src/app/models/cases/SecondOpinionForm';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatchMakingService {
  selectRecordSecondOpinion = new BehaviorSubject<SecondOpinionForm>(null);
  dataHolder = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  getCasePathologists(params) {
    return this.http.get(`${environment.apiUrl}/MatchMaking/GetCasePathologists`, { params });
  }

  assignPathologiests(pathologiests) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/AssignPathologiests`, pathologiests);
  }

  deleteRecord(obj) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/DeleteAssignedPathologiests`, obj);
  }

  changePathologistStatus(obj) {
    return this.http.put(`${environment.apiUrl}/MatchMaking/ChangePathologistStatus`, obj);
  }
  assignPathologist(params , data) {
    if (data.forAssignEndorsement) {
      return this.http.get(`${environment.apiUrl}/MatchMaking/EndorsementPathologists`, { params });
    } else if (data.forAssignSecondOpinion) {
      return this.http.get(`${environment.apiUrl}/MatchMaking/SecondOpinionPathologists`, { params });
    }
  }

  requestSecondOpinion(obj) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/RequestSecondOpinion`, obj);
  }

  editSecondOpinionRequest(obj) {
    return this.http.put(`${environment.apiUrl}/MatchMaking/EditSecondOpinionRequest`, obj);
  }

  supervisorApprovedPathologists(obj) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/SupervisorApprovedPathologists`, obj);
  }

  getApprovedPathologists(params) {
    return this.http.get(`${environment.apiUrl}/MatchMaking/GetApprovedPathologists`, { params });
  }

  editEndorsementStatus(obj) {
    return this.http.put(`${environment.apiUrl}/MatchMaking/EditEndorsementStatus`, obj);
  }

  requestReportOTP(params) {
    return this.http.get(`${environment.apiUrl}/MatchMaking/RequestReportOTP`, { params });
  }

  verfiyOTP(obj) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/VerfiyOTP`, obj);
  }

  requestGrossing(pathologiests) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/RequestGrossing`, pathologiests);
  }

  deleteGrossingPathologiests(pathologiests) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/DeleteGrossingPathologiests`, pathologiests);
  }

  GetCaseTechnologists(params) {
    return this.http.get(`${environment.apiUrl}/MatchMaking/GetCaseTechnologists`, { params });
  }

  SubmitGrossing(data) {
    return this.http.post(`${environment.apiUrl}/MatchMaking/SubmitGrossing`, data);
  }
}
