import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ViewerSlide } from '../features/viewer/viewer.component';
import OpenSeadragon from 'openseadragon';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(private http: HttpClient) { }

  GetSlideView(params: any): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetSlideView`, { params });
  }

  saveAnnotations(data): any {
    return this.http.post(`${environment.apiUrl}/Viewer/AddSlideRemarks`, data);
  }

  // Comments
  getComments(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetSlideViewComments`, { params });
  }
  saveComment(data) {
    if (data.commentId) {
      return this.http.put(`${environment.apiUrl}/Viewer/EditSlideComment`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/Viewer/AddSlideComment`, data);
    }
  }
  deleteComment(id) {
    return this.http.delete(`${environment.apiUrl}/Viewer/DeleteSlideComment?CommentId=${id}`);
  }

  // Share
  ShareSlide(data, prod = false): any {
    if (prod && data.slideId) {
      return this.http.post(`${environment.apiUrl}/Viewer/ShareSlidetoProduction`, data);
    } else {
      if (data.slideId) {
        return this.http.post(`${environment.apiUrl}/Viewer/ShareSlide`, data);
      } else {
        return this.http.post(`${environment.apiUrl}/Viewer/ShareSlideOutSource`, data);
      }
    }
  }
  ValidateShareSlideUrl(params) {
    return this.http.get(`${environment.apiUrl}/Viewer/ValidateShareSlideUrl`, { params });
  }
  ValidateMobileNumber(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/ValidateMobileNumber`, { params });
  }
  ValidateOtp(params) {
    return this.http.get(`${environment.apiUrl}/Viewer/ValidateOtp`, { params });
  }
  GetSlideViewOutSource(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetSlideViewOutSource`, { params });
  }
  getSlideViewAnn(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetSlideViewAnn`, { params });
  }
  GetSlideViewCommentsOutSource(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetSlideViewCommentsOutSource`, { params });
  }

  AddROISnapshot(obj) {
    return this.http.post(`${environment.apiUrl}/Snapshot/AddROISnapshot`, obj);
  }

  getAllSnapshotsForCaseMatchMaking(params): any {
    return this.http.get(`${environment.apiUrl}/Snapshot/GetAllSnapshotsForCaseMatchMaking`, { params });
  }

  getListContainerSpecimensImages(params): any {
    return this.http.get(`${environment.apiUrl}/Snapshot/ListContainerSpecimensImages`, { params });
  }

  getAllSmallerSlide(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetAllSmallerSlide`, { params });
  }

  getAllAssignedSlides(params): any {
    return this.http.get(`${environment.apiUrl}/Viewer/GetAllAssignedSlides`, { params });
  }

  convertRectangleCordinates(cordinatesString: string, repeat = false) {
    let splitstring = cordinatesString.split(":");
    let stringArray = splitstring[1].split(",");
    let cordinate = [];
    stringArray.forEach(cordinte => {
      cordinate.push(parseInt(cordinte));
    });
    let x1 = cordinate[0];
    let y1 = cordinate[1];
    let width = cordinate[2];
    let height = cordinate[3];
    let x2 = width + x1;
    let y2 = height + y1;
    let rectanglePoits = [[x1, y1], [x2, y2]];
    let coordinates = [];
    let cordinatesSaveInDatabase = [rectanglePoits[0], [rectanglePoits[1][0], rectanglePoits[0][1]], rectanglePoits[1], [rectanglePoits[0][0], rectanglePoits[1][1]]];
    cordinatesSaveInDatabase.forEach((coordinate) => {
      coordinates.push([coordinate[0], coordinate[1]]);
    })
    if (repeat) { coordinates.push(coordinates[0]); }
    return coordinates;
  }

  convertPolygonToCordinates(cordinatestring: string, repeat = false) {
    let cordinatesString = [];
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\"") + 1,
      cordinatestring.lastIndexOf("\"")
    );
    let splitThird = SubString.split(" ");
    splitThird.forEach((splitdata) => {
      let splitcordi = splitdata.split(",");
      cordinatesString.push([parseInt(splitcordi[0]), parseInt(splitcordi[1])]);
    });
    if (repeat) { cordinatesString.push(cordinatesString[0]); }
    return cordinatesString;
  }

  convertCircleToCordinates(cordinatestring: string, repeat = false) {
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\""),
      cordinatestring.lastIndexOf("\"") + 1
    ).split(" ").map(item => item.substring(
      item.indexOf("\"") + 1,
      item.lastIndexOf("\"")
    ));

    const centerX = Number(SubString[0]);
    const centerY = Number(SubString[1]);
    const radius = Number(SubString[2]);

    // Define the number of sides for the polygon approximation
    const numSides = 100; // Adjust this value to control the smoothness of the resulting polygon

    // Calculate the angle between each side of the polygon
    const angleStep = (1 * Math.PI) / (numSides / 2);
    // Create an array to store the polygon vertices
    const polygonVertices = [];

    // Generate the vertices of the polygon by evenly distributing them around the circle
    for (let i = 0; i < numSides; i++) {
      const angle = i * angleStep;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      polygonVertices.push([x, y]);
    }
    if (repeat) { polygonVertices.push(polygonVertices[0]); }
    return polygonVertices;
  }

  convertEllipseToCordinates(cordinatestring: string, repeat = false) {
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\""),
      cordinatestring.lastIndexOf("\"") + 1
    ).split(" ").map(item => item.substring(
      item.indexOf("\"") + 1,
      item.lastIndexOf("\"")
    ));

    const centerX = Number(SubString[0]);
    const centerY = Number(SubString[1]);
    const radiusX = Number(SubString[2]);
    const radiusY = Number(SubString[3]);

    // Define the number of sides for the polygon approximation
    const numSides = 100; // Adjust this value to control the smoothness of the resulting polygon

    // Calculate the angle between each side of the polygon
    const angleStep = (1 * Math.PI) / (numSides / 2);
    // Create an array to store the polygon vertices
    const polygonVertices = [];

    // Generate the vertices of the polygon by evenly distributing them around the circle
    for (let i = 0; i < numSides; i++) {
      const angle = i * angleStep;
      const x = centerX + radiusX * Math.cos(angle);
      const y = centerY + radiusY * Math.sin(angle);
      polygonVertices.push([x, y]);
    }
    if (repeat) { polygonVertices.push(polygonVertices[0]); }
    return polygonVertices;
  }

  convertSvgPathToPolygon(svgPath, repeat = false) {
    const pathParser = new DOMParser();
    const pathDoc = pathParser.parseFromString(svgPath, 'image/svg+xml');
    const pathElement = pathDoc.querySelector('path');
    const pathData = pathElement.getAttribute('d');

    // Extract the coordinates from the parsed path data
    const coordinates = [];
    const pathSegments = pathData.split(/[A-Za-z]/).filter(Boolean);
    pathSegments.forEach(function (segment) {
      const segmentCoordinates = segment.trim().split(/[ ,]+/);
      const x = parseFloat(segmentCoordinates[segmentCoordinates.length - 2]);
      const y = parseFloat(segmentCoordinates[segmentCoordinates.length - 1]);
      coordinates.push([x, y]);
    });

    if (repeat) { coordinates.push(coordinates[0]); }

    return coordinates;
  }


  getPointsAnnotation(annotation, repeat = false) {
    let points = [];
    if (annotation.target.selector.value.includes("xywh")) {
      points = this.convertRectangleCordinates(annotation.target.selector.value, repeat);
    }
    else if (annotation.target.selector.value.includes("polygon")) {
      points = this.convertPolygonToCordinates(annotation.target.selector.value, repeat);
    }
    else if (annotation.target.selector.value.includes("circle")) {
      points = this.convertCircleToCordinates(annotation.target.selector.value, repeat);
    }
    else if (annotation.target.selector.value.includes("ellipse")) {
      points = this.convertEllipseToCordinates(annotation.target.selector.value, repeat);
    }
    else if (annotation.target.selector.value.includes("path")) {
      points = this.convertSvgPathToPolygon(annotation.target.selector.value, repeat);
    }
    return points;
  }


  calculatePolygonBounds(points, calcZoom = true, slide: ViewerSlide) {
    let minX = Math.min(...points.map(p => p[0]));
    let minY = Math.min(...points.map(p => p[1]));
    let maxX = Math.max(...points.map(p => p[0]));
    let maxY = Math.max(...points.map(p => p[1]));

    if (calcZoom) {
      const zoomChanged = slide.imageDefaultZoom == slide.imageZoom ? (slide.imageZoom * 2) : slide.imageZoom;
      const zoomx = ((zoomChanged / 100) * (maxX - minX));
      const zoomy = ((zoomChanged / 100) * (maxY - minY));

      if ((minX - zoomx) > 0) {
        minX -= zoomx;
        minY -= zoomy;
        maxX += (zoomx * 2);
        maxY += (zoomy * 2);
      }
    }
    return [
      minX,
      minY,
      maxX - minX,
      maxY - minY
    ]
  }

  /**
 * Cuts the selected image snippet from the OpenSeadragon CANVAS element.
 */
  getSnippet(viewer, container, xi, yi, wi, hi) {
    // Current image zoom from OSD
    const imageZoom = viewer.viewport.viewportToImageZoom(viewer.viewport.getZoom());
    console.log(imageZoom)
    // Scale factor for OSD canvas element (physical vs. logical resolution)
    const { canvas } = viewer.drawer;
    const canvasBounds = canvas.getBoundingClientRect();
    const kx = canvas.width / canvasBounds.width;
    const ky = canvas.height / canvasBounds.height;

    // Convert image coordinates (=annotation) to viewport coordinates (=OpenSeadragon canvas)
    const topLeft = viewer.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(xi, yi));
    const bottomRight = viewer.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(xi + wi, yi + hi));

    const { x, y } = topLeft;
    const w = bottomRight.x - x;
    const h = bottomRight.y - y;

    // const w = x + (wi / kx) / imageZoom;
    // const h = y + (hi / ky) / imageZoom;
    const width = (w * kx) + 2;
    const height = (h * ky) + 2;
    // Cut out the image snippet as in-memory canvas element
    const snippet = document.createElement('CANVAS') as HTMLCanvasElement;
    const ctx = snippet.getContext('2d');
    snippet.width = width;
    snippet.height = height;
    // const px = x + (x / kx) / imageZoom;
    // const py = y + (y / ky) / imageZoom;
    // console.log(px, py)
    ctx.drawImage(container, x * kx, y * ky, width, height, 0, 0, width, height);
    const jpegImage = snippet.toDataURL("image/jpeg");
    // Return snippet canvas + basic properties useful for downstream coord translation
    return { jpegImage, kx, ky, x: xi, y: yi };
  }

  captureAnnotation(annotation, canvas, slide: ViewerSlide, calcZoom = true) {
    const [xi, yi, wi, hi] = this.calculatePolygonBounds(this.getPointsAnnotation(annotation, true), calcZoom, slide);
    // console.log(xi, yi, wi, hi)
    const { jpegImage, x, y, kx, ky } = this.getSnippet(slide.viewer, canvas, xi, yi, wi, hi);
    return jpegImage;
  }
}
