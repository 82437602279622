import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-user-type',
  templateUrl: './select-user-type.component.html',
  styles: [
  ]
})
export class SelectUserTypeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectUserTypeComponent>,
  ) { }

  ngOnInit(): void {
  }

  select(type) {
    this.dialogRef.close(type);
  }
}
