export enum ActionEnum {
  SUBMIT_SLIDE_REF = 'SUBMIT_SLIDE_REF',
  TECHNOLOGIST_SENT_BACK_GROSSING = 'TECHNOLOGIST_SENT_BACK_GROSSING',
  TISSUE_PROCESSING = 'TISSUE_PROCESSING',
  TISSUE_EMBEDDING = 'TISSUE_EMBEDDING',
  SLIDES_SECTIONING = 'SLIDES_SECTIONING',
  SLIDES_STAINING = 'SLIDES_STAINING',
  TECH_ACCEPT_GROSSING = 'TECH_ACCEPT_GROSSING',
  TECH_CHANGE_DECISION = 'TECH_CHANGE_DECISION',
  SHOW_DEFINED_SLIDES_NUMBER = 'SHOW_DEFINED_SLIDES_NUMBER',
  ADD_SLIDE_REF = 'ADD_SLIDE_REF'
}
