<cdk-tree *ngIf="TREE_DATA.length" [dataSource]="treeDataSource" [treeControl]="treeControl">
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="tree-node" [hidden]="node.hidden">
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node" *ngIf="!isView"
      [checked]="treeSelection.isSelected(node) || isInArray(selectedItems, node)"
      (change)="treeSelection.toggle(node); selected(node);">{{node.name}}</mat-checkbox>
    <span *ngIf="isView">{{node.name}}</span>
  </cdk-nested-tree-node>
  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="tree-node" [hidden]="node.hidden">
    <button mat-icon-button type="button" [attr.aria-label]="'toggle ' + node.name" cdkTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox *ngIf="!isView" [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node); selected(node);">
      {{node.name}}</mat-checkbox>
    <span *ngIf="isView">{{node.name}}</span>
    <div [class.tree-invisible]="!treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
