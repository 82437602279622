<div class="position-relative">
  <mat-form-field class="form-group" [hideRequiredMarker]='true'
    [class.mat-form-field-invalid]="!selectedRecords.length && isSubmitted" (click)="clickout(null)"
    [class.loading-field]="recordsLoading">
    <mat-label>Regions & Cities</mat-label>
    <mat-chip-list #chipRecordsList [required]="required">
      <mat-chip *ngFor="let node of selectedRecords; index as i;" [hidden]="i >= 5"
        (removed)="selectedRecords.splice(i, 1)">
        {{node.name}}
      </mat-chip>
      <div *ngIf="selectedRecords.length > 5" class="more-plus" (click)="showRecordsTree = true">
        +{{selectedRecords.length - 5}}</div>
      <input style="width: 1px;" autocomplete="off" matInput [matChipInputFor]="chipRecordsList">
    </mat-chip-list>
    <mat-icon matSuffix>{{showRecordsTree ? 'expand_less' : 'expand_more'}}</mat-icon>
    <p class="has-error" [class.active]="!selectedRecords.length && isSubmitted">
      {{ 'forms.Required' | translate }}</p>
  </mat-form-field>

  <div class="tree-dropdown" *ngIf="recordsTree.length" [hidden]="!showRecordsTree">
    <input class="show py-2 w-100 border-bottom mx-0" placeholder="Search..." #searchInput [formControl]="searchCtrl"
      autocomplete="off" [matChipInputFor]="chipRecordsList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur" (keyup)="filterTree(searchInput.value,true)">
    <app-tree-selection [TREE_DATA]='recordsTree' [isView]="false" [formField]='true'
      [selectedInput]='detailsSelectedRecords' (SelectedItems)="updateSelectedRecords($event);">
    </app-tree-selection>
  </div>
  <!-- <div id="popOverlay" *ngIf="showRecordsTree"></div> -->
</div>
