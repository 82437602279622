export class GlobalFilter {
  PageSize: number;
  PageNumber: number;
  pageNumber: number;
  pageSize: number;

  DateFrom: string;
  DateTo: string;
  dateFrom: string;
  dateTo: string;
}
