import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { statusList } from 'src/app/models/cases/enums/statusList';

export interface CaseLogData {
  name: string;
  message: string;
  status: statusList;
  procedure: string;
  comment: string;
  image: string;
  creationDate: Date;
}

@Component({
  selector: 'app-case-log',
  templateUrl: './case-log.component.html'
})
export class CaseLogComponent implements OnInit {
  caseId: number;
  @Input() recordsData: CaseLogData[] = [];
  dataLoading = false;
  openCaseLog = false;
  selectedRecord: any;
  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() type: string; // request or case

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {

  }

  showCaseLog(record) {
    this.selectedRecord = record;
    this.openCaseLog = true;
  }

  closeAll(e) {
    this.openCaseLog = false;
    this.closeDetails.emit();
  }

  closeSidenavCaseLog(e) {
    this.openCaseLog = false;
  }


}
