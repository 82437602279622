export enum ActionType {
  SLIDES_DELIVERIED = 'SLIDES_DELIVERIED',
  SLIDES_PICKEDUP = 'SLIDES_PICKEDUP',
  SLIDES_RECIEVED = 'SLIDES_RECIEVED',
  CONTAINER_DELIVERIED = 'CONTAINER_DELIVERIED',
  CONTAINER_PICKEDUP = 'CONTAINER_PICKEDUP',
  CONTAINER_RECIEVED = 'CONTAINER_RECIEVED',
  ADD_CONTAINER_QR = 'ADD_CONTAINER_QR',
  SCAN_LIST = 'SCAN_LIST'
}

