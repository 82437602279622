import { ModelsEnum } from './../models/users/users/enums/ModelsEnum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  refreshUsersList = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ListUsers`, { params: filter });
  }
  getAllInProfiles(filter, model): any {
    if (model === ModelsEnum.Lab) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListLabUsersById`, { params: filter });
    } else if (model === ModelsEnum.ScanningCenter) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListScanningCenterUsersById`, { params: filter });
    } else if (model === ModelsEnum.Hospital) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListEntityUsersById`, { params: filter });
    } else if (model === ModelsEnum.Clinic) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListEntityUsersById`, { params: filter });
    }
  }
  getLabAndVlLabList() {
    return this.http.get(`${environment.apiUrl}/ReportTemplate/GetLabList`);
  }
  getDetails(modal, userInfo, pathologistLabType: any = null, entityId: any = null) {
    let userId = null;
    if (userInfo) {
      if (userInfo.labUserId) {
        userId = userInfo.labUserId;
      } else if (userInfo.pathologistId) {
        userId = userInfo.pathologistId;
      }
    }
    // const userId = userInfo.labUserId ? userInfo.labUserId : userInfo.pathologistId;
    const queryStr =
      ((userId) ? `?UserId=${userId}` : '') +
      ((modal === ModelsEnum.hospitalUser || modal === ModelsEnum.Hospital) ? `&UserType=${ModelsEnum.hospitalUser}` : '') +
      ((modal === ModelsEnum.clinicUser || modal === ModelsEnum.Clinic) ? `&UserType=${ModelsEnum.clinicUser}` : '') +
      ((pathologistLabType) ? `&LabType=${pathologistLabType}` : '') +
      ((entityId) ? `&LabId=${entityId}` : '');

    if (modal === ModelsEnum.LabUser || modal === ModelsEnum.Lab) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetLabUserDetails${queryStr}`);
    } else if (modal === ModelsEnum.SCUser || modal === ModelsEnum.ScanningCenter) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetScanningCenterUserDetails${queryStr}`);
    } else if (modal === ModelsEnum.VLUser || modal === ModelsEnum.VL) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetVirtualLabUserDetails${queryStr}`);
    } else if (modal === ModelsEnum.hospitalUser || modal === ModelsEnum.Hospital) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetEntityUserDetails${queryStr}`);
    } else if (modal === ModelsEnum.clinicUser || modal === ModelsEnum.Clinic) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetEntityUserDetails${queryStr}`);
    } else if (modal === 'PATHOLOGIST_USER' || modal === 'PATHOLOGIST_REQUEST') {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetPathologistUserDetails${queryStr}`);
    }
  }

  getPathologistDetails(params) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetPathologistUserDetails`, { params });
  }

  saveUser(modal, user) {
    if (user.userId) {
      return this.updateUser(modal, user);
    } else {
      return this.addUser(modal, user);
    }
  }

  ActiveUser(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ActivateUser`, {
      params: filter
    });
  }

  DeactivateUser(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/DeactivateUser`, {
      params: filter
    });
  }

  takeActionForPathologistRequest(data) {
    return this.http.post(`${environment.apiUrl}/Pathologist/ProceesPathologistRequest`, data);
  }


  ResendActivationMail(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ResendActivationMail`, {
      params: filter
    });
  }

  ResendResetPasswordMail(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/ResendResetPasswordMail`, {
      params: filter
    });
  }

  getReportToUsers(model) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetReportToUsers`, {
      params: model
    });
    // if (model === ModelsEnum.VLUser) {
    //   return this.http.get(`${environment.apiUrl}/ManageUsers/GetVirtualLabReportToUsers`, {
    //     params: filter
    //   });
    // } else {
    //   return this.http.get(`${environment.apiUrl}/ManageUsers/GetReportToUsers`, {
    //     params: filter
    //   });
    // }
  }

  deleteUser(query) {
    return this.http.delete(`${environment.apiUrl}/ManageUsers/DeleteUser`, {
      params: query
    });
  }

  addUser(modal, user) {
    if (modal === ModelsEnum.LabUser || modal === ModelsEnum.Lab) {
      return this.http.post(`${environment.apiUrl}/ManageUsers/CreateLabUser`, user);
    } else if (modal === ModelsEnum.VLUser || modal === ModelsEnum.VL) {
      return this.http.post(`${environment.apiUrl}/ManageUsers/CreateVirtualLabUser`, user);
    } else if (modal === ModelsEnum.SCUser || modal === ModelsEnum.ScanningCenter) {
      return this.http.post(`${environment.apiUrl}/ManageUsers/CreateScanningCenterUser`, user);
    } else if (modal === ModelsEnum.Hospital || modal === ModelsEnum.hospitalUser || modal === ModelsEnum.Clinic || modal === ModelsEnum.clinicUser) {
      return this.http.post(`${environment.apiUrl}/ManageUsers/CreateEntityUser`, user);
    } else if (modal === 'PATHOLOGIST_USER') {
      return this.http.post(`${environment.apiUrl}/ManageUsers/CreatePathologistUser`, user);
    }
  }

  updateUser(modal, user) {
    if (modal === ModelsEnum.LabUser || modal === ModelsEnum.Lab) {
      return this.http.put(`${environment.apiUrl}/ManageUsers/UpdateLabUser`, user);
    } else if (modal === ModelsEnum.VLUser || modal === ModelsEnum.VL) {
      return this.http.put(`${environment.apiUrl}/ManageUsers/UpdateVirtualLabUser`, user);
    } else if (modal === ModelsEnum.SCUser || modal === ModelsEnum.ScanningCenter) {
      return this.http.put(`${environment.apiUrl}/ManageUsers/UpdateScanningCenterUser`, user);
    } else if (modal === ModelsEnum.Hospital || modal === ModelsEnum.hospitalUser || modal === ModelsEnum.Clinic || modal === ModelsEnum.clinicUser) {
      return this.http.put(`${environment.apiUrl}/ManageUsers/UpdateEntityUser`, user);
    } else if (modal === 'PATHOLOGIST_USER') {
      return this.http.post(`${environment.apiUrl}/ManageUsers/UpdatePathologistUser`, user);
    }
  }

  getSCCoverageAreas(filter) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetScanningCenterCoverageAreas`, {
      params: filter
    });
  }

  getUserProfile() {
    return this.http.get(`${environment.apiUrl}/UserProfile/GetUserProfile`);
  }

  updateProfile(user) {
    return this.http.put(`${environment.apiUrl}/UserProfile/UpdateProfile`, user);
  }

  changeUserName(userName) {
    return this.http.put(`${environment.apiUrl}/UserProfile/ChangeUserName`, userName);
  }

  changePassword(password) {
    return this.http.put(`${environment.apiUrl}/UserProfile/ChangePassword`, password);
  }

  changeProfilePicture(avatar) {
    return this.http.put(`${environment.apiUrl}/UserProfile/ChangeProfilePicture`, avatar);
  }

  checkBeforeDeleteUser(params) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/CheckBeforeDeleteUser`, { params });
  }

  getSubspecialties() {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetSubSpecilities`);
  }

  searchForPathologiest(data) {
    return this.http.get(`${environment.apiUrl}/ManageUsers/SearchForPathologiest`, {
      params: data
    });
  }

  getListUserPreformanceIndicator(userType: ModelsEnum, params): any {
    if (userType === ModelsEnum.pathologistUser) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListPathologistPerformanceIndicator`, { params });
    } else {
      return this.http.get(`${environment.apiUrl}/ManageUsers/ListUserPreformanceIndicator`, { params });
    }
  }
}
