import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserDetails } from 'src/app/models/users/users/UserDetails';

import { UserPreformanceIndicator } from 'src/app/models/users/users/UserPreformanceIndicator';
import { StatisticsPeriod } from 'src/app/models/users/users/enums/statistics-period.enum';
import { UsersService } from 'src/app/services/users.service';
import { ListFilterUserPreformanceIndicator } from 'src/app/models/users/users/ListFilterUserPreformanceIndicator';
import { IndicatorActionName } from 'src/app/models/users/users/enums/indicatorActionName';

export interface DialogData {
  userId: number;
  entityType: string;
  user: UserDetails;
  actionsSelected: UserPreformanceIndicator[];
  filter: ListFilterUserPreformanceIndicator;
  title: string;
}

@Component({
  selector: 'app-user-performance-statistics',
  templateUrl: './user-performance-statistics.component.html'
})
export class UserPerformanceStatisticsComponent implements OnInit {
  formGroup: FormGroup;
  periodList = [
    { name: 'All time', value: StatisticsPeriod.ALL },
    { name: 'Today', value: StatisticsPeriod.TODAY },
    { name: 'Last 7 days', value: StatisticsPeriod.LAST_7_DAYS },
    { name: 'Last 30 days', value: StatisticsPeriod.LAST_30_DAYS },
    { name: 'Last 365 days', value: StatisticsPeriod.LAST_365_DAYS },
    { name: 'Custom range', value: StatisticsPeriod.CUSTOME }
  ];
  dataLoading = false;
  recordsData: UserPreformanceIndicator[] = [];
  actionsSelected: UserPreformanceIndicator[] = [];
  today = new Date();
  filterModel: ListFilterUserPreformanceIndicator = new ListFilterUserPreformanceIndicator();
  indicatorActionName = IndicatorActionName;
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UserPerformanceStatisticsComponent>,
    private formBuilder: FormBuilder,
    private service: UsersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.buildForm();
    if (this.data.actionsSelected && this.data.actionsSelected.length) {
      this.actionsSelected = this.data.actionsSelected;
    }
    if (this.data.filter) {
      this.bindToFilter();
    }
    this.getListUserPreformanceIndicator();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      timeRange: [StatisticsPeriod.ALL],
      dateFrom: [null],
      dateTo: [null]
    });
  }

  bindToFilter() {
    this.formGroup.controls.timeRange.setValue(this.data.filter.filter);
    if (this.data.filter.from) {
      this.formGroup.controls.dateFrom.setValue(new Date(this.data.filter.from));
    }
    if (this.data.filter.to) {
      this.formGroup.controls.dateTo.setValue(new Date(this.data.filter.to));
    }

  }

  getListUserPreformanceIndicator(event = false) {
    this.isSubmitted = false;
    this.dataLoading = true;
    this.filterModel = new ListFilterUserPreformanceIndicator();
    this.filterModel.Id = this.data.userId;
    this.filterModel.entityType = String(this.data.entityType).toUpperCase();
    this.filterModel.filter = this.formGroup.controls.timeRange.value;
    if (this.filterModel.filter === StatisticsPeriod.CUSTOME) {
      if (this.formGroup.controls.dateFrom.value) {
        this.filterModel.from = this.formGroup.controls.dateFrom.value.toLocaleDateString();
      }
      if (this.formGroup.controls.dateTo.value) {
        this.filterModel.to = this.formGroup.controls.dateTo.value.toLocaleDateString();
      }
    } else {
      this.formGroup.controls.dateFrom.setValue(null);
      this.formGroup.controls.dateTo.setValue(null);
    }
    this.service.getListUserPreformanceIndicator(this.data.user.userType, this.filterModel).subscribe(
      data => {
        this.recordsData = data as UserPreformanceIndicator[];
        if (!event && this.recordsData.length && !this.actionsSelected.length) {
          this.recordsData.map(item => {
            const record = {
              roleName: item.roleName,
              result: []
            };
            this.actionsSelected.push(record);
          })
        } else if (this.actionsSelected.length) {
          this.recordsData.forEach((item, i) => {
            item.result.map((record, index) => {
              if (this.isInArray(this.actionsSelected, i, record.actionName, index)) {
                const indexRecord = this.actionsSelected[i].result.findIndex(item => item.actionName === record.actionName && item.id === index)
                this.actionsSelected[i].result[indexRecord] = { id: index, ...record };
              }
            })
          })
        }
        this.dataLoading = false;
      }, err => {
        this.dataLoading = false;
        console.error(err);
      });
  }

  generate() {
    this.isSubmitted = true;
    if (this.checkSlectOneAction()) {
      const data = {
        actionsSelected: this.actionsSelected,
        user: this.data.user,
        filter: this.filterModel
      }
      localStorage.setItem('performanceIndicator', JSON.stringify(data));
      if (!this.data.actionsSelected) {
        this.router.navigate(['/admin/users/statistics-performance-indicator'], { queryParams: { directTo: this.router.url, title: this.data.title } });
      }
      this.dialogRef.close();
    }
  }

  selectAction(i: number, name: string, index: number) {
    const indexRecord = this.actionsSelected[i].result.findIndex(item => item.actionName === name && item.id === index);
    if (indexRecord > -1) {
      this.actionsSelected[i].result.splice(indexRecord, 1);
    } else {
      this.actionsSelected[i].result.push({ id: index, ...this.recordsData[i].result[index] });
    }
  }

  selectAll(e: MatCheckboxChange) {
    if (e.checked) {
      this.recordsData.map((item, index) => {
        this.actionsSelected[index].result = [...item.result.map((item, i) => item = { id: i, ...item })]
      });
    } else {
      this.actionsSelected.map(item => item.result = []);
    }
  }

  checkSelectdAll() {
    let selected = true;
    this.recordsData.map((item, index) => {
      if (item.result.length !== this.actionsSelected[index].result.length && selected) { selected = false }
    })
    return selected;
  }

  isInArray(parentObj, i, name: string, index: number) {
    return parentObj[i].result.some(obj => obj.actionName === name && obj.id === index);
  }


  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  resetDate(id) {
    this.formGroup.controls[id].setValue(null);
  }

  checkSlectOneAction() {
    let selected = false;
    this.actionsSelected.map(item => {
      if (item.result.length && !selected) { selected = true }
    })
    return selected;
  }
}
