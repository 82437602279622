<div mat-dialog-title>
  <h2 class="d-block" *ngIf="data.isShare">
    Share Slides
    <span class="ml-0 mt-1">(Select slides you want to share to Concurel)</span>
  </h2>
  <h2 class="d-block" *ngIf="!data.isShare && !data.isSimilarity">
    View Multiple Slides
    <span class="ml-0 mt-1">(Select slides you want to open)</span>
  </h2>
  <h2 class="d-block" *ngIf="data.isSimilarity">
    Similar Slides
    <span class="ml-0 mt-1">(Select slides you want to open)</span>
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="assign-dialog-content multiple_slides" *ngIf="slidesList.length; else dataEmpty"
  infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount"
  (scrolled)="getNextPage()">
  <div class="page">

    <div class="">

      <table class="table table-primary table_assign">
        <thead>
          <tr>
            <th width="1%"></th>
            <th *ngIf="!data.isSimilarity && !data.isCompare">Slide No.</th>
            <th *ngIf="data.isSimilarity || data.isCompare">Slide Name</th>
            <ng-container *ngIf="!data.isCompare">
              <th>Thumbnail</th>
              <th>Stain type</th>
              <th>Antibody</th>
            </ng-container>
            <ng-container *ngIf="data.isSimilarity">
              <th>Patient age & gender</th>
              <th>Case Date</th>
              <th>Clinical Info</th>
              <th></th>
              <th></th>
            </ng-container>

            <ng-container *ngIf="data.isCompare">
              <th>Subspecialty</th>
              <th>Tissue Type</th>
              <th>Stain Type</th>
              <th>Patient age & gender</th>
              <th>Tags</th>
            </ng-container>

          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let record of slidesList; let i = index">
            <tr (click)="selectedSlides(record.slideId)" [class.active]="isInArray(record.slideId)">
              <td>
                <mat-checkbox [checked]="isInArray(record.slideId)"
                  [disabled]="data.slideId == record.slideId && data.isCompare"></mat-checkbox>
              </td>
              <td *ngIf="!data.isSimilarity && !data.isCompare">
                #{{i + 1}}
              </td>
              <td *ngIf="data.isSimilarity">
                {{record.slideName}}
              </td>
              <ng-container *ngIf="!data.isCompare">
                <td>
                  <div class="img-circle">
                    <div class="image m-0 rounded">
                      <img *ngIf="record.thumbnail" [src]="record.thumbnail" (error)='record.thumbnail = ""'
                        class="rounded">
                      <img *ngIf="!record.thumbnail" src="assets/images/icons8-no-image-100.png" class="rounded">
                    </div>
                  </div>
                </td>
                <td>{{record.stainType.name}}</td>
                <td>
                  {{record.antibody?record.antibody:'__'}}
                </td>
              </ng-container>
              <ng-container *ngIf="data.isSimilarity">
                <td>{{record.patientAge}} years/{{record.patientGender}}</td>
                <td>{{record.caseDate | date:'d MMM, y, h:mm a'}}</td>
                <td [matTooltip]="record.clinicalInfo">
                  {{(record.clinicalInfo?record.clinicalInfo:'__') | truncateText:25}}
                </td>
                <td>
                  <a mat-button mat-dialog-close color="primary" class="btn_link_with_icon p-0"
                    [routerLink]="['/admin/cases/details', record.caseId,record.caseType]">
                    Case Info
                    <i class="material-icons">
                      launch
                    </i>
                  </a>
                </td>
                <td>
                  <a mat-button mat-dialog-close color="primary" class="btn_link_with_icon p-0"
                    [routerLink]="['/admin/cases/report/preview', record.caseId, record.reportId,record.caseType]"
                    [queryParams]="record.isForQuality?{isForQuality:true}:null">
                    View Report
                    <i class="material-icons">
                      launch
                    </i>
                  </a>
                </td>
              </ng-container>

              <ng-container *ngIf="data.isCompare">
                <td>
                  <div class="img-circle">
                    <div class="image m-0 rounded">
                      <img *ngIf="record.thumpnail" [src]="record.thumpnail" (error)='record.thumpnail = ""'
                        class="rounded">
                      <img *ngIf="!record.thumpnail" src="assets/images/icons8-no-image-100.png" class="rounded">
                    </div>
                  </div>
                </td>
                <td>
                  {{record.name}}
                </td>
                <td>
                  {{record.subSpecialty}}
                </td>
                <td>
                  {{record.tissueType}}
                </td>
                <td>
                  {{record.stainType}}
                </td>
                <td>{{record.age}} years/{{record.gender}}</td>
                <td [matTooltip]="joinArray(record.caseTags)">
                  <ng-container *ngFor="let item of record.caseTags; index as i">
                    <span *ngIf="i < 2">{{(i !== 0 ? ' ,' : '') + item | truncateText:30}}</span>
                  </ng-container>
                  <span *ngIf="record.caseTags.length > 2">, +{{record.caseTags.length - 2}}</span>
                </td>
              </ng-container>
            </tr>
          </ng-container>

        </tbody>

      </table>
    </div>
  </div>
</mat-dialog-content>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-template #dataEmpty>
  <ng-container *ngIf="!dataLoading">
    <app-empty-state [title]='"No Data Found"'></app-empty-state>
  </ng-container>
</ng-template>

<mat-dialog-actions align="end" [ngClass]="data.isShare ? 'justify-content-end' : 'justify-content-between'"
  class="assign-dialog-actions">
  <p *ngIf="!data.isShare">Min {{data.isSimilarity?'1':'2'}}, Max {{data.isSimilarity?'3':'4'}} slides to view</p>
  <div>
    <button mat-raised-button color="grey" mat-dialog-close [disabled]='loading'>Cancel</button>
    <ng-container *ngIf="!data.notAuthenticated">
      <a mat-raised-button color="primary" mat-dialog-close
        *ngIf="slidesList.length && selected.length >= (data.isSimilarity?1:2) && selected.length <= 4 && !data.isShare"
        (click)="continue()">
        View Slides
      </a>
      <button mat-raised-button color="primary" *ngIf="data.isShare && selected.length" [class.loading]='loading'
        [disabled]='loading' (click)="loginConcurel()">Share to Concurel</button>
    </ng-container>
    <a mat-raised-button color="primary" mat-dialog-close *ngIf="data.notAuthenticated" [routerLink]="
    ['slideListShare',data.caseIdType, data.caseId, 0]" [queryParams]="{slide: selected}">
      View Slides
    </a>
  </div>
</mat-dialog-actions>