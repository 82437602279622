import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefaultCurrency } from '../models/currency/DefaultCurrency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  refreshDetails = new BehaviorSubject<boolean>(false);
  public defaultCurrencySubject: BehaviorSubject<DefaultCurrency> = new BehaviorSubject<DefaultCurrency>(null);

  constructor(private http: HttpClient) {
    const dc = JSON.parse(localStorage.getItem('defaultCurrency')) as DefaultCurrency;
    if (dc) {
      this.defaultCurrencySubject.next(dc);
    }
  }

  public get defaultCurrencyValue(): DefaultCurrency {
    return this.defaultCurrencySubject.value;
  }

  getAll(): any {
    return this.http.get(`${environment.apiUrl}/Currency/GetCurrencyList`);
  }

  saveRecord(data) {
    return this.http.put(`${environment.apiUrl}/Currency/ManageCurrency`, data);
  }

  getDefaultCurrency() {
    return this.http.get(`${environment.apiUrl}/Currency/GetDefaultCurrency`);
  }

  IsCurrencyUsed(id) {
    return this.http.get(`${environment.apiUrl}/Currency/IsCurrencyUsed?CurrencyId=${id}`);
  }

}
