import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(
    private translate: TranslateService,
    private store: LocalStoreService
  ) { }

  setLanguage(lang?) {
    if (!lang) {
      lang = localStorage.getItem('language') || this.translate.getDefaultLang();
    }
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    if (lang === 'ar') {
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute('style', 'direction: rtl');
      document.getElementById('mainStyle').setAttribute('href', 'assets/css/ar-SA/style.css');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute('style', 'direction: ltr');
      document.getElementById('mainStyle').setAttribute('href', 'assets/css/en-US/style.css');
    }

  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return this.store.getItem('language') || this.translate.getDefaultLang();
  }
}
