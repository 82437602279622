import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatBox } from 'src/app/models/cases/chat/chatBox';
import { ListDataFilter } from 'src/app/models/cases/chat/ListDataFilter';
import { Message } from 'src/app/models/cases/chat/message';
import { ConcernedStaff } from 'src/app/models/cases/ConcernedStaff';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit {
  caseId: number;
  loadingUsers = false;
  openChatList = false;
  filterModel: ListDataFilter = new ListDataFilter();
  searchText: string;

  activeChats: ChatBox[] = [];
  @Input() caseIdType: CaseType;

  constructor(
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.caseId = Number(params.get('id'));
      }
    });
    this.chatService.newChatUser.subscribe(user => {
      if (user) {
        this.openChat(user);
      }
    });
  }

  openChat(user: ConcernedStaff) {
    const chatIndex = this.activeChats.findIndex((item) => item.user.userId === user.userId);
    if (chatIndex === -1) {
      this.activeChats.push({
        active: true,
        loading: true,
        user,
        message: '',
        msgList: []
      });
    } else {
      this.activeChats[chatIndex].loading = true;
      this.activeChats[chatIndex].active = true;
    }
    this.listChatMessages(user);
    // localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  listChatMessages(user: ConcernedStaff) {
    let chatIndex = this.activeChats.findIndex((item) => item.user.userId === user.userId);
    this.filterModel = {
      userId: user.userId + '_' + user.userType,
      CaseId: this.caseId,
      CaseIdType: this.caseIdType
    };
    this.chatService.ListChatMessages(this.filterModel).subscribe(
      result => {
        this.chatService.messagesList.next(true);
        chatIndex = this.activeChats.findIndex((item) => item.user.userId === user.userId);
        if (chatIndex >= 0) {
          this.activeChats[chatIndex].msgList = result as Message[];
          this.activeChats[chatIndex].loading = false;
          this.scrollToChat(chatIndex);
        }
      }, err => {
        console.error(err);
      });
  }
  send(chat: ChatBox) {
    const self = this;
    if (chat.message) {
      const params = {
        toUserId: chat.user.userId,
        toUserType: chat.user.userType,
        text: chat.message,
        caseID: this.caseId,
        caseIdType: this.caseIdType
      } as Message;
      this.chatService.SendChatMessage(params).subscribe(
        result => {
          const newMessage = {
            text: chat.message,
            mine: true,
            time: new Date(),
            isSeen: true
          } as Message;
          chat.msgList.push(newMessage);
          chat.message = '';
          this.scrollToChat(this.activeChats.indexOf(chat));
        }, err => {
          console.error(err);
        });
    }
  }
  removeChat(index) {
    this.activeChats.splice(index, 1);
    this.chatService.newChatUser.next(null);
    this.filterModel = new ListDataFilter();
    // localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  scrollToChat(index) {
    setTimeout(() => {
      if (document.querySelectorAll('#chat-' + index + ' ul').length) {
        document.getElementById('chat-' + index).scrollTop =
          document.querySelectorAll('#chat-' + index + ' ul')[0].clientHeight;
      }
    }, 100);
  }
}
