import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Directive({
  selector: '[appCounter]'
})
export class CounterDirective implements OnChanges, OnDestroy {

  private counterSource = new Subject<any>();
  private subscription = Subscription.EMPTY;

  @Input() counter: number;
  @Input() interval = 1000;
  @Output() value = new EventEmitter<number>();
  @Output() done = new EventEmitter<boolean>();

  constructor() {

    this.subscription = this.counterSource.pipe(
      switchMap(({ interval, count }) =>
        timer(0, interval).pipe(
          take(count),
          tap(() => {
            this.value.emit(--count);
            if (count <= 0) {
              this.done.emit(true);
            }
          })
        )
      )
    ).subscribe();
  }

  ngOnChanges() {
    this.counterSource.next({ count: this.counter, interval: this.interval });
  }

  public startTimer() {
    this.counterSource.next({ count: this.counter, interval: this.interval });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
