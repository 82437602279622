import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ModelsEnum } from '../models/users/users/enums/ModelsEnum';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  refreshProfileBraches = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  getAll(params, type: ModelsEnum): any {
    if (type === ModelsEnum.Lab) {
      return this.http.get(`${environment.apiUrl}/Lab/ListMainLabBranches`, { params });
    }
    if (type === ModelsEnum.ScanningCenter) {
      return this.http.get(`${environment.apiUrl}/ScanningCenter/ListMainScanningCenterBranches`, { params });
    }
  }
  saveRecord(data) {
    if (data.id) {
      return this.http.put(`${environment.apiUrl}/branches`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/branches`, data);
    }
  }
  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/branches/${id}`);
  }
  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/branches/${id}`);
  }

  getBranches(id, type: ModelsEnum) {
    if (type === ModelsEnum.Lab) {
      return this.http.get(`${environment.apiUrl}/Lab/GetLabBranchesSideBar?labId=${id}`);
    }
    if (type === ModelsEnum.ScanningCenter) {
      return this.http.get(`${environment.apiUrl}/ScanningCenter/GetScanningCenterBranchesSideBar?ScanningCenterId=${id}`);
    }
  }
}
