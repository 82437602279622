import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { ListData, PagedData } from 'src/app/models/transactions/ListData';
import { ListDataFilter } from 'src/app/models/transactions/ListDataFilter';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html'
})
export class TransactionsComponent implements OnInit {
  dataLoading = false;
  // table
  pageNumber = 1;
  pageSize = 8;
  pageCount: number;
  recordsData: ListData = new ListData();
  filterModel: ListDataFilter = new ListDataFilter();
  transactionsList: Array<PagedData> = [];
  defaultCurrency: DefaultCurrency;
  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() isProfile: boolean;
  @Input() walletId: number;
  @Input() entityId: number;
  @Input() entityType: string;

  constructor(
    private service: PaymentsService,
    public translate: TranslateService,
    private currencyService: CurrencyService,
    private auth: AuthenticationService
  ) {
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.dataLoading = true;
    this.filterModel.pageSize = this.pageSize;
    this.filterModel.pageNumber = this.pageNumber;
    if (
      (
        this.hasPermission(RolesEnum.SuperAdmin) || this.hasPermission(RolesEnum.MainLabAdmin)
      ) &&
      !this.isProfile
    ) {
      this.filterModel.walletId = this.walletId;
    }

    if (this.entityId) {
      this.filterModel.entityId = this.entityId;
      this.filterModel.entityType = this.entityType;
    }
    this.service.getEntityWalletTransactions(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(this.recordsData.totalCount / this.filterModel.pageSize);
          if (this.recordsData.pagedData && this.recordsData.pagedData.length) {
            this.transactionsList = this.transactionsList.concat(this.recordsData.pagedData);
          }
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1) {
      this.pageNumber += 1;
      this.getAll();
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
