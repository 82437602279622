import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {

}

@Component({
  selector: 'app-webcam-image-capture',
  templateUrl: './webcam-image-capture.component.html'
})
export class WebcamImageCaptureComponent implements OnInit, OnDestroy {
  loading = false;
  videoDevices: MediaDeviceInfo[] = [];
  @ViewChild('videoElement') videoElement: ElementRef;
  cameraStream = null;
  deviceSelected = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<WebcamImageCaptureComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.startCamera();
  }

  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          this.videoDevices = devices.filter(device => device.kind === 'videoinput');
          if (this.videoDevices.length > 0) {
            this.deviceSelected = this.videoDevices[0].deviceId;
            this.selectCamera();
          } else {
            console.error('No video devices found.');
          }
        })
        .catch(error => console.error('Error enumerating devices:', error));
    }
  }

  captureImage() {
    const video = this.videoElement.nativeElement;
    // Create a new canvas with the original video frame aspect ratio
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the image data as a base64-encoded string
    const imageDataURL = canvas.toDataURL('image/png');
    // Convert the data URL to a Blob object
    const blob = this.dataURLToBlob(imageDataURL);

    // Create a File object from the Blob
    const file = new File([blob], 'Specimen_image' + new Date().getTime() + '.png', { type: 'image/png' });
    this.dialogRef.close(file);
  }

  dataURLToBlob(dataURL: string) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  selectCamera() {
    if (this.cameraStream) {
      this.stopCamera();
    }
    const constraints = { video: { deviceId: this.deviceSelected } };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.play();
        this.cameraStream = stream; // Store the stream as a property
      })
      .catch(error => console.error('Error accessing camera:', error));
  }

  stopCamera() {
    // const stream = this.videoElement.nativeElement.srcObject;
    if (this.cameraStream instanceof MediaStream) {
      this.videoElement.nativeElement.pause();
      this.videoElement.nativeElement.srcObject = null;
      this.cameraStream.getTracks().forEach(track => track.stop());
    }
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }
}
