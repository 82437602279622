

export class ListOldData {
  slAsCount: number;
  pagedData: PagedData[];
}

export class PagedData {
  endDate: Date;
  slaId: number;
  startDate: Date;
}
