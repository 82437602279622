import { UsersService } from 'src/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { SwalService } from 'src/app/services/swal.service';

export interface DialogData {
  userName: string;
}

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html'
})
export class ChangeUsernameComponent implements OnInit {

  formGroup: FormGroup;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeUsernameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private service: UsersService,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading = true;
      const username = {
        userName: this.formGroup.controls.UserName.value
      };
      this.service.changeUserName(username).subscribe(
        data => {
          this.loading = false;
          this.swalService.swalSuccess(this.translate.instant('auth.Username-updated-successfully'))
            .then((result) => {
              this.dialogRef.close(true);
              this.authenticationService.logout();
            });
        },
        error => {
          this.loading = false;
          this.handleErrors(error.detail);
        });
    }
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      UserName: [this.data.userName, [
        Validators.required,
        CustomValidator.noWhiteSpace,
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9._@-]+$')
      ]]
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
      }
    });
  }

}
