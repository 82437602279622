import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClinicFormComponent } from '../clinic-form/clinic-form.component';
import { ClinicsService } from 'src/app/services/clinics.service';
import { ClinicDetails } from 'src/app/models/clinics/Details';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { CurrencyService } from 'src/app/services/currency.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { ListHeaderData } from 'src/app/models/payments/list-header-data';
import { SwalService } from 'src/app/services/swal.service';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
  selector: 'app-clinic-profile',
  templateUrl: './clinic-profile.component.html'
})
export class ClinicProfileComponent implements OnInit {
  dataLoading = false;
  clinicId: number;
  details: ClinicDetails = new ClinicDetails();
  isOurProfile = false;
  currentUser = new AuthUser();
  walletDetails: ListHeaderData = new ListHeaderData();
  defaultCurrency: DefaultCurrency;
  entityEnumSLA = EntityEnumSLA;

  constructor(
    public dialog: MatDialog,
    private service: ClinicsService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public servicePayment: PaymentsService,
    private currencyService: CurrencyService,
    private verifyService: VerificationService,
    private swalService: SwalService
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.clinicId = Number(params.get('id'));
        this.getDetails();
      } else {
        this.isOurProfile = true;
        this.getClinicProfileDetails();
      }
    });
    this.service.refreshDetails.subscribe(data => {
      if (data) {
        if (this.isOurProfile) {
          this.getClinicProfileDetails();
        } else {
          this.getDetails();
        }
      }
    });
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.clinicId).subscribe(
      data => {
        if (data) {
          this.details = data as ClinicDetails;
          this.details.id = this.clinicId;
          this.getEntityWalletDetails();
        }
      },
      error => {
        this.dataLoading = false;
        this.router.navigate(['/admin/clinics']);

      });
  }

  getClinicProfileDetails() {
    this.dataLoading = true;
    this.service.getClinicProfileDetails().subscribe(
      data => {
        if (data) {
          this.details = data as ClinicDetails;
          this.getEntityWalletDetails();
        }
      },
      error => {
        this.dataLoading = false;
        // this.router.navigate(['/admin/clinics']);
      });
  }

  getEntityWalletDetails() {
    let query: any = {};
    if (!this.isOurProfile) {
      query = {
        entityId: this.clinicId,
        entityType: EntityEnumSLA.clinic
      };
    }
    this.servicePayment.getEntityWalletDetails(query).subscribe(
      data => {
        if (data) {
          this.dataLoading = false;
          this.walletDetails = data as ListHeaderData;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  openClinicForm() {
    const dialogRef = this.dialog.open(ClinicFormComponent, {
      width: '600px',
      data: {
        clinicId: this.clinicId,
        record: this.details,
        isOurProfile: this.isOurProfile
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.isOurProfile) {
          this.getClinicProfileDetails();
        } else {
          this.getDetails();
        }
      }
    });
  }

  deleteRecord(clinicId) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          this.service.deleteRecord(clinicId).subscribe(
            data => {
              if (data) {
                this.dataLoading = false;
                this.swalService.swalSuccess()
                  .then(() => {
                    if (clinicId === this.clinicId) {
                      this.router.navigate(['/admin/clinics']);
                    } else {
                      this.getDetails();
                    }
                  });
              }
            },
            err => {
              this.dataLoading = false;
              // console.log('Try again!, ' + error);
              const errors = JSON.parse(err.detail);
              let message = '';
              if (err.detail) {
                errors.ErrorDTO.forEach((error) => {
                  if (error.ErrorCode === 'CantDelete') {
                    message = error.Error;
                  }
                });
              } else {
                message = err.title;
              }
              this.swalService.swalFailed(message);
            });
        }
      });
  }

  verfiyEmail(e) {
    this.swalService.swalConfirmation('Yes', 'Verify Email?').then((result) => {
      if (result.value) {
        this.dataLoading = true;
        const record = {
          entityId: this.clinicId ? this.clinicId : this.currentUser.ObjectValue,
          entityType: EntityEnumSLA.clinic
        };
        this.verifyService.sendVerifyEntityEmail(record).subscribe(
          data => {
            if (data) {
              this.dataLoading = false;
              this.swalService.swalSuccess('Send successfully, Please check your email!')
                .then(() => {
                  // this.getDetails();
                  // this.service.refreshProfileBraches.next(true);
                });
            }
          },
          err => {
            this.dataLoading = false;
            // console.log('Try again!, ' + error);
            this.swalService.swalFailed(err.title);
          });
      }
    });
  }

  verifyMobile(e) {
    this.service.refreshDetails.next(e);
  }

}
