import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { statusList } from 'src/app/models/sla/enums/statusList';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OldSlasComponent } from 'src/app/features/sla/old-slas/old-slas.component';
import { SlaDeclineReasonComponent } from 'src/app/features/sla/sla-decline-reason/sla-decline-reason.component';

export interface Sla {
  creationDate: Date;
  slaId: number;
  slaStatus: statusList;
  rejectionReason: string;
  rejectionDate: Date;
}

@Component({
  selector: 'app-sla-actions',
  templateUrl: './sla-actions.component.html'
})
export class SlaActionsComponent implements OnInit {
  @Input() sla: Sla;
  @Input() lastSLA: any;
  @Input() entityId: number;
  @Input() entityType: string;
  @Input() entityValue: string;
  @Input() entityName: string;
  @Input() isProfile: boolean;

  constructor(
    private auth: AuthenticationService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  oldSlAs() {
    this.dialog.open(OldSlasComponent, {
      width: '670px',
      data: {
        entityId: this.entityId,
        entityType: this.entityType,
        entityName: this.entityName,
        isProfile: this.isProfile
      }
    });
  }

  declineReason() {
    this.dialog.open(SlaDeclineReasonComponent, {
      width: '550px',
      data: {
        comment: this.sla.rejectionReason,
        creationDate: this.sla.rejectionDate
      }
    });
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
