import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { statusList } from 'src/app/models/sla/enums/statusList';

export interface DialogData {
  status: statusList;
  comment: string;
  creationDate: Date;
}

@Component({
  selector: 'app-sla-decline-reason',
  templateUrl: './sla-decline-reason.component.html'
})
export class SlaDeclineReasonComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SlaDeclineReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
