import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModelsEnum } from '../models/users/users/enums/ModelsEnum';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  getAll(modal = null): any {
    if (modal === ModelsEnum.SCUser || modal === ModelsEnum.ScanningCenter) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetRolesForScanningCenterUser`);
    } else if (modal === ModelsEnum.VLUser) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetVLRoles`);
    } else if (modal === ModelsEnum.hospitalUser || modal === ModelsEnum.Hospital) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetEntityRoles?EntityType=HOSPITAL`);
    } else if (modal === ModelsEnum.clinicUser || modal === ModelsEnum.Clinic) {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetEntityRoles?EntityType=CLINIC`);
    } else {
      return this.http.get(`${environment.apiUrl}/ManageUsers/GetRoles`);
    }
  }

  reportToRole(modal = null) {
    const data = {LabType: modal};
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetReportToRoles`, {
      params: data
    });
    // if (modal === ModelsEnum.VLUser) {
    //   return this.http.get(`${environment.apiUrl}/ManageUsers/GetVLReportToRoles`);
    // } else {
    //   return this.http.get(`${environment.apiUrl}/ManageUsers/GetReportToRoles`);
    // }
  }

}
