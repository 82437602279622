import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var MediaRecorder: any;

@Injectable({
  providedIn: 'root'
})
export class DeepgramAudioService {
  voiceToTextSubject: Subject<string> = new Subject();
  myStream: any;

  constructor() { }

  saveRecord() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.myStream = stream;
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm"
      });
      const soket = new WebSocket("wss://api.deepgram.com/v1/listen", [
        "token",
        environment.deepgramKey
      ]);

      soket.onopen = () => {
        mediaRecorder.addEventListener("dataavailable", async (event: any) => {
          // if (event.data.size > 0 && deepgramSocket.readyState == 1) {
          // }
          // console.log(event)
          soket.send(event.data);
        });
        mediaRecorder.start(1000);
      }

      soket.onmessage = (message) => {
        const received = JSON.parse(message.data);
        // console.log(received)
        const transcript = received.channel?.alternatives[0].transcript;
        if (transcript && received.is_final) {
          this.voiceToTextSubject.next(transcript);
        }
      };
    });
  }

  stopRecord() {
    if (this.myStream) {
      // this.webSoketStream.close();
      this.myStream.getTracks().forEach((track: any) => {
        track.stop();
      });
      this.myStream = null;
    }
    // console.log(this.myStream)
  }

}
