<div mat-dialog-title>
  <h2>
    {{data && data.title?data.title:'Receive the '+ title +'(s)'}}
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content class="container-receive-manually">
  <div class="mat-dialog-body">
    <div class="conatiner_qrcode">
      <ngx-scanner-qrcode [isAuto]="false" [config]="config" #action="scanner" (event)="getData($event)"
        (error)="onError($event)">
      </ngx-scanner-qrcode>
      <div class="conatiner_message">
        <span class="badge-status active" *ngIf="successMessage">
          {{title}} received successfully
        </span>
        <span class="badge-status danger" *ngIf="errorMessage">
          Failed receiving {{title}}s
        </span>
      </div>
      <div class="page-progress position-absolute">
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      </div>
    </div>

    <div class="receive-manually-header">
      <h4>Received {{title?title+'s':''}}</h4>
      <div class="request_code" *ngIf="caseCode">
        Case:
        <span>{{caseCode}}</span>
      </div>
      <div class="request_code" *ngIf="requestCode">
        Request:
        <span>R-{{requestCode}}</span>
      </div>
    </div>

    <ng-container *ngIf="recordsData.length; else dataEmpty">
      <div class="receive-manually-content py-0">
        <div class="list_received_items">
          <div class="received_head">
            <h4 class="received_title">#</h4>
            <h4 class="received_title">Case</h4>
          </div>
          <div class="received_content">
            <ng-container *ngFor="let record of recordsData; let i = index">
              <div class="received_item">
                <div class="received_body">
                  <div class="received_top">
                    <div class="item_left">
                      <h5 class="received_code">
                        #{{i+1}}
                      </h5>
                      <h5 class="received_ref" *ngIf="forGrossing">
                        Ref. No.
                        <span>
                          {{
                          receiveContainer?record.caseContainerDetailsDto.containerRefNumber:record.caseContainerDetailsDto.containerCassetteValue
                          }}
                        </span>
                      </h5>
                    </div>
                    <div class="item_right">
                      <h5 class="received_code">
                        {{record.caseCode}}
                      </h5>
                      <h5 class="received_ref" *ngIf="!forGrossing">
                        <strong>{{record.received.length}}</strong>
                        of
                        <strong *ngIf="!forGrossing">{{record.requestSlidesNumber}}</strong>
                        <strong *ngIf="forGrossing">
                          {{record.requestContainersNumber}}
                        </strong>
                        received
                      </h5>
                    </div>
                  </div>
                  <div class="received_bottom" *ngIf="forGrossing && receiveContainer">
                    <h5>
                      Received
                      <span>{{record.containerSpecimensNumber}} specimens</span>
                    </h5>
                    <a mat-button color="primary" class="btn_link_with_icon py-0"
                      (click)="updateSpecimen(i,record.caseContainerDetailsDto.containerSpecimensNumber,record.containerSpecimensNumber)">
                      <i class="material-icons">
                        edit
                      </i>
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="receive-manually-footer">
        <p class="mb-3 text-center">
          <i class="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
              <path id="info-circle"
                d="M7,2a5,5,0,1,0,5,5A5,5,0,0,0,7,2Zm0,9a4,4,0,1,1,4-4A4,4,0,0,1,7,11ZM7,6.75a.5.5,0,0,0-.5.5v1.5a.5.5,0,0,0,1,0V7.25A.5.5,0,0,0,7,6.75Zm0-2a.625.625,0,1,0,.625.625A.625.625,0,0,0,7,4.75Z"
                transform="translate(-2 -2)" fill="#999" />
            </svg>
          </i>
          <i>
            Please check the {{title}}s before confirm receiving them.
          </i>
        </p>
        <div class="btn-container text-center">
          <button mat-raised-button color="primary" class="w-100 mt-4" [class.loading]='loading && isSubmitted'
            [disabled]='loading' (click)="saveRecord()">
            <span>Confirm Receiving ({{slidesCount}} {{title}}s)</span>
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #dataEmpty>
      <div class="receive-manually-content text-center">
        <img src="./assets/images/scan-QR-code-illustration-lottifiles.png" alt="">
      </div>
      <div class="receive-manually-footer">
        <p class="mb-3 text-center">
          Scan the QR Code placed on the {{title}}
        </p>
        <div class="btn-container text-center" *ngIf="data.multible && !data.extrnalScan">
          <button mat-raised-button (click)="backDialog()" color="grey">
            <span>Back</span>
          </button>
        </div>
      </div>
    </ng-template>

  </div>
</mat-dialog-content>