export class ListHeaderData {
  incoming: {
    amount: number,
    amountAlreadyReceived: number,
    totalCustomers: number,
    totalInvoices: number
  };
  outgoing: {
    amount: number,
    amountAlreadyReceived: number,
    totalCustomers: number,
    totalInvoices: number
  };
  recentWalletTransactionDate: string;
  walletBalance: number;
  walletId: number;
}
