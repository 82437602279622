import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(private http: HttpClient) {
  }

  sendVerifyEntityEmail(data) {
    return this.http.post(`${environment.apiUrl}/VerifyEntity/SendVerifyEntityEmail`, data);
  }

  updateEntityVerification(data) {
    return this.http.post(`${environment.apiUrl}/VerifyEntity/UpdateEntityVerification`, data);
  }

  sendVerifyEntityOTP(data) {
    return this.http.post(`${environment.apiUrl}/VerifyEntity/SendVerifyEntityOTP`, data);
  }

  sendVerifyEntityOTPToEntityUser() {
    return this.http.post(`${environment.apiUrl}/UserProfile/GenerateOtp`,{});
  }

  updateEntityVerificationOTP(data) {
    return this.http.post(`${environment.apiUrl}/VerifyEntity/UpdateEntityVerificationOTP`, data);
  }

  verifyEntityUserMobile(otp) {
    return this.http.post(`${environment.apiUrl}/UserProfile/VerfieOTP`, otp);
  }

}
