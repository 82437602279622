export enum PathologistStatus {
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  DRAFTED = 'DRAFTED',
  REPORTED = 'REPORTED',
  ACCEPTED = 'ACCEPTED',
  WITH_DRAW = 'WITH_DRAW',
  CANCELLED = 'CANCELLED',
  SENT_BACK = 'SENT_BACK',
  REQUESTED_SECOND_OPINION = 'REQUESTED_SECOND_OPINION',
  SUBMIT_COMMENTS = 'SUBMIT_COMMENTS',
  SENT_BACK_SECOND_OPINION = 'SENT_BACK_SECOND_OPINION',
  REVIEWED = 'REVIEWED',
  DECLINED = 'DECLINED',
  UPDATES_SUBMITTED = 'UPDATES_SUBMITTED',
  TECHNICIAN_ADDED_SLIDE = 'TECHNICIAN_ADDED_SLIDE',
  TECHNICIAN_ADDED_OTHER_SLIDE = 'TECHNICIAN_ADDED_OTHER_SLIDE',
  TECHNICIAN_HIDE_SLIDE = 'TECHNICIAN_HIDE_SLIDE',
  SUPERVISOR_APPROVED_SLIDES = 'SUPERVISOR_APPROVED_SLIDES',
  SEND_BACK_UNDER_PROCESS = 'SEND_BACK_UNDER_PROCESS',
  REJECTED_PASS_PATHOLOGISTS = 'REJECTED_PASS_PATHOLOGISTS',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  TECHNICIAN_REJECT = 'TECHNICIAN_REJECT',
  WAITING_ENDORSEMENT_FEEDBACK = 'WAITING_ENDORSEMENT_FEEDBACK',
  CANCEL_ENDORSEMENT = 'CANCEL_ENDORSEMENT',
  ENDORSEMENT_CANCELLED = 'ENDORSEMENT_CANCELLED',
  ASSIGN_ENDORSEMENT = 'ASSIGN_ENDORSEMENT',
  ENDORSEMENT_AGREED = 'ENDORSEMENT_AGREED',
  ENDORSEMENT_NOT_AGREED = 'ENDORSEMENT_NOT_AGREED',
  PATH_SENT_BACK = 'PATH_SENT_BACK',
  PATH_SEND_BACK_UNDER_PROCESS = 'PATH_SEND_BACK_UNDER_PROCESS',
  CANCEL_PATH_SENT_BACK = 'CANCEL_PATH_SENT_BACK',
  SENT_BACK_DONE = 'SENT_BACK_DONE',
  ACCEPT_GROSSING = 'ACCEPT_GROSSING',
  REJECT_GROSSING = 'REJECT_GROSSING',
  SUBMITED_GROSSING = 'SUBMITED_GROSSING',
  SENT_BACK_GROSSING = 'SENT_BACK_GROSSING',
  SENT_BACK_GROSSING_DONE = 'SENT_BACK_GROSSING_DONE',
  CANCELLED_SENT_BACK_GROSSING = 'CANCELLED_SENT_BACK_GROSSING',
  TECHNOLOGIST_SENT_BACK_GROSSING = 'TECHNOLOGIST_SENT_BACK_GROSSING',
  TECHNOLOGIST_SENT_BACK_GROSSING_DONE = 'TECHNOLOGIST_SENT_BACK_GROSSING_DONE',
  PENDING = 'PENDING',
  VERIFICATION_DONE='VERIFICATION_DONE'
}

// {
//   ACTIVE = 'ACTIVE',
//   REJECTED = 'REJECTED',
//   DRAFTED = 'DRAFTED',
//   REPORTED = 'REPORTED',
//   ACCEPTED = 'ACCEPTED',
//   WITH_DRAW = 'WITH_DRAW',
//   REVIEWED = 'REVIEWED',
//   DECLINED = 'DECLINED',
// }
