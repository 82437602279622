import { AuthenticationService } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router, private snackBar: MatSnackBar,private activatedRoute:ActivatedRoute) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 425) {
        this.snackBar.open('Your account has been inactivated or deleted!', 'Ok', {
          horizontalPosition: 'right',
          verticalPosition: 'top'
        });
        this.authenticationService.logout();
      }
      if (err.status === 401 || err.status === 422) {
        this.authenticationService.RefeshUserToken(true,err.url.includes('GetDefaultCurrency')).subscribe(data => {
        }, err => {
          this.authenticationService.logout();
        });
      }
      if (this.authenticationService.currentUserValue) {
        if (err.status === 403 || err.status === 423) {
          this.router.navigate(['/admin/notAuthorized']);
        }
      }
      if (err.status === 0) { // Offline
        this.snackBar.open('You are offline!', 'Ok', {
          horizontalPosition: 'right',
          verticalPosition: 'top'
        });
      }

      // const error = err.error.message || err.statusText;
      return throwError(err.error);
    }));
  }
}
