import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-auth',
  templateUrl: './not-auth.component.html'
})
export class NotAuthComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/js/unauthorizedaccess.json',
    loop: false
  };

  constructor(public location: Location) { }

  ngOnInit(): void {
  }

}
