<div mat-dialog-title>
  <h2>
    Receive the {{title}}(s)
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content class="container-receive-manually">
  <div class="mat-dialog-body">
    <div class="receive-manually-header">
      <h4>{{title}}s to receive</h4>
      <div class="request_code" *ngIf="caseCode">
        Case:
        <span>{{caseCode}}</span>
      </div>
      <div class="request_code" *ngIf="requestCode">
        Request:
        <span>R-{{requestCode}}</span>
      </div>
    </div>
    <div class="receive-manually-content" [formGroup]="formGroup">
      <div class="sets-conatiner" *ngIf="casesList.length">
        <div class="set-item collapsed" *ngFor="let record of casesList; let index = index;"
          [class.collapsed]='activeCaseIndex !== index'>
          <div class="set-head" (click)="toggleCaseset(index)">
            <div>
              <span>{{record.caseNumber}}</span>
              {{record.patientName}}
            </div>
            <mat-icon class="more">unfold_more</mat-icon>
            <mat-icon class="less">unfold_less</mat-icon>
          </div>

          <div class="set-content">
            <div class="table-responsive">
              <table class="table table-primary">
                <tbody [formArrayName]="record.caseId">
                  <ng-container *ngIf="data.forGrossing && data.actionType === 'CONTAINER_PICKEDUP';else notGrossing">
                    <ng-container *ngFor="let item of record.containerReferrences; let i = index">
                      <tr [formGroupName]="i">
                        <td class="text-left align-middle py-0">
                          <mat-checkbox formControlName="check"
                            (change)="changeEvent($event,i,record.caseId,item.specimensNumber)"></mat-checkbox>
                        </td>
                        <td>
                          <div class="td-inner">
                            <div class="td-content">
                              <h5 class="code text-theme">
                                #{{i+1}}
                              </h5>
                            </div>
                            <div class="td-body">
                              <h5 class="mb-0">
                                <span>Ref. No.</span>
                                {{item.containerRef}}
                              </h5>
                              <h5 class="mb-0">
                                <span>Expected to receive</span> {{item.specimensNumber}} specimens
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td class="text-right align-middle py-0" [hidden]="data.actionType !== 'CONTAINER_PICKEDUP'">
                          <input class="form-control ml-auto" type="text" formControlName="specimenNumber"
                            [class.is-invalid]="isSubmitted && !getControl('specimenNumber',i,record.caseId).valid"
                            [required]="getControl('check',i,record.caseId).value"
                            [attr.disabled]="!getControl('check',i,record.caseId).value? '' : null" appOnlynumber
                            [hidden]="!getControl('check',i,record.caseId).value">
                          <p class="has-error h3 mb-0" *ngIf="hasError('specimenNumber', 'required',i,record.caseId)">
                            {{ 'forms.Required' | translate }}
                          </p>
                          <p class="has-error h3 mb-0" *ngIf="hasError('specimenNumber', 'min',i,record.caseId)">
                            Minimum is 1
                          </p>
                          <p class="has-error h3 mb-0" *ngIf="hasError('specimenNumber', 'max',i,record.caseId)">
                            Maximum is {{item.specimensNumber}}
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>

                  <ng-template #notGrossing>
                    <ng-container *ngIf="data.forGrossing;else notCassette"></ng-container>
                    <ng-container *ngFor="let item of record.containerReferrences; let i = index">
                      <tr [formGroupName]="i">
                        <td>
                          <div class="td-inner">
                            <div class="td-content">
                              <h5 class="code text-theme">
                                #{{i+1}}
                              </h5>
                            </div>
                            <div class="td-body">
                              <h5 class="mb-0">
                                <span>Ref. No.</span>{{item.cassetteRef}}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td hidden>
                          <input class="form-control" type="text" formControlName="specimenNumber" appOnlynumber>
                        </td>
                        <td class="text-right align-middle py-0">
                          <mat-checkbox formControlName="check"></mat-checkbox>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-template>
                  <ng-template #notCassette>
                    <ng-container *ngFor="let item of record.slideReferrences; let i = index">
                      <tr [formGroupName]="i">
                        <td>
                          <div class="td-inner">
                            <div class="td-content">
                              <h5 class="code text-theme">
                                #{{i+1}}
                              </h5>
                            </div>
                            <div class="td-body">
                              <h5 class="mb-0">
                                <span>Ref. No.</span>{{item}}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td hidden>
                          <input class="form-control" type="text" formControlName="specimenNumber" appOnlynumber>
                        </td>
                        <td class="text-right align-middle py-0">
                          <mat-checkbox formControlName="check"></mat-checkbox>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-template>

                </tbody>
              </table>
            </div>
          </div>
          <p class="has-error h3 mb-3" *ngIf="hasError(record.caseId, 'required') && isSubmitted">
            At least one {{title}} must be selected.
          </p>
        </div>
      </div>
    </div>
    <div class="receive-manually-footer">
      <p class="mb-3 text-center">
        <i class="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <path id="info-circle"
              d="M7,2a5,5,0,1,0,5,5A5,5,0,0,0,7,2Zm0,9a4,4,0,1,1,4-4A4,4,0,0,1,7,11ZM7,6.75a.5.5,0,0,0-.5.5v1.5a.5.5,0,0,0,1,0V7.25A.5.5,0,0,0,7,6.75Zm0-2a.625.625,0,1,0,.625.625A.625.625,0,0,0,7,4.75Z"
              transform="translate(-2 -2)" fill="#999" />
          </svg>
        </i>
        <i>
          Please check the {{title}}s before confirm receiving them.
        </i>
      </p>
      <div class="btn-container text-center">
        <button mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading' class="w-100 mt-4"
          (click)="saveRecord()">
          <span>Confirm Receiving</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>