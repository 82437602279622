import { Component, EventEmitter, OnInit, Output, Input, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-sidenav-over',
  templateUrl: './sidenav-over.component.html'
})
export class SidenavOverComponent implements OnInit, OnDestroy {
  @Input() open: boolean;
  @Input() hideScroll: boolean;
  @Output() closeDetails = new EventEmitter<boolean>();
  @Input() custClass: string;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.open) {
      this.renderer.addClass(document.body, 'overflow-hidden');
    }
  }

  close() {
    if (!this.hideScroll) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
    this.closeDetails.emit();
  }


  ngOnDestroy(): void {
    if (!this.hideScroll) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

}
