export enum CasecurrentStatus {
  IDLE = 'IDLE',
  UPLOAD_SLIDES = 'UPLOAD_SLIDES',
  Closed = 'CLOSED',
  Reopened = 'REOPENED',
  MatchMaking = 'MATCH_MAKING',
  MATCHMAKING_RESET = 'MATCHMAKING_RESET',
  SECONDOPINION = 'SECOND_OPINION',
  CaseSubmitted = 'CASE_SUBMITTED',
  CaseSubmittedAfterSentBackCaseDetails = 'CASE_SUBMITTED_AFTER_SENT_BACK_CASE_DETAILS',
  CaseSubmittedAfterSentBackUploadSlides = 'CASE_SUBMITTED_AFTER_SENT_BACK_UPLOAD_SLIDES',
  LabSupervisorApproved = 'LAB_SUPERVISOR_APPROVED',
  LabSupervisorRejected = 'LAB_SUPERVISOR_REJECTED',
  CaseWithCancelledRequest = 'CASE_WITH_CANCELLED_REQUEST',
  WAITING_TECHNICIAN_RESPONSE = 'WAITING_TECHNICIAN_RESPONSE',
  WaitingVlSupervisorProcess = 'WAITING_VL_SUPERVISOR_PROCESS',
  CaseWithRequest = 'CASE_WITH_REQUEST',
  UploadedSlidesForRequest = 'UPLOADED_SLIDES_FOR_REQUEST',
  CaseHasRejectedRequest = 'CASE_HAS_REJECTED_REQUEST',
  WaitingForSubmition = 'WAITING_FOR_SUBMITION',
  PathologiestAssigned = 'PATHOLOGIEST_ASSIGNED',
  PathologiestSubmitReport = 'PATHOLOGIEST_SUBMIT_REPORT',
  CaseCancelled = 'CASE_CANCELLED',
  CaseHasApprovedByVirtualLab = 'CASE_HAS_APPROVED_BY_VIRTUAL_LAB',
  CaseHasApprovedByScanningCenter = 'CASE_HAS_APPROVED_BY_SCANNING_CENTER',
  LabSuervisorReviewed = 'LAB_SUERVISOR_REVIEWED',
  SupervisorRejectUploadedSlides = 'SUPERVISOR_REJECT_UPLOADED_SLIDES',
  SupervisorRejectCaseDetails = 'SUPERVISOR_REJECT_CASE_DETAILS',
  SupervisorRejectUploadedSlidesCase = 'SUPERVISOR_REJECT_UPLOADED_SLIDES_CASE',
  SupervisorRejectCaseDetailsCase = 'SUPERVISOR_REJECT_CASE_DETAILS_CASE',
  TechnicianRefusedSupervisorComments = 'TECHNICIAN_REFUSED_SUPERVISOR_COMMENTS',
  CaseReuploadedSlides = 'CASE_REUPLOADED_SLIDES',
  CaseSubmittedDetails = 'CASE_SUBMITTED_DETAILS',
  CaseHasApprovedRequest = 'CASE_HAS_APPROVED_REQUEST',
  CASE_REVIEWED_SUCCESFULLY = 'CASE_REVIEWED_SUCCESFULLY',
  CLOSED_LAB_DELETED = 'CLOSED_LAB_DELETED',
  SCANNING_CENTER_UPLOADED_SLIDES = 'SCANNING_CENTER_UPLOADED_SLIDES',
  VIRTUAL_LAB_UPLOADED_SLIDES = 'VIRTUAL_LAB_UPLOADED_SLIDES',

  // Pathologist Status
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  REPORTED = 'REPORTED',
  ACCEPTED = 'ACCEPTED',
  WITH_DRAW = 'WITH_DRAW',
  CANCELLED = 'CANCELLED',
  SENT_BACK = 'SENT_BACK',
  UPDATES_SUBMITTED = 'UPDATES_SUBMITTED',
  SUPERVISOR_APPROVED_SLIDES = 'SUPERVISOR_APPROVED_SLIDES',
  REJECTED_PASS_PATHOLOGISTS = 'REJECTED_PASS_PATHOLOGISTS',
  TECHNICIAN_REJECT = 'TECHNICIAN_REJECT',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  secondOpnionWithdrow = 'secondOpnionWithdrow',
  secondOpnionReject = 'secondOpnionReject',
}
