<div class="common-page">
  <div class="page-container">
    <ng-lottie [options]="options"></ng-lottie>

    <div class="page-titles text-center">
      <h2>We are Sorry, Page Not Found</h2>
      <p>It seems we can't find what you're looking for</p>

      <!-- <button mat-raised-button color='primary' (click)="location.back()">Return Back</button> -->
    </div>
  </div>
</div>
