import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConcurelAuthUser } from 'src/app/models/concurel/ConcurelAuthUser';
import { shareSlides } from 'src/app/models/concurel/shareSlides';
import { ConcurelService } from 'src/app/services/concurel.service';

export interface DialogData {
  slideList: [];
  caseType: string;
  caseId: number;
  conCuralUserName: string;
  conCuralPassword: string;
  notAuthorized: boolean;
}

@Component({
  selector: 'app-concurel-account',
  templateUrl: './concurel-account.component.html',
})
export class ConcurelAccountComponent implements OnInit {
  successMsg = false;
  selectedMeeting: any;
  loading = false;
  shareSlides: shareSlides = new shareSlides();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ConcurelAccountComponent>,
    private concurelService: ConcurelService
  ) { }

  ngOnInit(): void {
  }


  closeDailog() {
    this.dialogRef.close();
  }

  isLocalStorageSaved(): boolean {
    const concurelUser = localStorage.getItem('concurelUser');
    return concurelUser !== null && concurelUser !== undefined;
  }

  submit() {
    const authConcurelUser = JSON.parse(
      localStorage.getItem('concurelUser')
    ) as ConcurelAuthUser;
    this.shareSlides.slidesIds = this.data.slideList;
    this.shareSlides.CaseType = this.data.caseType;
    this.shareSlides.CaseId = this.data.caseId;
    this.shareSlides.meetingId = this.selectedMeeting;
    this.shareSlides.Token = authConcurelUser.Token;
    this.loading = true;
    this.concurelService.shareSlideToConcurel(this.shareSlides).subscribe(
      (data) => {
        this.loading = false;
        this.successMsg = true;
      },
      (erorr) => {
        // let err = JSON.parse(erorr)
        // if (err.ErrorDTO.ErrorCode == 'Unauthorized') {
        //   localStorage.removeItem('concurelUser');
        // }
        localStorage.removeItem('concurelUser');
        this.loading = false;
      }
    );
  }

}
