import { SubmitReasonComponent } from 'src/app/partial/submit-reason/submit-reason.component';
import { Component, OnInit, OnDestroy, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { DstatusList } from 'src/app/models/requests/enums/DstatusList';
import { RequestType } from 'src/app/models/requests/requestType';
import { ActionType } from 'src/app/models/slides/enums/ActionType';
import { ReadSlide } from 'src/app/models/slides/enums/ReadSlide';
import { UpdateSlideReference } from 'src/app/models/slides/UpdateSlideReference';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DigitizationRequestService } from 'src/app/services/digitizationRequest.service';
import { SlidesService } from 'src/app/services/slides.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ReceiveSpecimenComponent } from '../receive-specimen/receive-specimen.component';

export interface DialogData {
  readSlideEnum: ReadSlide;
  caseCode: string;
  requestCode: number;
  multible: boolean;
  extrnalScan: boolean;
  isPackupDelivery: boolean;
  isReferred: boolean;
  title: string;
  forGrossing: boolean;
  receiveContainer: boolean;
  isPickup: boolean;
  isForGrossing: boolean;
}

export interface PageData {
  caseCode: string;
  requestSlidesNumber: number;
  requestContainersNumber: number;
  received: string[];
  containerSpecimensNumber: number;
  caseContainerDetailsDto: any;
}
@Component({
  selector: 'app-receive-slide-qrcode',
  templateUrl: './receive-slide-qrcode.component.html'
})
export class ReceiveSlideQrcodeComponent implements OnInit, OnDestroy, AfterViewInit {
  isSubmitted = false;
  loading = false;
  recordsData: PageData[] = [];
  currentUser = new AuthUser();
  qrcode: string;
  requestCode: number;
  caseCode: string;
  slidesCount = 0;
  dRequestStatus: DstatusList;
  successMessage: boolean;
  errorMessage: boolean;

  @ViewChild('action') action: HTMLElement;

  public config = {};
  title = 'slide';
  forGrossing = false;
  receiveContainer = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ReceiveSlideQrcodeComponent>,
    private service: SlidesService,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private dService: DigitizationRequestService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    this.requestCode = this.data.requestCode;
    this.caseCode = this.data.caseCode;
    this.forGrossing = this.data.forGrossing;
    this.receiveContainer = this.data.receiveContainer;
    if (this.forGrossing && this.receiveContainer) {
      this.title = 'container';
    } else if (this.forGrossing) {
      this.title = 'cassette';
    } else if (!this.data.requestCode && !this.data.caseCode) {
      this.title = '';
    }
  }

  getData(e) {
    if (e && !this.qrcode && !this.loading && !this.errorMessage) {
      this.qrcode = e;
      this.receiveSlide();
    }
  }

  public onError(e: any): void {
    console.log(e);
  }

  public handle(action: any, fn: string): void {
    action[fn]();
  }

  receiveSlide() {
    this.loading = true;
    const data = {
      readSlideEnum: this.data.readSlideEnum,
      token: this.qrcode,
      entityType: this.currentUser.ObjectType,
      entityId: this.currentUser.ObjectValue,
      IsReferred: this.data.isReferred,
      isPickup: this.data.isPickup || (this.forGrossing && this.receiveContainer) ? true : false,
      isContainerToken: this.forGrossing && this.receiveContainer,
      isCasseteToken: this.forGrossing && !this.receiveContainer,
      isForGrossing: this.data.isForGrossing
    } as any;
    this.service.readReferenceSlides(data).subscribe((res) => {
      if (res.notFound) {
        this.handleError(res);
        return;
      } else {
        this.handle(this.action, 'pause');
        if (this.data.extrnalScan && this.data.multible && this.data.readSlideEnum === ReadSlide.DREQUEST_ID) {
          if (!this.requestCode) {
            this.requestCode = res.dRequestId;
            this.dRequestStatus = res.dRequestStatus;

            let type = this.dRequestStatus && (this.dRequestStatus === DstatusList.WAITING_PICKUP || this.dRequestStatus === DstatusList.WAITING_CONTAINER_PICKUP) ?
              RequestType.PICKUP : RequestType.DELIVERY;
            if (this.data.isPackupDelivery) {
              this.router.navigate(
                ['/admin/requests/pickup-delivery', res.dRequestId],
                { queryParams: { model: 'pickup-delivery', requestType: type } }
              ).
                then(result => {
                  if (
                    (type === RequestType.DELIVERY && !res.isAssignedForDelivery) ||
                    (type === RequestType.PICKUP && !res.isAssignedForPickup)
                  ) {
                    type = RequestType.DELIVERY && res.isAssignedForDelivery ? RequestType.DELIVERY :
                      RequestType.PICKUP && res.isAssignedForPickup ? RequestType.PICKUP : RequestType.DELIVERY;
                    this.router.navigate(
                      ['/admin/requests/pickup-delivery', res.dRequestId],
                      { queryParams: { model: 'pickup-delivery', requestType: type } }
                    );
                    this.handle(this.action, 'stop');
                    this.dialogRef.close(null);
                  } else {
                    this.handle(this.action, 'play');
                  }
                });

            } else {
              this.router.navigate(['/admin/requests/details', res.dRequestId]).
                then(result => {
                  this.handle(this.action, 'play');
                });
            }
          }
          if (
            this.dRequestStatus &&
            (
              (
                this.dRequestStatus !== DstatusList.WAITING_PICKUP &&
                this.dRequestStatus !== DstatusList.WAITING_CONTAINER_PICKUP &&
                this.dRequestStatus !== DstatusList.WAITING_DELIVERY &&
                this.dRequestStatus !== DstatusList.WAITING_CONTAINER_DELIVERY &&
                this.data.isPackupDelivery
              ) ||
              (
                this.dRequestStatus !== DstatusList.SLIDES_DELIVERIED &&
                this.dRequestStatus !== DstatusList.CONTAINERS_DELIVERIED &&
                !this.data.isPackupDelivery
              ) ||
              (
                this.dRequestStatus == DstatusList.CONTAINERS_DELIVERIED &&
                res.caseContainerDetailsDto &&
                res.caseContainerDetailsDto.containerRefNumber === res.srn &&
                !this.data.isReferred &&
                !this.data.isPackupDelivery
              )
            )
          ) {
            this.handle(this.action, 'stop');
            this.dialogRef.close(null);
          } else {
            this.handleReceiveSlide(res);
          }
        } else if (!this.data.extrnalScan && this.data.multible && this.data.readSlideEnum === ReadSlide.DREQUEST_ID) {
          if (!this.dRequestStatus) {
            this.dRequestStatus = res.dRequestStatus;
          }
          this.handleReceiveSlide(res);
        } else {
          this.handle(this.action, 'stop');
          this.dialogRef.close(res);
        }
        this.loading = false;
      }
    }, err => {
      this.handleErrorStatus();
    });
  }

  handleReceiveSlide(res) {
    let index = this.recordsData.findIndex((item) => item.caseCode === res.caseIdNumber);
    if (this.forGrossing) {
      index = this.recordsData.findIndex((item) => item.received[0] === res.srn);
    }
    if (
      this.data.readSlideEnum === ReadSlide.DREQUEST_ID &&
      this.requestCode &&
      res.dRequestId &&
      this.requestCode !== res.dRequestId
    ) {
      this.handleError(res);
      return;
    }
    if (!this.data.isPackupDelivery && res.caseContainerDetailsDto && !this.data.isReferred) {
      this.forGrossing = true;
      this.title = 'cassette';
    }
    if (index === -1) {
      const record = {
        caseCode: res.caseIdNumber,
        requestSlidesNumber: res.requestSlidesNumber,
        requestContainersNumber: res.requestContainersNumber,
        received: [res.srn],
        caseContainerDetailsDto: res.caseContainerDetailsDto
      } as any;
      if (res.caseContainerDetailsDto) {
        record.containerSpecimensNumber = res.caseContainerDetailsDto.containerSpecimensNumber;
      }
      this.recordsData.push(record);
      this.slidesCount += 1;
    } else if (
      index !== -1 &&
      (
        (
          !this.forGrossing &&
          this.recordsData[index].received.length < this.recordsData[index].requestSlidesNumber
        ) ||
        (
          this.forGrossing &&
          this.recordsData[index].received.length < this.recordsData[index].requestContainersNumber
        )
      ) &&
      !this.recordsData[index].received.includes(res.srn)
    ) {
      this.recordsData[index].received.push(res.srn);
      this.slidesCount += 1;
    }
    this.successMessage = true;
    setTimeout(() => {
      this.successMessage = false;
    }, 500);
    this.qrcode = null;
    this.handle(this.action, 'play');
  }

  handleErrorStatus() {
    this.loading = false;
    this.errorMessage = true;
    this.handle(this.action, 'play');
    setTimeout(() => {
      this.qrcode = null;
      this.errorMessage = false;
    }, 1000);
  }

  saveRecord() {
    this.isSubmitted = true;
    const refNumber = [];
    this.recordsData.map(record => {
      if (this.forGrossing && this.receiveContainer) {
        refNumber.push({ key: record.caseContainerDetailsDto.containerRefNumber, value: record.containerSpecimensNumber });
      } else if (this.forGrossing && !this.receiveContainer) {
        refNumber.push({ key: record.caseContainerDetailsDto.containerCassetteValue });
      } else {
        refNumber.push(...record.received);
      }
    });
    const data = {
      token: null,
      actionType:
        this.dRequestStatus === DstatusList.WAITING_PICKUP ? ActionType.SLIDES_PICKEDUP :
          this.dRequestStatus === DstatusList.SLIDES_DELIVERIED ? ActionType.SLIDES_RECIEVED :
            this.dRequestStatus === DstatusList.WAITING_CONTAINER_PICKUP ? ActionType.CONTAINER_PICKEDUP :
              this.dRequestStatus === DstatusList.WAITING_CONTAINER_DELIVERY ? ActionType.CONTAINER_DELIVERIED :
                this.dRequestStatus === DstatusList.CONTAINERS_DELIVERIED ? ActionType.CONTAINER_RECIEVED : ActionType.SLIDES_DELIVERIED,
      refNumber,
      dRequestId: this.data.requestCode ? this.data.requestCode : this.requestCode
    } as UpdateSlideReference;
    if (this.checkTotalSlides()) {
      data.comment = null;
      this.loading = true;
      let serviceTarget = this.service.updateSlideReference(data);
      if (this.forGrossing) {
        serviceTarget = this.service.updateContainerReference(data);
      }

      serviceTarget.subscribe((res) => {
        this.loading = false;
        this.receiveConfirm();
      }, err => {
        this.loading = false;
      });
    } else {
      this.receiveReason(data);
    }
  }

  receiveReason(recordData: UpdateSlideReference) {
    this.loading = false;
    let helperLabel = 'Please explain why you received fewer slide than the total number you should receive.';
    if (this.data.forGrossing && this.receiveContainer) {
      helperLabel = 'Please explain why you received fewer containers or specimens than the total number you should receive.';
    } else if (this.data.forGrossing) {
      helperLabel = 'Please explain why you received fewer cassette than the total number you should receive.';
    }
    let serviceTarget = 'updateSlideReference';
    if (this.data.forGrossing) {
      serviceTarget = 'updateContainerReference';
    }
    const dataRecord = {
      title: 'Reasons',
      titleField: 'Reasons / Notes',
      comment: null,
      record: {
        token: recordData.token,
        actionType: recordData.actionType,
        refNumber: recordData.refNumber,
        dRequestId: recordData.dRequestId
      },
      serviceTarget,
      service: this.service,
      showClose: false,
      showBack: true,
      btnSubmitTitle: 'Submit Reasons',
      hideSwalMessageSuccess: true,
      helpersTop: [{
        label: helperLabel,
        class: "label-form"
      }]
    };

    const dialogRef = this.dialog.open(SubmitReasonComponent, {
      width: '550px',
      data: dataRecord
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.back) {
        this.receiveConfirm();
      }
    });
  }

  receiveConfirm() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '650px',
      data: {
        title: `Receiving ${this.title}s confirmed`,
        // message:
        //   'Waiting you to deliver the slides to your scanning center unless you\'re a mobile center',
        img: 'assets/images/done-illustration.png',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dService.refreshDetails.next(true);
      this.dialogRef.close(true);
    });
  }

  handleError(err) {
    this.handle(this.action, 'pause');
    let message = 'The QR code that you scanned is not related to this case';
    if (this.data && this.data.extrnalScan && !this.data.isReferred && this.data.readSlideEnum === ReadSlide.DREQUEST_ID) {
      message = 'The QR code that you scanned isn\'t related to your requests';
    } else if (this.data && this.data.extrnalScan && this.data.isReferred && this.data.readSlideEnum === ReadSlide.DREQUEST_ID) {
      message = 'The QR code that you scanned isn\'t related to your referred requests';
    } else if (this.data && !this.data.extrnalScan && this.data.readSlideEnum === ReadSlide.DREQUEST_ID) {
      message = 'The QR code that you scanned isn\'t related to this request';
    } else if (this.data && this.data.extrnalScan && this.data.readSlideEnum === ReadSlide.REF_CASE) {
      message = 'The QR code that you scanned isn\'t related to your referred cases';
    } else if (this.data && this.data.extrnalScan && this.data.readSlideEnum === ReadSlide.CASE) {
      message = 'The QR code that you scanned isn\'t related to your cases';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '650px',
      data: {
        title: '',
        message,
        img: 'assets/images/Group-11981.svg',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
      this.handle(this.action, 'play');
      setTimeout(() => {
        this.qrcode = null;
      }, 500);
    });
  }

  checkTotalSlides(): boolean {
    let Valid = true;
    if (this.forGrossing) {
      if (this.recordsData.length !== this.recordsData[0].requestContainersNumber) {
        Valid = false;
      } else if (this.data.receiveContainer) {
        this.recordsData.map(record => {
          if (record.caseContainerDetailsDto.containerSpecimensNumber !== record.containerSpecimensNumber) {
            Valid = false;
          }
        });
      }
    } else {
      this.recordsData.map(record => {
        if (record.received.length !== record.requestSlidesNumber) {
          Valid = false;
        }
      });
    }
    return Valid;
  }

  backDialog() {
    this.handle(this.action, 'stop');
    this.dialogRef.close({ back: true });
  }

  ngAfterViewInit(): void {
    console.log(this.action);
    this.handle(this.action, 'start');
    console.log(this.action, 'start');

  }

  ngOnDestroy(): void {
    this.qrcode = null;
    this.handle(this.action, 'stop');
    this.stopTracks(this.action)

  }
  stopTracks(stram) {
    stram.video.nativeElement.srcObject.getTracks().forEach(track => {
      track.stop();
    })
    stram.video.nativeElement.srcObject = null;
  }

  updateSpecimen(index: number, containerSpecimensNumber: number, specimensNumber) {
    const dialogRef = this.dialog.open(ReceiveSpecimenComponent, {
      width: '550px',
      data: {
        containerSpecimensNumber,
        specimensNumber
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recordsData[index].containerSpecimensNumber = Number(result);
      }
    });
  }

}
