<div mat-dialog-title>
  <h2>Statistics of Performance Indicator</h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">
      <div class="fields-group">
        <h3 class="label-form">
          To narrow down the data, specify the following
        </h3>
        <div class="row">

          <div class="col-md-12">
            <mat-form-field>
              <mat-label>Select Time Range</mat-label>
              <mat-select disableOptionCentering formControlName="timeRange"
                (selectionChange)="getListUserPreformanceIndicator(true)">
                <mat-option [value]="item.value" *ngFor="let item of periodList">
                  <span>{{item.name}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div class="row"
          [hidden]="formGroup.controls.timeRange.value && formGroup.controls.timeRange.value !== 'CUSTOME'">
          <div class="col-lg-6 mb-3">
            <mat-form-field class="form-group no-validation">
              <mat-label>Date from</mat-label>
              <input matInput [matDatepicker]="DateFrom" formControlName='dateFrom'
                [max]='formGroup.controls.dateTo.value || today' (dateChange)="getListUserPreformanceIndicator(true)"
                readonly>
              <div matSuffix class="d-flex">
                <button mat-icon-button type="button"
                  (click)="resetDate('dateFrom');getListUserPreformanceIndicator(true)"
                  *ngIf="formGroup.controls.dateFrom.value">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="DateFrom"></mat-datepicker-toggle>
                <mat-datepicker #DateFrom></mat-datepicker>
              </div>
            </mat-form-field>
          </div>
          <div class="col-lg-6 mb-3">
            <mat-form-field class="no-validation">
              <mat-label>Date to</mat-label>
              <input matInput [matDatepicker]="DateTo" formControlName='dateTo'
                [min]="formGroup.controls.dateFrom.value" [max]='today'
                (dateChange)="getListUserPreformanceIndicator(true)" readonly>
              <div matSuffix class="d-flex">
                <button mat-icon-button type="button"
                  (click)="resetDate('dateTo');getListUserPreformanceIndicator(true)"
                  *ngIf="formGroup.controls.dateTo.value">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="DateTo"></mat-datepicker-toggle>
                <mat-datepicker #DateTo></mat-datepicker>
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="fields-group">
        <h3 class="label-form">
          Select date or actions that you would like to track
        </h3>
        <div *ngIf='dataLoading' class="dataLoading">
          <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>

        <ng-container *ngIf='!dataLoading'>
          <div *ngIf="recordsData; else dataEmpty">
            <div class="table-responsive table_assign px-0" *ngIf="recordsData.length; else dataEmpty">
              <table class="table table-primary table_actions">
                <thead>
                  <tr>
                    <th>
                      <mat-checkbox [checked]="checkSelectdAll()" (change)="selectAll($event)">
                      </mat-checkbox>
                    </th>
                    <th>Select All</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let record of recordsData; let i = index">
                    <tr class="sub_tr_header">
                      <td colspan="3">
                        {{record.roleName}}
                      </td>
                    </tr>
                    <ng-container *ngFor="let item of record.result; let index = index">
                      <tr (click)="selectAction(i,item.actionName,index)" class="active">
                        <td>
                          <mat-checkbox [checked]="isInArray(actionsSelected,i,item.actionName,index)">
                          </mat-checkbox>
                        </td>
                        <td>{{item.actionTitle}}</td>
                        <td class="text-right">
                          {{
                          item.actionName === indicatorActionName.ADD_SLIDE ||
                          item.actionName === indicatorActionName.UPDATE_SLIDE ||
                          item.actionName === indicatorActionName.DELETE_SLIDE?
                          item.slidesCount:item.mainCount
                          }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>

                </tbody>

              </table>
              <p class="has-error h3 mb-3" *ngIf="!checkSlectOneAction() && isSubmitted">
                At least one Action must be selected.
              </p>
            </div>
          </div>
        </ng-container>

        <ng-template #dataEmpty>
          <app-empty-state [title]='" No Data Found"'></app-empty-state>
        </ng-template>
      </div>

      <button class="w-100" mat-raised-button color="primary" [disabled]="!this.actionsSelected.length"
        (click)="generate()">
        Generate
      </button>
    </form>
  </div>
</mat-dialog-content>