import { AuthenticationService } from 'src/app/services/authentication.service';
import { Directive, Input, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit {
  @Input() accessRoles: string;
  constructor(private elementRef: ElementRef, private auth: AuthenticationService) { }

  ngOnInit() {
    this.checkAccess();
  }
  checkAccess() {
    const accessControls: string[] = this.auth.currentUserValue.UserRoles;
    const accessRolesList = this.accessRoles.split(',');
    const founded = [];
    accessRolesList.forEach(role => {
      const found = accessControls.some(control => control === role);
      if (found) { founded.push(role); }
    });

    if (!founded.length && accessControls.length) {
      this.elementRef.nativeElement.remove();
    }
  }
}
