<div mat-dialog-title>
  <h2>
    Update Number of Specimen
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">

      <div class="fields-group mb-3">
        <mat-form-field>
          <mat-label>Number of specimens</mat-label>
          <input matInput formControlName="specimensNumber" maxlength="500">
          <mat-error *ngIf="hasError('specimensNumber', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('specimensNumber', 'min')">
            You must receive at least one specimen or not receive the container
          </mat-error>
          <mat-error *ngIf="hasError('specimensNumber', 'max')">
            Enter number less than or equal {{this.data.containerSpecimensNumber}}
          </mat-error>
        </mat-form-field>

      </div>

      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        Submit
      </button>
      <!-- <button mat-raised-button [disabled]="loading" mat-dialog-close color="grey" class="w-100 mt-3" type="button">
        Cancel
      </button> -->
    </form>
  </div>
</mat-dialog-content>