import { NavigationService } from './../../../services/navigation.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { IMenuItem } from 'src/app/services/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ])
  ]
})
export class SidebarComponent implements OnInit {
  nav: IMenuItem[];
  selectedItem: IMenuItem;
  today = new Date();

  constructor(
    public navService: NavigationService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.navService.menuItems.subscribe(items => {
      this.nav = items;
    });
  }

  isActiveMenu(link) {
    return location.href.includes(link);
  }

  selectItem(item) {
    if (item.active) {
      this.nav.forEach(element => {
        element.active = false;
      });
      this.selectedItem = null;
    } else {
      this.selectedItem = item;
      this.setActiveMainItem(item);
    }
  }

  setActiveMainItem(item) {
    this.nav.forEach(element => {
      element.active = false;
    });
    item.active = true;
  }

  toggelSidebar() {
    const navState = this.navService.sidebarState;
    navState.sidenavOpen = !navState.sidenavOpen;
  }

}
