export enum DstatusList {
  IDLE = 'IDLE',
  REVIEWED = 'REVIEWED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  Submitted = 'SUBMITTED',
  ROLLED_BACK = 'ROLLED_BACK',
  UpdatedRequestSource = 'UPDATED_REQUEST_SOURCE',
  UploadedSlides = 'UPLOADED_SLIDES',
  RejectedUploadedSlides = 'REJECTED_UPLOADED_SLIDES',
  RejectedCaseDetails = 'REJECTED_CASE_DETAILS',
  ReuploadedSlides = 'REUPLOADED_SLIDES',
  SUPERVISOR_APPROVED_SLIDES = 'SUPERVISOR_APPROVED_SLIDES',
  TECHNICIAN_SUBMIT_UPDATES = 'TECHNICIAN_SUBMIT_UPDATES',
  REJECTED_PASS_PATHOLOGISTS = 'REJECTED_PASS_PATHOLOGISTS',
  TECHNICIAN_REJECT = 'TECHNICIAN_REJECT',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  SLIDES_PICKED_UP = 'SLIDES_PICKED_UP',
  SLIDES_PICKEDUP = 'SLIDES_PICKEDUP',
  SLIDES_RECIEVED = 'SLIDES_RECIEVED',
  CONTAINERS_RECIEVED = 'CONTAINERS_RECIEVED',
  SLIDES_DELIVERIED = 'SLIDES_DELIVERIED',
  WAITING_PICKUP = 'WAITING_PICKUP',
  WAITING_DELIVERY = 'WAITING_DELIVERY',
  UPLOADED_SLIDES = 'UPLOADED_SLIDES',
  READY_TO_UPLOAD_SLIDES = 'READY_TO_UPLOAD_SLIDES',
  REQUEST_ROLL_BACK = 'REQUEST_ROLL_BACK',
  READY_TO_UPLOAD_SLIDES_REF = 'READY_TO_UPLOAD_SLIDES_REF',
  WAITING_CONTAINER_PICKUP = 'WAITING_CONTAINER_PICKUP',
  READY_TO_GROSSING = 'READY_TO_GROSSING',
  CONTAINERS_PICKEDUP = 'CONTAINERS_PICKEDUP',
  WAITING_CONTAINER_DELIVERY = 'WAITING_CONTAINER_DELIVERY',
  CONTAINERS_DELIVERIED = 'CONTAINERS_DELIVERIED'
}
