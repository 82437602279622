import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DstatusList } from 'src/app/models/requests/enums/DstatusList';


export interface DialogData {
  forGrossing: boolean;
  representativeStatus: DstatusList;
}
@Component({
  selector: 'app-receive-method',
  templateUrl: './receive-method.component.html'
})
export class ReceiveMethodComponent implements OnInit {
  title = 'slide';
  constructor(
    public dialogRef: MatDialogRef<ReceiveMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    if (this.data.forGrossing && this.data.representativeStatus === DstatusList.WAITING_CONTAINER_PICKUP) {
      this.title = 'container';
    } else if (this.data.forGrossing) {
      this.title = 'cassette';
    }
  }

  select(type) {
    this.dialogRef.close(type);
  }

}
