import { TransactionType } from './enums/TransactionType';

export class ListData {
  totalCount: number;
  pagedData: PagedData[];
}

export class PagedData {
  id: number;
  amount: number;
  paymentReferenceNumber: string;
  transactionDate: Date;
  transactionType: TransactionType;
}
