<div mat-dialog-title>
  <h2>Add Slide Reference</h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="mat-dialog-inner mb-0">
      <form [formGroup]="formGroup">
        <h4 class="label-form" [hidden]="data.specimenType == specimenTypes.SLIDES">Select Casstte</h4>
        <div class="fields-group" [hidden]="data.specimenType == specimenTypes.SLIDES">

          <mat-form-field [class.loading-field]="loadingCassettes">
            <mat-label>Select Casstte</mat-label>
            <mat-select disableOptionCentering formControlName="casseteeId" (selectionChange)='getStains()'>
              <mat-option [value]="item.id" *ngFor="let item of cassetteList" [matTooltip]="item.name">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('casseteeId', 'required')">{{ 'forms.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <h4 class="label-form">Select Stain Type</h4>
        <div class="fields-group">

          <mat-form-field [class.loading-field]="dataLoadingStain">
            <mat-label>Select Stain Type</mat-label>
            <mat-select disableOptionCentering formControlName="stainTypeId">
              <mat-option [value]="item.id" *ngFor="let item of stainList" [matTooltip]="item.name">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('stainTypeId', 'required')">{{ 'forms.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <h4 class="label-form">Antibody</h4>
        <div class="fields-group" *ngIf="!antiBodyList.length">
          <mat-form-field>
            <mat-label>Enter Antibody</mat-label>
            <input matInput formControlName="antibody" maxlength="50">
            <mat-error *ngIf="hasError('antibody', 'maxLength')">
              Max Length is 50 Characters
            </mat-error>
            <mat-hint align="end">
              Max Length is 50 Characters
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="fields-group" *ngIf="antiBodyList.length">
          <mat-form-field [class.loading-field]="dataLoadingAntiBody">
            <mat-label>Select Antibody</mat-label>
            <mat-select formControlName="antibodyList" placeholder="">
              <mat-option></mat-option>
              <mat-option *ngFor="let item of antiBodyList" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button class="w-100" mat-raised-button color="primary" [disabled]="loading || !formGroup.valid"
          [class.loading]='loading' (click)="saveRecord()">
          Submit
        </button>
      </form>
    </div>
  </div>
</mat-dialog-content>