import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { SCForm } from '../models/scanning-center/SCForm';

@Injectable({
  providedIn: 'root'
})
export class ScanningCentersService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/ScanningCenter/ListScanningCenters`, { params });
  }
  GetScanningCenterByCity(CityId): any {
    return this.http.get(`${environment.apiUrl}/ScanningCenter/GetScanningCenterByCity?CityId=${CityId}`);
  }
  getAllMain(id = null): any {
    const query = id ? '?ScanningCenterId=' + id : '';
    return this.http.get(`${environment.apiUrl}/ScanningCenter/GetMainScanningCenters${query}`);
  }
  saveRecord(data: SCForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    if (data.ScanningCenterId) {
      return this.http.put(`${environment.apiUrl}/ScanningCenter/EditScanningCenter`, formData);
    } else {
      return this.http.post(`${environment.apiUrl}/ScanningCenter/AddScanningCenter`, formData);
    }
  }
  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/ScanningCenter/GetScanningCenterDetails?ScanningCenterId=${id}`);
  }
  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/ScanningCenter/DeleteScanningCenter?ScanningCenterId=${id}`);
  }

  getAllScanningCentersLookup(params = null) {
    return this.http.get(`${environment.apiUrl}/ScanningCenter/GetAllScanningCentersLookup`, { params });
  }

  getScanningCenterProfileDetails() {
    return this.http.get(`${environment.apiUrl}/ScanningCenterProfile/GetScanningCenterProfileDetails`);
  }


  updateScanningCenterProfile(data: SCForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    return this.http.put(`${environment.apiUrl}/ScanningCenterProfile/EditScanningCenterProfile`, formData);
  }
}
