import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { CoveredArea } from 'src/app/models/users/CoveredArea';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-covered-area-form',
  templateUrl: './covered-area-form.component.html'
})
export class CoveredAreaFormComponent implements OnInit {
  @Input() record: CoveredArea = new CoveredArea();
  @Input() countryList: Lookup[] = [];
  @Input() coveredAreaList: CoveredArea[] = [new CoveredArea()];
  @Input() currentIndex: number;

  @Output() newRecord = new EventEmitter<CoveredArea>();
  @Output() deleteItem = new EventEmitter();

  formGroup: FormGroup;
  isSubmitted = false;
  coAreaLoading = false;

  citiesTree: Lookup[] = [];
  selectedCities = [];
  detailsSelectedRecords: Lookup[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  getCoverageAreas() {
    this.checkUniqCountry();
    const filter = { CountryId: this.formGroup.controls.country.value.id };
    this.citiesTree = [];
    this.coAreaLoading = true;
    this.lookupsService.getCoverageAreas(filter).subscribe(
      data => {
        this.citiesTree = data as [];
        this.coAreaLoading = false;
      }, err => {
        console.error(err);
        this.coAreaLoading = false;
      });
  }

  bindDataToUpdateRecord(record = this.record) {
    if (record.country) {
      this.formGroup.controls.country.setValue(this.countryList.find(item => item.id === record.country.id));
      if (record.cities && record.cities[0].childs && record.cities[0].childs.length) {
        this.getCoverageAreas();
        this.detailsSelectedRecords = record.cities;
      }
    }
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      country: [null, Validators.required],
    });
    this.bindDataToUpdateRecord();
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  submitRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid && this.selectedCities.length && !this.checkUniqCountry()) {
      this.record.country = this.formGroup.controls.country.value,
        this.record.cities = this.selectedCities,
        this.record.selected = this.selectedCities,

        this.newRecord.emit(this.record);
      this.bindDataToUpdateRecord();
    }
  }

  deleteRecord() {
    this.deleteItem.emit();
    this.checkUniqCountry();
  }

  checkUniqCountry(): boolean {
    const item = this.formGroup.controls.country.value;
    if (
      this.currentIndex != null
      && this.coveredAreaList[this.currentIndex].country
      && this.coveredAreaList[this.currentIndex].country.id === item.id
    ) {
      return false;
    } else if (this.coveredAreaList.length && item) {
      return this.coveredAreaList.some(obj => obj.country?.id === item.id);
    } else {
      return false;
    }
  }
}
