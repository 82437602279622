export enum ModelsEnum {
  LabUser = 'LAB_USER',
  SCUser = 'SCANNING_CENTER_USER',
  VLUser = 'VIRTUAL_LAB_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  EXPERT_USER = 'EXPERT_USER',
  OTHER_USER = 'OTHER_USER',
  pathologistUser = 'PATHOLOGIST_USER',
  pathologistRequest = 'PATHOLOGIST_REQUEST',
  hospitalUser  = 'HOSPITAL_USER',
  clinicUser  = 'CLINIC_USER',

  // User inside Lab/SC profile
  Lab = 'Lab',
  Hospital = 'Hospital',
  Clinic = 'Clinic',
  VL = 'VirtualLab',
  ScanningCenter = 'ScanningCenter',
  profile = 'profile',
  LabStaff = 'LabStaff',
  SCStaff = 'SCStaff'
}
