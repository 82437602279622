import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConcurelAuthUser } from '../models/concurel/ConcurelAuthUser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConcurelService {
  constructor(private http: HttpClient) {
  }

  loginConcurel(data) {
    return this.http.post<ConcurelAuthUser>(
      `${environment.apiUrl}/Concurel/loginToConcurel`, data)
      .pipe(map(user => {
        if (user && user.Token) {
          localStorage.setItem('concurelUser', JSON.stringify(user));
          return user;
        }
        return false;
      }));
  }

  getAllMeeting(params): any {
    return this.http.post(`${environment.apiUrl}/ConcurelViewer/GetConcurelMeetingsList`, params);
  }

  shareSlideToConcurel(params): any {
    return this.http.post(`${environment.apiUrl}/ConcurelViewer/ShareSlideToConcurel`, params);
  }


}
