import { Component, Input, OnInit } from '@angular/core';
import { TechnologistCaseAudit } from 'src/app/models/cases/technologistCaseAudit';

@Component({
  selector: 'app-related-technologists',
  templateUrl: './related-technologists.component.html'
})
export class RelatedTechnologistsComponent implements OnInit {
  @Input() list: TechnologistCaseAudit[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
