<div class="common-page">
  <div class="page-container">
    <ng-lottie [options]="options"></ng-lottie>

    <div class="page-titles text-center">
      <h2>Access Unauthorized</h2>
      <p>The page you are trying to access requires proper authentication, Perhaps you've landed here by mistake?</p>

      <!-- <button mat-raised-button color='primary' (click)="location.back()">Return Back</button> -->
    </div>
  </div>
</div>
