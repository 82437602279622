import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SlidesService } from 'src/app/services/slides.service';
import { Details } from 'src/app/models/slides/Details';
import { ListFilter } from 'src/app/models/slides/ListFilter';

export interface DialogData {
  caseId: number;
  userId: number;
  userType: string;
}

@Component({
  selector: 'app-slide-other-form',
  templateUrl: './slide-other-form.component.html'
})
export class SlideOtherFormComponent implements OnInit {
  dataLoading = false;
  loading = false;
  isSubmitted = false;
  slidesIds: number[] = [];

  // table
  recordsData: Details[] = [];
  filterModel: ListFilter = new ListFilter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SlideOtherFormComponent>,
    private service: SlidesService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.dataLoading = true;
    this.filterModel.CaseId = this.data.caseId;
    if (this.data.userId) {
      this.filterModel.UserId = this.data.userId;
      this.filterModel.UserType = this.data.userType;
    }
    this.service.getOtherSlidesLookup(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as Details[];
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  assignPath(id) {
    const index = this.slidesIds.findIndex(slideId => slideId === id);
    if (index > -1) {
      this.slidesIds.splice(index, 1);
    } else {
      const item = this.recordsData.find(user => user.slideId === id);
      this.slidesIds.push(item.slideId);
    }
  }

  isInArray(id: number) {
    return this.slidesIds.some(slideId => slideId === id);
  }

  saveRecord() {
    if (this.slidesIds.length === 0) {
      return;
    }
    this.isSubmitted = true;
    this.loading = true;
    const data = {
      caseId: this.data.caseId,
      slideIds: this.slidesIds,
      userId: this.data.userId,
      userType: this.data.userType
    } as any;
    this.service.addOtherSlide(data).subscribe(() => {
      this.loading = false;
      this.dialogRef.close(true);
    }, err => {
      this.loading = false;
    });
  }


}
