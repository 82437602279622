import { ConcurelService } from 'src/app/services/concurel.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConcurelAuthUser } from 'src/app/models/concurel/ConcurelAuthUser';
import { ListFilter } from 'src/app/models/concurel/ListFilter';
import { ListData, pageData } from 'src/app/models/concurel/ListData';

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
})
export class MeetingListComponent implements OnInit {
  dataLoading = false;
  errorMsg: any
  @Output() selected = new EventEmitter<any>();
  @Input() successMsg = false;
  recordsData: ListData;
  meetingList: Array<pageData> = [];
  selectedMeeting: any;
  filterModel: ListFilter = new ListFilter();
  pageCount = 0;
  pageIndex = 0;
  pageNumber = 0;
  pageSize = 6;

  constructor(private concurelService: ConcurelService) { }

  ngOnInit(): void {
    this.getAll(null);
  }

  getAll(event) {
    if (!this.meetingList.length) {
      this.dataLoading = true;
    }
    if (event) {
      this.pageNumber = 0;
      this.meetingList = [];
    }
    this.filterModel.pageSize = this.pageSize;
    this.filterModel.pageNumber = this.pageNumber;
    const authConcurelUser = JSON.parse(
      localStorage.getItem('concurelUser')
    ) as ConcurelAuthUser;
    this.filterModel.Token = authConcurelUser.Token;
    this.filterModel.Lang = localStorage.getItem('language');
    this.concurelService.getAllMeeting(this.filterModel).subscribe(
      (data) => {
        if (data.ErrorMessage == 'Unauthorized') {
          localStorage.removeItem('concurelUser');
        }
        this.recordsData = data as ListData;
        if (this.recordsData.pageData && this.recordsData.pageData.length) {
          this.meetingList = this.meetingList.concat(this.recordsData.pageData);
        }
        this.pageCount = Math.ceil(
          this.recordsData.totalCount / this.filterModel.pageSize
        );
        this.dataLoading = false;
      },
      (error) => {
        const err = JSON.parse(error.detail);
        this.dataLoading = false;
      }
    );
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1) {
      this.pageNumber += 1;
      this.getAll(false);
    }
  }

  changeSelected(id) {
    this.selectedMeeting = id;
    this.selected.emit(id);
  }
}
