<div mat-dialog-title>
  <h2>
    {{ hospitalId || isOurProfile ? 'Update' : 'Add New' }} Hospital
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <form [formGroup]="formGroup">

      <div class="fields-group">

        <mat-form-field>
          <mat-label>Hospital Name</mat-label>
          <input matInput formControlName="HospitalName" maxlength="100">
          <mat-error *ngIf="hasError('HospitalName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('HospitalName', 'notUniqe')">Hospital Name Already Exist</mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group mb-5">
        <div class="upload-logo">
          <div class="image-upload">
            <label for="imageUpload">
              <input type='file' class="img-viewer" id="imageUpload" accept="image/jpeg, image/pjpeg, image/png"
                (change)="changeFileListener($event)">
              <div class="image-preview">
                <img [src]="logoSrc" *ngIf="logoSrc">
              </div>
              <span class="plus-icon"></span>
            </label>
          </div>
          <div>
            <label>{{ 'forms.Upload photo' | translate }}</label>
            <p>{{ 'forms.Maximumfilesize800KB' | translate }} <br>
              {{ 'forms.Werecommendimagedimensions' | translate }}</p>
          </div>
        </div>
        <p class="invalid-feedback mt-2 d-block" *ngIf="!logoSrc && isSubmitted">{{ 'forms.Required' | translate }}</p>
        <p class="invalid-feedback mt-2 d-block" *ngIf="fileTypeError">{{ 'forms.InvalidFile' | translate }}</p>
        <p class="invalid-feedback mt-2 d-block" *ngIf="fileSizeError">{{ 'forms.Max-files' | translate }}
          {{maxFileSize}} Mb</p>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">Contacts Info</h3>

        <div class="form-group">
          <ngx-intl-tel-input [customPlaceholder]="" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
            [maxLength]="50" [numberFormat]="PhoneNumberFormat.International" [phoneValidation]="true"
            [searchCountryField]="[SearchCountryField.All]" [searchCountryFlag]="true" [selectFirstCountry]="false"
            [selectedCountryISO]="selectedCountryISO" [separateDialCode]="true" formControlName="contactNumber"
            name="contactNumber">
          </ngx-intl-tel-input>
          <p class="has-error" *ngIf="
              formGroup.controls.contactNumber.errors &&
              formGroup.controls.contactNumber.errors.required && isSubmitted">{{ 'forms.Required' | translate }}</p>

          <p class="has-error" *ngIf="
              formGroup.controls.contactNumber.errors &&
              formGroup.controls.contactNumber.errors.validatePhoneNumber ">
            Enter valid number according selected country
          </p>
        </div>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" maxlength="50" (cdkAutofill)="autoFillInput('email')">
          <mat-error *ngIf="hasError('email', 'required')">{{ 'forms.Required' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'invalid')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
          <mat-error *ngIf="hasError('email', 'pattern')">{{ 'forms.Invalid-Email' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">Address</h3>

        <mat-form-field>
          <mat-label>Country</mat-label>
          <mat-select disableOptionCentering formControlName="country" (selectionChange)='getRegions(true)'>
            <mat-option [value]="item.id" *ngFor="let item of countryList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('country', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Region/Governorate/Emirate</mat-label>
          <mat-select disableOptionCentering formControlName="region" (selectionChange)='getCities(true)'>
            <mat-option [value]="item.id" *ngFor="let item of regionList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('region', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>City</mat-label>
          <mat-select disableOptionCentering formControlName="cityId">
            <mat-option [value]="item.id" *ngFor="let item of cityList">
              <span>{{item.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('cityId', 'required')">{{ 'forms.Required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Street Address</mat-label>
          <textarea matInput formControlName="address" maxlength="500"></textarea>
          <mat-error *ngIf="hasError('address', 'required')">{{ 'forms.Required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">Manager</h3>

        <div class="row">
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput formControlName="managerFirstName" maxlength="50">
              <mat-error *ngIf="hasError('managerFirstName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="managerLastName" maxlength="50">
              <mat-error *ngIf="hasError('managerLastName', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">Slide Communication Methods
          <i class="sub-title">Select how you will get the slides</i>
        </h3>
        <div class="sets-conatiner" formArrayName="communicationMethod">
          <div class="set-item collapsed" [formGroupName]="i"
            *ngFor="let method of communicationMethods.controls; let i = index"
            [class.collapsed]='!communicationMethods.controls[i]["controls"].selectedMethod.value'>
            <div class="set-head">
              <mat-slide-toggle formControlName="selectedMethod" [labelPosition]="'before'">
                <i>
                  {{
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'NETWORK'? 'Network':
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'CEPH'? 'CEPH':
                  communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'FTP'? 'FTP':'Local'
                  }}
                </i>
              </mat-slide-toggle>
            </div>

            <div class="set-content"
              [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value === "LOCAL"'>
              <div [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value === "CEPH"'>
                <mat-form-field>
                  <mat-label
                    *ngIf="communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'NETWORK'">
                    Network Folder Path
                  </mat-label>
                  <mat-label *ngIf="communicationMethods.controls[i]['controls'].CommunicationMethodType.value == 'FTP'">
                    FTP Server Path
                  </mat-label>
                  <input matInput formControlName="NetworkPath"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')"
                    placeholder="\\10.0.0.14\wsifiles" />
                  <mat-error *ngIf="hasError('NetworkPath', 'required', i)">
                    {{'forms.Required' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Username</mat-label>
                  <input matInput formControlName="UserName" maxlength="50"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')">
                  <mat-error *ngIf="hasError('UserName', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="Password" maxlength="50"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && (communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'LOCAL' && communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'CEPH')"
                    [type]="hidePassword ? 'password' : 'text'">
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="hasError('Password', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <div class="fields-group"
                  [hidden]="communicationMethods.controls[i]['controls'].CommunicationMethodType.value !== 'FTP'">
                  <mat-slide-toggle formControlName="IsSFtp" [labelPosition]="'before'">
                    <i>Is Secure File Transfer Protocol (SFTP)?</i>
                  </mat-slide-toggle>
                </div>
              </div>
              <div [hidden]='communicationMethods.controls[i]["controls"].CommunicationMethodType.value !== "CEPH"'>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Api URL</mat-label>
                  <input matInput formControlName="CephApiURL"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephApiURL', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Access Key Id </mat-label>
                  <input matInput formControlName="CephAccessKeyId"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephAccessKeyId', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Secret Key</mat-label>
                  <input matInput formControlName="CephSecretKey"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephSecretKey', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [hideRequiredMarker]='true'>
                  <mat-label>Default Bucket</mat-label>
                  <input matInput formControlName="CephDefaultBucket"
                    [required]="communicationMethods.controls[i]['controls'].selectedMethod.value && communicationMethods.controls[i]['controls'].CommunicationMethodType.value === 'CEPH'">
                  <mat-error *ngIf="hasError('CephDefaultBucket', 'required', i)">{{ 'forms.Required' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <p class="has-error mt-2" *ngIf="isSubmitted && !checkCommunicationMethod()">
          {{ 'forms.Required' | translate }}</p>
      </div>

      <div class="fields-group">
        <h3 class="fields-title">
          Final Report for Patient
          <i class="sub-title">
            Which report will be printed for the patient in the cases that you referred to labs?
          </i>
        </h3>

        <div class="form-group">
          <div class="radio-selector">
            <mat-radio-group formControlName="FinalReport" class="row">
              <mat-radio-button class="col-6" [value]="'HOSPITAL_REPORT'">
                Hospital Report
              </mat-radio-button>
              <mat-radio-button class="col-6" [value]="'LAB_REPORT'">
                Lab Report
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>

      <div>
        <div class="fields-group">
          <mat-slide-toggle formControlName="OwnLab" [labelPosition]="'before'" [disabled]="disableHasLab">
            <i>Hospital has its own Lab?</i>
          </mat-slide-toggle>
        </div>
        <div class="fields-group">
          <mat-slide-toggle formControlName="OwnScanner" [labelPosition]="'before'">
            <i>Hospital has Scanners?</i>
          </mat-slide-toggle>
        </div>
      </div>

      <button class="w-100" mat-raised-button color="primary" [disabled]="loading" [class.loading]='loading'
        (click)="saveRecord()">
        {{ hospitalId || isOurProfile ? 'Update' : 'Add' }} Hospital
      </button>
    </form>
  </div>
</mat-dialog-content>
