import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ClinicForm } from '../models/clinics/ClinicForm';

@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/Clinic/GetAllClinics`, { params });
  }

  saveRecord(data: ClinicForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    if (data.ClinicId) {
      return this.http.put(`${environment.apiUrl}/Clinic/EditClinic`, formData);
    } else {
      return this.http.post(`${environment.apiUrl}/Clinic/AddClinic`, formData);
    }
  }

  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/Clinic/GetClinicDetails?ClinicId=${id}`);
  }

  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/Clinic/DeleteClinic?ClinicId=${id}`);
  }

  getClinicProfileDetails() {
    return this.http.get(`${environment.apiUrl}/ClinicProfile/GetClinicProfileDetails`);
  }

  updateClinicProfile(data: ClinicForm, logo) {
    const formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(data));
    return this.http.put(`${environment.apiUrl}/ClinicProfile/EditClinicProfile`, formData);
  }

}
